import {
  CloseOutlined,
  CloudDownloadOutlined,
  CloudFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Tabs,
  Tooltip,
  Upload,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Can from "../../../Can/Can";
import { environment } from "../../../env/config.env";
import Uploader from "../../../helpers/Uploader";
import fileService from "../../../services/file.service";
import VehiculeService from "../../../services/vehicule.service";
import exportMessages from "../../../UIcomponent/messages/messages";
import messages from "../../../UIcomponent/messages/messages";
import AppTable from "../../../UIcomponent/Table/AppTable";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";

const URL = environment.serverUrl;

const { Dragger } = Upload;
const { TabPane } = Tabs;

const { TextArea } = Input;

function Vehicule() {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [moyenVehiculeModal, setVehiculeModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [moyenForm] = Form.useForm();
  const [vehiculeFile, setVehiculeFile] = useState([]);
  const [filesCount, setfilesCount] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [filesList, setFilesList] = useState([]);
  const [removingFiles, setRemovingFiles] = useState([]);
  const list = [];

  useEffect(() => {
    getVehicules();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setFilesList([]);
    setRemovingFiles([]);
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };

  const fileChange = (vakue) => {
    let arr = vakue.fileList.map((el) => el.originFileObj);
    setselectedFiles(arr);
    // setselectedFiles(vakue.target.files);
  };

  const getVehicules = async () => {
    setLoading(true);
    const response = await VehiculeService.getVehicules();
    if (response.status === 200 || 204  ) initTableData(response.data);
    else messages.errorMessage("un erreur est survenue ");
    setLoading(false);
  };

  const VehiculeColumn = [
    {
      title: "Date d'achat",
      width: "150px",
      dataIndex: "showDate",
      sorter: (a, b) => a.showDate.localeCompare(b.showDate),
    },
    {
      title: "Marque",
      dataIndex: "marque",
      sorter: (a, b) => a.marque.localeCompare(b.marque),
    },
    {
      title: "Puissance fiscale",
      width: "150px",
      dataIndex: "puissance_fiscale",
      sorter: (a, b) => a.puissance_fiscale - b.puissance_fiscale,
    },
    {
      title: "Kilometrage",
      dataIndex: "kilometrage",
      sorter: (a, b) => a.kilometrage - b.kilometrage,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Action",
      key: "action",
      width: "13%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            {(!!currentUser || Can("11_3")) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            )}
          </Tooltip>
          <Tooltip title="Supprimer">
            {(!!currentUser || Can("11_4")) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => confirmDelete(record)}
                icon={<DeleteOutlined />}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce véhicule ? ",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };

  const confirmRemoveFile = (file) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce fichier ? ",
      okText: "Oui",
      onOk: () => deleteFile(file),
      cancelText: "Non",
    });
  };
  const deleteFile = async (file) => {
    const res = await fileService.deleteFile(file, "3");
    if (res.status === 200) {
closeVehiculeModal()      
      exportMessages.addSuccess("Fichier supprimé avec succées ");
    }
  };
  const openFile = (name) => {
    window.open(URL + "documents/vehicules/" + name, "_blank");
  };

  const updateMode = (record) => {
    setVehiculeModal(true);
    setEditMode(true);
    setVehiculeFile(record.file_id);
    moyenForm.setFieldsValue(record);
    moyenForm.setFieldsValue({
      kilometrage: record.kilometrage + "",
      puissance_fiscale: record.puissance_fiscale + "",
    });
    setUpdateId(record.vehicule_id);
  };

  const initTableData = (data) => {
   if (data) for (const element of data) {
      element.showDate = new Date(
        element.date_premier_mis_en_marche
      ).toLocaleDateString();
      element.date_premier_mis_en_marche = moment(
        element.date_premier_mis_en_marche
      );
    }
    setDataTable(data) 
  };

  const deleteItem = async (item) => {
    const response = await VehiculeService.deleteVehicule(item.vehicule_id);
    if (response.status === 200  ) {
      messages.addSuccess("Suppression avec succès ! ");
      getVehicules(response.data);
      return
    } else messages.errorMessage("un erreur est survenu");
  };

  const closingModal = () => {
    setFilesList([]);
    setRemovingFiles([]);
    setVehiculeModal(false);
    getVehicules();
    setConfirmLoading(false);
    clearData();
  };

  const addVehicule = async (values) => {
    setConfirmLoading(true);
    values.active = "true";
    const object = values;
    object.idsToRemove = removingFiles;
    const response = editMode
      ? await VehiculeService.updateVehicule(updateId, values)
      : await VehiculeService.addVehicule(values);
    if (response.status === 200 || 204) {
      let resFile = await fileService.upload(
        selectedFiles,
        response.data.vehicule_id,
        "3"
      );
    } else errorMessage();
    closingModal();
  };

  const downloadFile = async (file) => {
    await fileService.getFile(file.file_id, "3").then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
    });
  };
  const closeVehiculeModal = () => {
    setVehiculeModal(false);
    clearData();
  };

  const clearData = () => {
    moyenForm.resetFields();
    setVehiculeFile([])
    setEditMode(false);
    setUpdateId(null);
  };

  const addSuccess = () => {
    messages.addSuccess("Véhicule ajoutée avec succès ");
    closingModal();
  };
  const updateSuccess = () => {
    messages.updateSuccess("Mise à jour success");
    closingModal();
  };
  const errorMessage = () => {
    setConfirmLoading(false);
    messages.errorMessage();
  };
  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Date d'achat de du véhicule",
      },
    ],
  };

  return (
    <Spin spinning={isLoading} size="large">
      {(!!currentUser || Can("11_1")) ? (
        <Card
          headStyle={{ backgroundColor: "#dee0e9" }}
          title="Liste des véhicules "
          style={{ width: "100%" }}
          extra={
            (!!currentUser || Can("11_2")) && (
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => setVehiculeModal(true)}
              >
                Ajouter une véhicule
              </Button>
            )
          }
        >
          <AppTable data={dataTable} columns={VehiculeColumn} />
        </Card>
      ) : (
        <NotAuthorized />
      )}
      <Modal
        title={editMode ? "Modifier une véhicule" : "Ajouter une véhicule"}
        centered
        okText="Enregistrer"
        cancelText="Annuler"
        confirmLoading={confirmLoading}
        visible={moyenVehiculeModal}
        onOk={moyenForm.submit}
        onCancel={closeVehiculeModal}
        width={800}
      >
        <Form layout="vertical" form={moyenForm} onFinish={addVehicule}>
          <Tabs defaultActiveKey="2">
            <TabPane key="1" tab="Informations">
              <Row>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Marque de la voiture"
                    name="marque"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Exemple : BMW"
                      size="large"
                      type="text"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    name="date_premier_mis_en_marche"
                    label="Date d'achat"
                    {...config}
                  >
                    <DatePicker
                      placeholder="Date d'achat "
                      size="large"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="px-2">
                  <Form.Item
                    label="Kilométrage lors de l'achat"
                    name="kilometrage"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de chiffre 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Exemple : 50000"
                      size="large"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Puissance Fiscale"
                    name="puissance_fiscale"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de chiffre 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Exemple : 6"
                      size="large"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="px-2">
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        message:
                          "Champ obligatoire avec maximum de caractère 255 !",
                        max: 254,
                      },
                    ]}
                  >
                    <TextArea rows={4} maxLength={255} />
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane key="2" tab="Pièce jointe ">
              <div style={{ color: "transparent" }}>{filesCount}</div>
              {vehiculeFile.map((element) => {
                return (
                  <>
                    <Alert
                      key={element.fileId}
                      message={element.name}
                      type="success"
                      className="my-1"
                      action={
                        <Space direction="horizonal">
                          <Tooltip title="Supprimer">
                            <Button
                              type="danger"
                              shape="circle"
                              icon={<CloseOutlined />}
                              onClick={() => {
                                confirmRemoveFile(element);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Telecharger">
                            <Button
                              type="primary"
                              shape="circle"
                              icon={<CloudDownloadOutlined />}
                              onClick={() => downloadFile(element)}
                            />
                          </Tooltip>
                        </Space>
                      }
                    />
                  </>
                );
              })}
              <Form.Item name="files">
                <Uploader text="Véhicule" fileChange={fileChange} />
              </Form.Item>
            </TabPane>
          </Tabs>
        </Form>
      </Modal>
    </Spin>
  );
}
export default Vehicule;
