import { Button, Card, Form, Input, Row, Col, Spin } from "antd";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Can from "../../../Can/Can";
import entrepriseService from "../../../services/entreprise.service";
import messages from "../../../UIcomponent/messages/messages";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";

function Entreprise() {
    const [form] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [addMode, setaddMode] = useState(false);

    const [exists, setExists] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [entreprise, setEntreprise] = useState();
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        getEntrepriseInfo();
        return () => {
            form.resetFields();
        };
    }, []);

    const getEntrepriseInfo = async () => {
        setLoading(true);
        const response = await entrepriseService.getEntreprise();
        if (response.status === 200) {
            setEntreprise(response.data);

            if (!response.data) {
                setaddMode(true);
            }
            initUpdateForm(response.data);
            setLoading(false);
        } else {
            messages.errorMessage();
            setLoading(false);
        }
    };
    const initUpdateForm = (data) => {
      data.telephone = data.telephone.toString()
      data.fax = data.fax.toString()
        form.setFieldsValue(data);
    };

    const addEntreprise = async (newEntreprise) => {
        setLoading(true);
        const response =
            !addMode && editMode
                ? await entrepriseService.updateEntreprise(
                      entreprise.entreprise_id,
                      newEntreprise
                  )
                : await entrepriseService.addEntreprise(newEntreprise);
        if (response.status === 200)
            if (editMode) messages.updateSuccess("Mise à jour success");
            else
                messages.addSuccess(
                    "Ajout de la fiche d'entreprise avec success"
                );
        else messages.errorMessage();
        setEditMode(false);
        setLoading(false);
    };

    const changeEditMode = () => {
        messages.infoMessage();
        setEditMode(true);
    };

    return (
        <Spin spinning={isLoading} size="large">
            {Can("5_1") || !!currentUser.type ? (
                <Card
                    headStyle={{ backgroundColor: "#dee0e9" }}
                    title="Fiche entreprise"
                    extra={
                        (Can("5_3") || !!currentUser.type) && (
                            <Button
                                disabled={editMode}
                                className="px-4"
                                shape="round"
                                type="primary"
                                onClick={changeEditMode}
                            >
                                Modifier
                            </Button>
                        )
                    }
                >
                    <Form
                        disabled
                        layout="vertical"
                        form={form}
                        onFinish={addEntreprise}
                    >
                        <Row>
                            <Col span={12} className="px-2">
                                <Form.Item
                                    label="Nom de l'entreprise"
                                    name="nom"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            max: 99,
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={!editMode && !addMode}
                                        size="large"
                                        maxLength={100}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} className="px-2">
                                <Form.Item
                                    label="Raison social"
                                    name="raison_social"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            max: 99,
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={!editMode && !addMode}
                                        size="large"
                                        maxLength={100}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} className="px-2">
                                <Form.Item
                                    label="Matricule Fiscale"
                                    name="matricule_fiscal"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            max: 99,
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={!editMode && !addMode}
                                        size="large"
                                        maxLength={100}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} className="px-2">
                                <Form.Item
                                    label="Numéro registre de commerce"
                                    name="registre_commerce"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            max: 99,
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={!editMode && !addMode}
                                        size="large"
                                        maxLength={100}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} className="px-2">
                                <Form.Item
                                    label="Adresse"
                                    name="adresse"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            max: 99,
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={!editMode && !addMode}
                                        size="large"
                                        maxLength={100}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} className="px-2">
                                <Form.Item
                                    label="Adresse e-mail"
                                    name="mail"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Champ obligatoire avec maximum de caractère 100 !",
                                            max: 99,
                                        },{
                                          pattern:'^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$' , 
                                          message:"Veuillez Entrer un e-mail Valid "
                                        }
                                    ]}
                                >
                                    <Input
                                        disabled={!editMode && !addMode}
                                        size="large"
                                        maxLength={100}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} className="px-2">
                                <Form.Item
                                    label="Téléphone"
                                    name="telephone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Champ obligatoire", max: 20,
                                        },
                                        {
                                            message:
                                                "Veuillez enter un numéro téléphone Valide  !",
                                            pattern:"^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$",
                                        }, 
                                        
                                    ]}
                                >
                                    <Input
                                        disabled={!editMode && !addMode}

                                        size="large"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} className="px-2">
                                <Form.Item
                                    label="Fax"
                                    name="fax"
                                    rules={[
                                      {
                                          required: true,
                                          message: "Champ obligatoire avec maximum de caractère 20 !", max: 20,
                                      },
                                      {
                                          message:
                                              "Veuillez enter un numéro de  Fax Valide  !",
                                          pattern:"^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$",
                                      }, 
                                      
                                  ]}
                                >
                                    <Input
                                        disabled={!editMode && !addMode}
                                       
                                        size="large"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="d-flex flex-row-reverse">
                            {!exists ? (
                                <Button
                                    type="primary"
                                    size="large"
                                    shape="round"
                                    className="mx-2"
                                    disabled={!editMode}
                                    onClick={form.submit}
                                >
                                    Ajouter
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    className="mx-2"
                                    disabled={!editMode && !addMode}
                                    onClick={form.submit}
                                >
                                    Enregistrer
                                </Button>
                            )}

                            <Button
                                disabled={!editMode && !addMode}
                                onClick={() => setEditMode(false)}
                            >
                                Annuler
                            </Button>
                        </Row>
                    </Form>
                </Card>
            ) : (
                <NotAuthorized />
            )}
        </Spin>
    );
}
export default Entreprise;
