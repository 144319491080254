import React from "react";
import moment from "moment";

const EcheanceTable = ({ echeances }) => {
  const formatteDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <table style={{ width: "99%" }} className="table">
      <thead>
        <tr>
          <th> Libelle </th>
          <th> Date du paiement </th>
          <th> Montant </th>
          <th> Montant payé </th>
          <th> Reste à payer </th>
          <th> Etat </th>
        </tr>
      </thead>
      <tbody>
        {echeances &&
          echeances.map((echeance) => {
            return (
              <tr>
                <td>{echeances.length > 1 ? echeance.libelle : "Paiement-001"}</td>
                <td> {formatteDate(echeance.date_limite)} </td>
                <td> {echeance.montant} </td>
                <td> {echeance.montant - echeance.reste} </td>
                <td> {echeance.reste} </td>
                <td> {echeance.etat.toUpperCase()} </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
export default EcheanceTable;
