import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Can from "../../../Can/Can";
import ContratService from "../../../services/contrat.service";
import messages from "../../../UIcomponent/messages/messages";
import AppTable from "../../../UIcomponent/Table/AppTable";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";
const { TextArea } = Input;

function Contrat() {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [moyenContratModal, setContratModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [moyenForm] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();

  useEffect(() => {
    getContrats();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const getContrats = async () => {
    setLoading(true);
    const response = await ContratService.getContrats();
    if (response.status === 200 || 204) initTableData(response.data);
    else messages.errorMessage();
    setLoading(false);
  };
  
  const ContratColumn = [
    {
      title: "Nom",
      dataIndex: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
      width: "15%",
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      width: "15%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "52%",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            {(!!currentUser|| Can("10_3")) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            )}
          </Tooltip>
          <Tooltip title="Supprimer">
            {(!!currentUser|| Can("10_4")) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => confirmDelete(record)}
                icon={<DeleteOutlined />}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce contrat ? ",
      onOk: () => deleteItem(record),
      okText: "Oui",
      cancelText: "Non",
    });
  };
  const updateMode = (record) => {
    setContratModal(true);
    moyenForm.setFieldsValue(record);
    setUpdateId(record.contrat_id);
    setEditMode(true);
  };

  const initTableData = (data) => {
    setDataTable(data);
  };

  const deleteItem = async (item) => {
    const response = await ContratService.deleteContrat(item.contrat_id);
    if (response.status === 200 || 204) {
      messages.addSuccess("Suppression avec succès ! ");
      getContrats(response.data);
    } else messages.errorMessage();
  };

  const closingModal = () => {
    getContrats();
    setContratModal(false);
    setConfirmLoading(false);
    clearData();
  };

  const addTaxe = async (values) => {
    setConfirmLoading(true);
    setContratModal(true);
    values.active = "true";
    const response = editMode
      ? await ContratService.updateContrat(updateId, values)
      : await ContratService.addContrat(values);
    if (response.status === 200 || 204) {
      if (editMode) updateSuccess();
      else addSuccess();
    } else errorMessage();
    closingModal();
  };

  const closeContratModal = () => {
    setContratModal(false);
    setConfirmLoading(false);
    clearData();
  };

  const clearData = () => {
    moyenForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const addSuccess = () => {
    messages.addSuccess("Contrat ajoutée avec succès ");
    closingModal();
  };
  const updateSuccess = () => {
    messages.updateSuccess("Mise à jour d'un contrat avec success");
    closingModal();
  };
  const errorMessage = () => {
    setConfirmLoading(false);
    messages.errorMessage();
  };

  return (
    <Spin spinning={isLoading} size="large">
      {(!!currentUser|| Can("10_1")) ? (
        <Card
          headStyle={{ backgroundColor: "#dee0e9" }}
          title="Liste des contrats "
          style={{ width: "100%" }}
          extra={
            (!!currentUser|| Can("10_2")) && (
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => setContratModal(true)}
              >
                Ajouter un type de contrat
              </Button>
            )
          }
        >
          <AppTable data={dataTable} columns={ContratColumn} />
        </Card>
      ) : (
        <NotAuthorized />
      )}
      <Modal
        title="Ajouter un type de contrat"
        centered
        okText="Enregistrer"
        cancelText="Annuler"
        getContainer={false}
        confirmLoading={confirmLoading}
        visible={moyenContratModal}
        onOk={moyenForm.submit}
        onCancel={closeContratModal}
        width={600}
      >
        <Form layout="vertical" form={moyenForm} onFinish={addTaxe}>
          <Row>
            <Col span={24} className="px-2">
              <Form.Item
                label="Nom du contrat "
                name="nom"
                rules={[
                  {
                    required: true,
                    message:
                      "Champ obligatoire avec maximum de caractère 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input
                  placeholder="Exemple : . . ."
                  size="large"
                  type="text"
                  maxLength={100}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="px-2">
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message:
                      "Champ obligatoire avec maximum de caractère 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input
                  placeholder="Exemple : Contrat de vente"
                  size="large"
                  type="text"
                  maxLength={100}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="px-2">
              <Form.Item
                label="Description"
                name="description"
                rules={[{ message: "Maximum de caractère 100 !", max: 254 }]}
              >
                <TextArea rows={4} type="text" maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
}
export default Contrat;
