

import { Ability, AbilityBuilder } from "@casl/ability";
import store from "../store";
import createDebounce from "redux-debounced";
import { useSelector } from "react-redux";

const ability = new Ability();

export default (action, subject) => {
  return ability.can(action, subject);
};

const defineRulesFor = (auth) => {
  const permissions = auth;
  const { can, rules } = new AbilityBuilder();
  if (permissions.length !== 0) {
    permissions.forEach((p) => {
      //Module user
      if (p === "1_1") can("1_1");
      if (p === "1_2") can("1_2");
      if (p === "1_3") can("1_3");
      if (p === "1_4") can("1_4");

      //Module dépense
      if (p === "2_1") can("2_1");
      if (p === "2_2") can("2_2");
      if (p === "2_3") can("2_3");
      if (p === "2_4") can("2_4");
      if (p === "2_5") can("2_5");
      if (p === "2_6") can("2_6");

      //Centre de profit
      if (p === "3_1") can("3_1");
      if (p === "3_2") can("3_2");
      if (p === "3_3") can("3_3");
      if (p === "3_4") can("3_4");
      if (p === "3_5") can("3_5");

      //Catégories
      if (p === "4_1") can("4_1");
      if (p === "4_2") can("4_2");
      if (p === "4_3") can("4_3");
      if (p === "4_4") can("4_4");
      if (p === "4_5") can("4_5");

      //Entreprise
      if (p === "5_1") can("5_1");
      if (p === "5_2") can("5_2");
      if (p === "5_3") can("5_3");
      if (p === "5_4") can("5_4");

      //Méthode depaiement
      if (p === "6_1") can("6_1");
      if (p === "6_2") can("6_2");
      if (p === "6_3") can("6_3");
      if (p === "6_4") can("6_4");

      //type de paiement
      if (p === "7_1") can("7_1");
      if (p === "7_2") can("7_2");
      if (p === "7_3") can("7_3");
      if (p === "7_4") can("7_4");

      //TVA
      if (p === "8_1") can("8_1");
      if (p === "8_2") can("8_2");
      if (p === "8_3") can("8_3");
      if (p === "8_4") can("8_4");

      //Taxe
      if (p === "9_1") can("9_1");
      if (p === "9_2") can("9_2");
      if (p === "9_3") can("9_3");
      if (p === "9_4") can("9_4");

      //Contrat
      if (p === "10_1") can("10_1");
      if (p === "10_2") can("10_2");
      if (p === "10_3") can("10_3");
      if (p === "10_4") can("10_4");

      //Véhicule
      if (p === "11_1") can("11_1");
      if (p === "11_2") can("11_2");
      if (p === "11_3") can("11_3");
      if (p === "11_4") can("11_4");

      // Fournisseur
      if (p === "12_1") can("12_1");
      if (p === "12_2") can("12_2");
      if (p === "12_3") can("12_3");
      if (p === "12_4") can("12_4");

      // Fréquence
      if (p === "13_1") can("13_1");
      if (p === "13_2") can("13_2");
      if (p === "13_3") can("13_3");
      if (p === "13_4") can("13_4");

      // Type de dépense
      if (p === "14_1") can("14_1");
      if (p === "14_2") can("14_2");
      if (p === "14_3") can("14_3");
      if (p === "14_4") can("14_4");

      // Type de échéance
      if (p === "15_1") can("15_1");
      if (p === "15_2") can("15_2");
      if (p === "15_3") can("15_3");
      if (p === "15_4") can("15_4");

      //Paiement
      if (p === "16_1") can("16_1");
      if (p === "16_2") can("16_2");
      if (p === "16_3") can("16_3");
      if (p === "16_4") can("16_4");
      if (p === "16_5") can("16_5");
    });
  }
  return rules;
};

if (localStorage.getItem("permissions")) {
  let auth = JSON.parse(localStorage.getItem("permissions"));
  ability.update(defineRulesFor(auth));
}
