import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Can from "../../../Can/Can";
import paiementMoyenService from "../../../services/paiementMoyens.service";
import messages from "../../../UIcomponent/messages/messages";
import AppTable from "../../../UIcomponent/Table/AppTable";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";

function MoyenPaiement() {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [confirmMoyenPaiementLoading, setConfirmMoyenPaiementLoading] =
    useState(false);
  const [moyenPaiementModal, setMoyenPaiementModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [moyenForm] = Form.useForm();

  useEffect(() => {
    getPaiementTypes();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setUpdateId(null);
  };

  const getPaiementTypes = async () => {
    setLoading(true);
    const response = await paiementMoyenService.getPaiementMoyens();
    if (response.status === 200 || 204) initTableData(response.data);
    else messages.errorMessage();
    setLoading(false);
  };

  const PaiementColumn = [
    {
      title: "Nom",
      dataIndex: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
      width: "75%",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            {(!!currentUser.type || Can("7_3")) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            )}
          </Tooltip>
          <Tooltip title="Supprimer">
            {(!!currentUser.type || Can("7_4")) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => confirmDelete(record)}
                icon={<DeleteOutlined />}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce Moyen de paiement ? ",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const updateMode = (record) => {
    setMoyenPaiementModal(true);
    moyenForm.setFieldsValue(record);
    setUpdateId(record.id_type_paiement);
    setEditMode(true);
  };
  const initTableData = (data) => {
    setDataTable(data);
  };

  const deleteItem = async (item) => {
    const response = await paiementMoyenService.deletePaiementMoyen(
      item.id_type_paiement
    );
    if (response.status === 200 || 204) {
      messages.addSuccess("Suppression avec succès ! ");
      getPaiementTypes(response.data);
    } else messages.errorMessage();
  };

  const closingModal = () => {
    setMoyenPaiementModal(false);
    setConfirmMoyenPaiementLoading(false);
    getPaiementTypes();
    clearData();
  };

  const addMoyenPaiement = async (values) => {
    setConfirmMoyenPaiementLoading(true);
    setMoyenPaiementModal(true);
    values.active = "true";
    const response = editMode
      ? await paiementMoyenService.updatePaiementMoyen(updateId, values)
      : await paiementMoyenService.addPaiementMoyen(values);
    if (response.status === 200 || 204) {
      if (editMode) updateSuccess();
      else addSuccess();
    } else errorMessage();
    closingModal();
  };

  const closeMoyenPaiementModal = () => {
    setMoyenPaiementModal(false);
    clearData();
  };

  const clearData = () => {
    moyenForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const addSuccess = () => {
    messages.addSuccess("Moyen de paiement ajoutée avec succès");
    closingModal();
  };
  const updateSuccess = () => {
    messages.updateSuccess("Mise à jour success");
    closingModal();
  };
  const errorMessage = () => {
    setConfirmMoyenPaiementLoading(false);
    messages.errorMessage();
  };

  return (
    <Spin spinning={isLoading} size="large">
      {(!!currentUser.type || Can("7_1")) ? (
        <Card
          headStyle={{ backgroundColor: "#dee0e9" }}
          title="Liste des Moyens des paiements"
          style={{ width: "100%" }}
          extra={
            (!!currentUser.type || Can("7_2")) && (
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => setMoyenPaiementModal(true)}
              >
                Ajouter un Moyen de paiement
              </Button>
            )
          }
        >
          <AppTable data={dataTable} columns={PaiementColumn} />
        </Card>
      ) : (
        <NotAuthorized />
      )}
      <Modal
        title={
          editMode ? "Modifier Moyen de paiement" : "Ajouter Moyen de paiement"
        }
        centered
        getContainer={false}
        okText="Enregistrer"
        cancelText="Annuler"
        confirmLoading={confirmMoyenPaiementLoading}
        visible={moyenPaiementModal}
        onOk={moyenForm.submit}
        onCancel={closeMoyenPaiementModal}
        width={400}
      >
        <Form layout="vertical" form={moyenForm} onFinish={addMoyenPaiement}>
          <Row>
            <Col span={24} className="px-2">
              <Form.Item
                label="Moyen de paiement"
                name="nom"
                rules={[
                  {
                    required: true,
                    message:
                      "Champ obligatoire avec maximum de caractère 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input
                  placeholder="Exemple : Carte"
                  size="large"
                  type="text"
                  maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
}
export default MoyenPaiement;
