import authHeader from "./auth-header";
import axios from "axios";
import { environment } from "../env/config.env";
const API_URL = environment.api;
let   EntrepriseId = (localStorage.getItem('entreprise'))

const addVehicule = (vehicule) => {
  return axios.post( API_URL + "vehicule/"+EntrepriseId, vehicule, { headers: authHeader() });
};
const getVehicules = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get( API_URL + "vehiculeActive/" + EntrepriseId, { headers: authHeader() });
};
const getVehicule = (id) => {
  return axios.get( API_URL + "vehicule/" + id, { headers: authHeader() });
};
const updateVehicule = (id, vehicule) => {
  return axios.put( API_URL + "vehicule/" + id, vehicule, {
    headers: authHeader(),
  });
};
const deleteVehicule = (id) => {
  return axios.delete( API_URL + "vehicule/" + id, { headers: authHeader() });
};

const vehiculeFiles = async (id , files) => {
  return await axios.post( API_URL + "vehiculeFiles/" + id , files, {
      headers: {"Content-Type": "multipart/form-data"},
    },{ headers: authHeader() }
  );
};

const exportMethodes = {
  addVehicule,
  getVehicules,
  getVehicule,
  updateVehicule,
  deleteVehicule,
  vehiculeFiles,
};
export default exportMethodes;
