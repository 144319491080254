import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import Can from "../../../Can/Can";
import TypeDepenseService from "../../../services/depenseType.service";
import messages from "../../../UIcomponent/messages/messages";
import AppTable from "../../../UIcomponent/Table/AppTable";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";

function TypeDepense(props) {
  const [addTypedepenseModal, setaddTypedepenseModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [moyenForm] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();

  useEffect(() => {
    getTypeDepenses();
    // addActionColumn();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const getTypeDepenses = async () => {
    setLoading(true);
    const response = await TypeDepenseService.getTypesDepenses();
    if (response.status === 200 || 204) initTableData(response.data);
    else messages.errorMessage();
    setLoading(false);
  };

  const TypeDepenseColumn = [
    {
      title: "Type",
      dataIndex: "depense_type_name",
      width: "75%",
      sorter: (a, b) => a.depense_type_name.localeCompare(b.depense_type_name),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            {Can("14_3") && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => {
                  setaddTypedepenseModal(true);
                  setEditMode(true);
                  moyenForm.setFieldsValue(record);
                  setUpdateId(record.depense_type_id);
                }}
                icon={<EditOutlined />}
              />
            )}
          </Tooltip>
          <Tooltip title="Supprimer">
            {Can("14_4") && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => confirmDelete(record)}
                icon={<DeleteOutlined />}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];
  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce type de dépense ? ",
      onOk: () => deleteItem(record),
      okText: "Oui",
      cancelText: "Non",
    });
  };
  const updateMode = (record) => {};

  const initTableData = (data) => {
    setDataTable(data);
  };

  const deleteItem = async (item) => {
    const response = await TypeDepenseService.deleteDepenseType(
      item.depense_type_id
    );
    if (response.status === 204 || 200) {
      messages.addSuccess("Suppression avec succès ! ");
      getTypeDepenses();
    } else messages.errorMessage();
  };

  const closingModal = () => {
    getTypeDepenses();
    setaddTypedepenseModal(props.cancelVisible ? props.cancelVisible : false);
    setConfirmLoading(false);
    clearData();
  };

  const addTypeDepense = async (values) => {
    setConfirmLoading(true);
    setaddTypedepenseModal(true);
    values.active = "true";
    const response = editMode
      ? await TypeDepenseService.updateDepenseType(updateId, values)
      : await TypeDepenseService.addDepenseType(values);
    if (response.status === 200 || 204) {
      if (editMode) updateSuccess();
      else addSuccess();
    } else errorMessage();
    closingModal();
  };

  const closeContratModal = () => {
    setaddTypedepenseModal(props.cancelVisible);
    clearData();
  };

  const clearData = () => {
    moyenForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const addSuccess = () => {
    messages.addSuccess("Type de dépense ajouté avec succès");
    closingModal();
  };
  const updateSuccess = () => {
    messages.updateSuccess("Mise à jour success");
    closingModal();
  };
  const errorMessage = () => {
    setConfirmLoading(false);
    messages.errorMessage();
  };

  return (
    <Spin spinning={isLoading} size="large">
      {Can("14_1") ? (
        <Card
          headStyle={{ backgroundColor: "#dee0e9" }}
          title="Liste des types de dépense "
          style={{ width: "100%" }}
          extra={
            Can("14_2") && (
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => setaddTypedepenseModal(true)}
              >
                Ajouter un type de dépense
              </Button>
            )
          }
        >
          <AppTable data={dataTable} columns={TypeDepenseColumn} />
        </Card>
      ) : (
        <NotAuthorized />
      )}
      <Modal
        title={
          editMode ? "Modifier type de dépense" : "Ajouter un type de dépense"
        }
        centered
        okText="Enregistrer"
        cancelText="Annuler"
        getContainer={false}
        confirmLoading={confirmLoading}
        visible={props.visible ? props.visible : addTypedepenseModal}
        onOk={moyenForm.submit}
        onCancel={closeContratModal}
        width={600}
      >
        <Form layout="vertical" form={moyenForm} onFinish={addTypeDepense}>
          <Row>
            <Col span={24} className="px-2">
              <Form.Item
                label="Nom du type"
                name="depense_type_name"
                rules={[
                  {
                    required: true,
                    message:
                      "Champ obligatoire avec maximum de caractère 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input
                  placeholder="Exemple : . . ."
                  size="large"
                  maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
}
export default TypeDepense;
