import { message } from "antd";
const addSuccess = (msg) => {
  return message.success({ content: msg, className: "mt-5" });
};
const updateSuccess = (msg) => {
  return message.success({
    content: msg,
    className: "mt-5",
  });
};
const errorMessage = ( msg ) => {
  return message.error({
    content: msg ? msg : "Une erreur est survenu !",
    className: "mt-5",
  });
};

const costumErrorMessage = (msg) => {
  return message.error({
    content: msg,
    className: "mt-5",
  });
};
const infoMessage = () => {
  return message.info({
    content: "Maintenant vous pouvez éditer le formulaire ",
    className: "mt-5",
  });
};

const exportMessages = {
  infoMessage,
  errorMessage,
  updateSuccess,
  addSuccess,
  costumErrorMessage,
};
export default exportMessages;
