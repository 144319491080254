import authHeader from "./auth-header";


import axios from "axios";

import { environment } from "../env/config.env";
let EntrepriseId
const API_URL = environment.api;
const addCategorie = (categorie) => {
  return axios.post(API_URL + "categorie/"+EntrepriseId, categorie, {
    headers: authHeader(),
  });
};

const getCategories = () => {
   EntrepriseId = (localStorage.getItem('entreprise'))

  const re = axios.get(API_URL + "categories/"+localStorage.getItem('entreprise') );
  return re
};

const getCategorie = (id) => {
  return axios.get(API_URL + "categorie/" + id, { headers: authHeader() });
};
const addProfCenterToCateg = (id, profCenter) => {
  return axios.post(API_URL + "categorie/prof/" + id, profCenter, {
    headers: authHeader(),
  });
};
const updateCategorie = (id, categorie) => {
  return axios.put(API_URL + "categorie/" + id+"/"+EntrepriseId, categorie, {
    headers: authHeader(),
  });
};

const deleteCategorie = (id) => {
  return axios.delete(API_URL + "categorie/" + id, { headers: authHeader() });
};

const deleteProfitCenterCategorie = (id, profitCenterID) => {
  const profitCenterIDs = [profitCenterID];

  return axios.delete(API_URL + "categorie/prof/" + id + "/" + profitCenterIDs);
};
const deleteSousCategorie = async (id, sousCategoriesID) => {
  const sousCategoriesIDs = [sousCategoriesID];
  const api = axios.delete(
    API_URL + "categorie/sousCateg/" + id + "/" + sousCategoriesIDs
  );

  return await api;
};
const addsousCat = async (id, sousCat) => {
  return axios.post(API_URL + "categorie/sousCateg/" + id +"/"+ EntrepriseId, sousCat, {
    headers: authHeader(),
  });
};

const exportMethodes = {
  addCategorie,
  addProfCenterToCateg,
  addsousCat,
  getCategorie,
  getCategories,
  updateCategorie,
  deleteCategorie,
  deleteProfitCenterCategorie,
  deleteSousCategorie,
};
export default exportMethodes;
