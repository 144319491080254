import authHeader from "./auth-header";

import axios from "axios";
import { environment } from "../env/config.env";
const API_URL = environment.api;
let   EntrepriseId = (localStorage.getItem('entreprise'))

const addTaxe = (paiement_type) => {
  return axios.post(API_URL + "taxe/"+EntrepriseId ,  paiement_type, { headers: authHeader() });
};
const getallTaxes = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "taxes/"+EntrepriseId, { headers: authHeader() });
};
const getTaxes = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "taxessActive/" + EntrepriseId, { headers: authHeader() });
};
const getTaxe = (id) => {
  return axios.get(API_URL + "taxe/" + id, { headers: authHeader() });
};
const updateTaxe = (id, taxes) => {
  return axios.put(API_URL + "taxe/" + id, taxes, { headers: authHeader() });
};
const deleteTaxe = (id) => {
  return axios.delete(API_URL + "taxe/" + id, { headers: authHeader() });
};
const getTimbres = () => {
  return axios.get(API_URL + "timbresActives/"+EntrepriseId, { headers: authHeader() });
};

const exportMethodes = {
  addTaxe,
  getTaxes,
  getTaxe,
  updateTaxe,
  deleteTaxe,
  getTimbres,
  getallTaxes

};
export default exportMethodes;
