import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import Can from "../../../Can/Can";
import columns from "../../../helpers/tableColumns/TypeEcheanceColumns";
import TypeEcheanceService from "../../../services/echeanceType.service";
import messages from "../../../UIcomponent/messages/messages";
import AppTable from "../../../UIcomponent/Table/AppTable";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";

function TypeEcheance(props) {
  const [addTypeecheanceModal, setaddTypeecheanceModal] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [moyenForm] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();

  useEffect(() => {
    getTypeEchenaces();
    addActionColumn();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const getTypeEchenaces = async () => {
    setLoading(true);
    const response = await TypeEcheanceService.getTypesEcheances();
    if (response.status === 200 || 204) initTableData(response.data);
    else messages.errorMessage();
    setLoading(false);
  };

  const addActionColumn = () => {
    if (
      columns.TypeEcheanceColumn[columns.TypeEcheanceColumn.length - 1]
        .title !== "Action"
    ) {
      columns.TypeEcheanceColumn.push({
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Mettre à jour">
              {Can("15_3") && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => updateMode(record)}
                  icon={<EditOutlined />}
                />
              )}
            </Tooltip>
            <Tooltip title="Supprimer">
              {Can("15_4") && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => confirmDelete(record)}
                  icon={<DeleteOutlined />}
                />
              )}
            </Tooltip>
          </div>
        ),
      });
    }
  };

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce type d'échéance ? ",
      onOk: () => deleteItem(record),
      okText: "Oui",
      cancelText: "Non",
    });
  };
  const updateMode = (record) => {
    setaddTypeecheanceModal(true);
    moyenForm.setFieldsValue(record);
    setUpdateId(record.typeEcheance_id);
    setEditMode(true);
  };

  const initTableData = (data) => {
    setDataTable(data);
  };

  const deleteItem = async (item) => {
    const response = await TypeEcheanceService.deletEcheanceType(
      item.typeEcheance_id
    );
    if (response.status === 204 || 200) {
      messages.addSuccess("Suppression avec succès ! ");
      getTypeEchenaces();
    } else messages.errorMessage();
  };

  const TypeEcheanceColumn = [
    {
      title: "ID",
      dataIndex: "typeEcheance_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Nombre d'échéance",
      dataIndex: "nbEcheance",
      sorter: (a, b) => a.nbEcheance.length - b.nbEcheance.length,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            {Can("15_3") && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            )}
          </Tooltip>
          <Tooltip title="Supprimer">
            {Can("15_4") && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => confirmDelete(record)}
                icon={<DeleteOutlined />}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];

  const closingModal = () => {
    getTypeEchenaces();
    setaddTypeecheanceModal(props.cancelVisible ? props.cancelVisible : false);
    setConfirmLoading(false);
    clearData();
  };

  const addTypeEcheance = async (values) => {
    setConfirmLoading(true);
    setaddTypeecheanceModal(true);
    setLoading(true);
    setConfirmLoading(true);
    values.active = "true";

    const response = editMode
      ? await TypeEcheanceService.updatEcheanceType(updateId, values)
      : await TypeEcheanceService.addEcheanceType(values);
    if (response.status === 200 || 204) {
      if (editMode) updateSuccess();
      else addSuccess();
    } else errorMessage();
    closingModal();
  };

  const closeContratModal = () => {
    setaddTypeecheanceModal(props.cancelVisible);

    clearData();
  };

  const clearData = () => {
    moyenForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const addSuccess = () => {
    messages.addSuccess("Type d'échéance ajouté avec succès");
    closingModal();
  };
  const updateSuccess = () => {
    messages.updateSuccess("Mise à jours avec success");
    closingModal();
  };
  const errorMessage = () => {
    setConfirmLoading(false);
    messages.errorMessage();
  };

  return (
    <Spin spinning={isLoading} size="large">
      {Can("15_1") ? (
        <Card
          headStyle={{ backgroundColor: "#dee0e9" }}
          title="Liste des types d'échéances "
          style={{ width: "100%" }}
          extra={
            Can("15_2") && (
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => setaddTypeecheanceModal(true)}
              >
                Ajouter un type d'échéance
              </Button>
            )
          }
        >
          <AppTable data={dataTable} columns={columns.TypeEcheanceColumn} />
        </Card>
      ) : (
        <NotAuthorized />
      )}
      <Modal
        title={
          editMode ? "Modifier type d'échéance" : "Ajouter un type d'échéance"
        }
        centered
        okText="Enregistrer"
        cancelText="Annuler"
        getContainer={false}
        confirmLoading={confirmLoading}
        visible={props.visible ? props.visible : addTypeecheanceModal}
        onOk={moyenForm.submit}
        onCancel={closeContratModal}
        width={600}
      >
        <Form layout="vertical" form={moyenForm} onFinish={addTypeEcheance}>
          <Row>
            <Col span={24} className="px-2">
              <Form.Item
                label="Nom du type"
                name="name"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  defaultValue="--Sélectionner un Nom--"
                >
                  <Select.Option value="Annuel">Annuel</Select.Option>
                  <Select.Option value="Mensuel">Mensuel</Select.Option>
                  <Select.Option value="Trimestriel">Trimestriel</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} className="px-2">
              <Form.Item
                label="Nombre d'échéance"
                name="nbEcheance"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire avec maximum de chiffre 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input
                  placeholder="Exemple : 5 échéance"
                  size="large"
                  maxLength={100}
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
}
export default TypeEcheance;
