import authHeader from "./auth-header";


import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;
let EntrepriseId ; 
const addEntreprise = ( entreprise ) => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.post(API_URL + "entreprise/"  +EntrepriseId  , entreprise ,{ headers: authHeader() });
};

const getEntreprsies = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "entreprises/"+EntrepriseId ,  { headers: authHeader() });
};

const getEntreprise = ( id ) => {
  EntrepriseId = (localStorage.getItem('entreprise'))

  return axios.get(API_URL + "entreprise/" + EntrepriseId , { headers: authHeader() });
};

const updateEntreprise = (id , entreprise ) => {
  return axios.put(API_URL + "entreprise/" + id , entreprise, { headers: authHeader() });
};

const deleteEntreprise = (id) => {
  return axios.delete(API_URL + "entreprise" + id , { headers: authHeader() });
};

const exportMethodes = {
  addEntreprise,
  getEntreprsies,
  getEntreprise,
  updateEntreprise,
  deleteEntreprise,
};
export default exportMethodes;
