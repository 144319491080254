import {
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Form, InputNumber,
    Radio,
    Row,
    Select,
    Slider,
    Spin
} from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useSelector } from "react-redux";
import Can from "../../Can/Can";
import paiementService from "../../services/paiement.service";
import paiementMethodeService from "../../services/paiementMethodes.service";
import profitCenterservice from "../../services/profitCenter.service";
import exportMessages from "../../UIcomponent/messages/messages";
import AppTable from "../../UIcomponent/Table/AppTable";
import NotAuthorized from "../NotAuthorizedPage/NotAuthorizedPage";

const RapportDepense = () => {
    const [isLoading, setLoading] = useState(false);
    const [searchForm] = Form.useForm();
    const [rangeForm] = Form.useForm();
    const [maxValue, setMax] = useState(1000);
    const [minValue, setMin] = useState(0);
    const [profitCentersAndCategoriesData, setProfitCentersAndCategoriesData] =
        useState([]);
    const [marks, setMarks] = useState({ 0: "0", 1000: "1000" });
    const [formRangeMin, setFormRangeMin] = useState(null);
    const [rangeModal, setRangeModal] = useState(false);
    const [paimentsMethodes, setPaimentsMethodes] = useState([]);
    const [showMontantFilter, setShowMontantFiltre] = useState(false);
    const [profitCenters, setProfitCenters] = useState([]);
    const [categorieLevel0, setCategorieLevel0] = useState([]);
    const [categorieLevel1, setCategorieLevel1] = useState([]);
    const [categorieLevel2, setCategorieLevel2] = useState([]);
    const [categorieLevel3, setCategorieLevel3] = useState([]);
    const [categorieLevel4, setCategorieLevel4] = useState([]);
    const [categorieLevel5, setCategorieLevel5] = useState([]);
    const [paiements, setPaiements] = useState([]);
    const [filtredData , setFiltredData] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        loadProfitCenters();
        getPaiementsMethods();
        initForm();
        getPaiements();
    }, []);

    const searchData = () => {
        const search = searchForm.getFieldsValue();
        var paiementToFiltre = paiements;
        console.log(search);
        console.log(paiementToFiltre);
        if( search.dateFrom  ){
            console.log("data search");
            paiementToFiltre = paiementToFiltre.filter((p) => ( 
                moment(new Date(p.datePaiement)).isSameOrAfter(new Date(search.dateFrom[0]), 'day') && 
                moment(new Date(p.datePaiement)).isSameOrBefore(new Date(search.dateFrom[1])) 
            ));
        }
        if( search.showFilterMontant  ){
            console.log("montant  search");

            paiementToFiltre = paiementToFiltre.filter((p) => ( 
                p.montant >= search.montants[0] && 
                p.montant <= search.montants[1]
            ))
            console.log(paiementToFiltre);

        }
        if( search.profitCenter ){
            console.log("pc serach");

            if(search.profitCenter !== -1 )
                paiementToFiltre = paiementToFiltre.filter((p) => ( p.pc === search.profitCenter))
        }
        if( search.categorieLevel0){
            const list  = getSearchCategorys(search);
            paiementToFiltre = paiementToFiltre.filter(item => list.includes(item.categorie))
        }

        if( search.moyenPaiemnt && search.moyenPaiemnt !== -1 ){
            console.log("methode paiement search");

            paiementToFiltre = paiementToFiltre.filter((p) => (  p.methodePaiement.methode_paiement_id === search.moyenPaiemnt ));
        }
        setFiltredData(paiementToFiltre);
    }

    const getSearchCategorys = (search) => {
        const fields = {
            categorieLevel5,
            categorieLevel4,
            categorieLevel3,
            categorieLevel2,
            categorieLevel1,
            categorieLevel0,
        };
        var list = []
        for (const fieldName of Object.keys(fields)) {
            if (search?.[fieldName]) {
                if (search?.[fieldName] === -1) {
                    for (const element of fields[fieldName]) {
                        list.push(...serachInChildren(element));
                    }
                }else {
                    for (const element of fields[fieldName]) {
                        if( element.categorie_nom === search?.[fieldName] )
                        list.push(element.categorie_nom);
                    }
                }
            }
        }
        return list;
    };
    const serachInChildren = (cat) => {
        let list = [];
        if(!cat?.sousCategories.length) {
            list.push(cat.categorie_nom)
            return list;
        }
        for (const sousCat of cat.sousCategories) {
            list.push(...serachInChildren(sousCat))
        }
        list.push(cat.categorie_nom)
        return list;
    };


    const changeRange = () => {
        const ranges = rangeForm.getFieldsValue();
        setMax(ranges.maximum);
        setMin(ranges.minimum);
        setMarks({
            [ranges.minimum]: ranges.minimum + "",
            [ranges.maximum]: ranges.maximum + "",
        });
        searchForm.setFieldsValue({
            montants: [ranges.minimum, ranges.maximum],
        });
        setRangeModal(false);
    };
    const loadProfitCenters = async () => {
        setLoading(true);
        const response = await profitCenterservice.getProfitCenters();
        setProfitCentersAndCategoriesData(response.data);
        formatteProfitCenters(response.data);
        setLoading(false);
    };
    const getPaiements = async () => {
        let res = await paiementService.getPaiements();
        if (res.status === 200) {
            formattePaiements(res.data);
        } else exportMessages.errorMessage("une erreur est surevenue");
    };
    const formattePaiements = (data) => {
        let newData = data.map((pay) => {
            let paiement = {
                ...pay,
                methodedePaiement: pay.methodePaiement.nom,
                typeCompte: pay.methodePaiement.type_account,
                depenseName: pay.depense.titre,
                datePaiement: formatteDate(pay.date_paiement),
                dateEncaissesment: formatteDate(pay.date_ecaissement),
                categorie: pay.depense.categorie?.categorie_nom,
                pc: pay.depense.profitCenter?.profitCenter_nom,
            };
            return paiement;
        });
        setFiltredData(newData);
        setPaiements(newData);
    };

    const formatteDate = (date) => {
        return moment(date).format("YYYY-MM-DD");
    };

    const initForm = () => {
        setShowMontantFiltre(false);
        searchForm.setFieldsValue({ showFilterMontant: false });
    };

    const formatteProfitCenters = (data) => {
        const list = [];
        for (const element of data) {
            list.push({
                id: element.profitCenter_nom,
                nom: element.profitCenter_nom,
            });
        }
        setProfitCenters(list);
    };

    const getPaiementsMethods = async () => {
        setLoading(true);
        const response = await paiementMethodeService.getPaiementMethodes();
        if (response.status === 200 || 204) {
            setPaimentsMethodes(response.data);
        } else exportMessages.errorMessage();
        setLoading(false);
    };

    const profitCenterChanged = () => {
        searchForm.setFieldsValue({
            categorieLevel0: null,
            categorieLevel1: null,
            categorieLevel2: null,
            categorieLevel3: null,
            categorieLevel4: null,
            categorieLevel5: null,
        });
        setCategorieLevel0(null);
        setCategorieLevel1(null);
        setCategorieLevel2(null);
        setCategorieLevel3(null);
        setCategorieLevel4(null);
        setCategorieLevel5(null);

        const search = searchForm.getFieldsValue();
        const selectedProfitCenter = search.profitCenter;
        const pc = profitCentersAndCategoriesData.find(
            (e) => e.profitCenter_nom === selectedProfitCenter
        );
        if (pc) setCategorieLevel0(pc?.categories);
    };
    const categoryLevel0Changed = () => {
        searchForm.setFieldsValue({
            categorieLevel1: null,
            categorieLevel2: null,
            categorieLevel3: null,
            categorieLevel4: null,
            categorieLevel5: null,
        });
        setCategorieLevel1(null);
        setCategorieLevel2(null);
        setCategorieLevel3(null);
        setCategorieLevel4(null);
        setCategorieLevel5(null);

        const search = searchForm.getFieldsValue();
        const selectedCateg = search.categorieLevel0;
        const categ = categorieLevel0.find(
            (e) => e.categorie_nom === selectedCateg
        );

        setCategorieLevel1(
            categ?.sousCategories?.length ? categ.sousCategories : null
        );
    };
    const categoryLevel1Changed = () => {
        searchForm.setFieldsValue({
            categorieLevel2: null,
            categorieLevel3: null,
            categorieLevel4: null,
            categorieLevel5: null,
        });
        setCategorieLevel2(null);
        setCategorieLevel3(null);
        setCategorieLevel4(null);
        setCategorieLevel5(null);

        const search = searchForm.getFieldsValue();
        const selectedCateg = search.categorieLevel1;
        const categ = categorieLevel1.find(
            (e) => e.categorie_nom === selectedCateg
        );
        setCategorieLevel2(
            categ?.sousCategories?.length ? categ.sousCategories : null
        );
    };

    const categoryLevel2Changed = () => {
        searchForm.setFieldsValue({
            categorieLevel3: null,
            categorieLevel4: null,
            categorieLevel5: null,
        });
        setCategorieLevel3(null);
        setCategorieLevel4(null);
        setCategorieLevel5(null);

        const search = searchForm.getFieldsValue();
        const selectedCateg = search.categorieLevel2;
        const categ = categorieLevel2.find(
            (e) => e.categorie_nom === selectedCateg
        );
        setCategorieLevel3(
            categ?.sousCategories?.length ? categ.sousCategories : null
        );
    };
    const categoryLevel3Changed = () => {
        searchForm.setFieldsValue({ categorieLevel4: null });
        setCategorieLevel4(null);
        const search = searchForm.getFieldsValue();
        const selectedCateg = search.categorieLevel3;
        const categ = categorieLevel3.find(
            (e) => e.categorie_nom === selectedCateg
        );
        setCategorieLevel4(
            categ?.sousCategories?.length ? categ.sousCategories : null
        );
    };
    const categoryLevel4Changed = () => {
        const search = searchForm.getFieldsValue();
        const selectedCateg = search.categorieLevel4;
        const categ = categorieLevel4.find(
            (e) => e.categorie_nom === selectedCateg
        );
        setCategorieLevel5(
            categ?.sousCategories?.length ? categ.sousCategories : null
        );
    };

    const openChangeRangeModal = () => {
        setRangeModal(true);
    };
    const closeRangeModal = () => {
        setRangeModal(false);
    };
    const minRangeChanged = () => {
        setFormRangeMin(rangeForm.getFieldValue("minimum"));
    };
    const columns = [
        {
            title: "Dépense",
            key: "paiment",
            dataIndex: "libelle",
            sorter: (a, b) => a.libelle.localeCompare(b.libelle),
        },
        {
            title: "Charge",
            key: "depenseName",
            dataIndex: "depenseName",
            sorter: (a, b) => a.depenseName.localeCompare(b.depenseName),
        },
        {
            title: "Montant",
            key: "montant",
            dataIndex: "montant",
            sorter: (a, b) => a.montant - b.montant,
        },

        {
            title: "Date de paiement",
            key: "datePaiement",
            dataIndex: "datePaiement",
            sorter: (a, b) => a.datePaiement.localeCompare(b.datePaiement),
        },

        {
            title: "Date de décaissement",
            key: "dateEncaissesment",
            dataIndex: "dateEncaissesment",
            sorter: (a, b) =>
                a.dateEncaissesment.localeCompare(b.dateEncaissesment),
        },

        {
            title: "Méthode de paiement",
            key: "methodedePaiement",
            dataIndex: "methodedePaiement",
            sorter: (a, b) =>
                a.methodedePaiement.localeCompare(b.methodedePaiement),
        },
        {
            title: "Catégorie",
            key: "categorie",
            dataIndex: "categorie",
            sorter: (a, b) => a.categorie.localeCompare(b.categorie),
        },
        {
            title: "Centre de profit",
            key: "pc",
            dataIndex: "pc",
            sorter: (a, b) => a.pc.localeCompare(b.pc),
        },
    ];

    return (
        <Spin spinning={isLoading} size="large">
            {Can("10_1") || !!currentUser.type ? (
                <Card
                    headStyle={{ backgroundColor: "#dee0e9" }}
                    title="Rapport des dépenses"
                    extra={

                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ant-btn ant-btn-primary ant-btn-round mx-2"
                            table="table-to-xls"
                            filename="rapport-dépense"
                            sheet="tablexls"
                            buttonText="Exporter la Table"
                            shape="round"
                            type="primary"
                        >
                        </ReactHTMLTableToExcel>
                    }
                >
                    <Collapse
                        defaultActiveKey={["1"]}
                        expandIconPosition={"left"}
                    >
                        <Collapse.Panel
                            header="Paramètres de recherche "
                            key="1"
                        >
                            <Form
                                layout="vertical"
                                form={searchForm}
                                onFinish={searchData}
                            >
                                <Row>
                                    <Col span={12} className="px-2">
                                        <Form.Item
                                            label="Période"
                                            name="dateFrom"
                                        >
                                            <DatePicker.RangePicker
                                                style={{ width: "100%" }}
                                                size="large"
                                                placeholder={["De", "À"]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="px-2">
                                        <Form.Item
                                            label="Méthode de paiement"
                                            name="moyenPaiemnt"
                                        >
                                            <Select size="large">
                                                <Select.Option value={-1}>
                                                    Toutes les méthodes de
                                                    paiement
                                                </Select.Option>
                                                {paimentsMethodes &&
                                                    paimentsMethodes.map(
                                                        (method) => (
                                                            <Select.Option
                                                                key={
                                                                    method.methode_paiement_id
                                                                }
                                                                value={
                                                                    method.methode_paiement_id
                                                                }
                                                            >
                                                                {method.nom}
                                                            </Select.Option>
                                                        )
                                                    )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="px-2">
                                        <Form.Item
                                            label="Centre de profit"
                                            name="profitCenter"
                                        >
                                            <Select
                                                size="large"
                                                onChange={() =>
                                                    profitCenterChanged()
                                                }
                                            >
                                                <Select.Option value={-1}>
                                                    Tous les centres de profit
                                                </Select.Option>
                                                {profitCenters &&
                                                    profitCenters.map((pc) => (
                                                        <Select.Option
                                                            key={pc.id}
                                                            value={pc.nom}
                                                        >
                                                            {pc.nom}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {categorieLevel0 &&
                                    categorieLevel0?.length ? (
                                        <Col span={6} className="px-2">
                                            <Form.Item
                                                label="Catégories de niveau 0"
                                                name="categorieLevel0"
                                            >
                                                <Select
                                                    size="large"
                                                    onChange={() =>
                                                        categoryLevel0Changed()
                                                    }
                                                >
                                                    <Select.Option value={-1}>
                                                        Toutes les catégories
                                                    </Select.Option>
                                                    {categorieLevel0.map(
                                                        (categ, i) => (
                                                            <Select.Option
                                                                key={
                                                                    categ.categorie_nom
                                                                }
                                                                value={
                                                                    categ.categorie_nom
                                                                }
                                                            >
                                                                {
                                                                    categ.categorie_nom
                                                                }
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                    {categorieLevel1 &&
                                    categorieLevel1?.length ? (
                                        <Col span={6} className="px-2">
                                            <Form.Item
                                                label="Catégories de niveau 1"
                                                name="categorieLevel1"
                                            >
                                                <Select
                                                    size="large"
                                                    onChange={() =>
                                                        categoryLevel1Changed()
                                                    }
                                                >
                                                    <Select.Option value={-1}>
                                                        Toutes les catégories
                                                    </Select.Option>
                                                    {categorieLevel1.map(
                                                        (categ, i) => (
                                                            <Select.Option
                                                                key={
                                                                    categ.categorie_nom
                                                                }
                                                                value={
                                                                    categ.categorie_nom
                                                                }
                                                            >
                                                                {
                                                                    categ.categorie_nom
                                                                }
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                    {categorieLevel2 &&
                                    categorieLevel2?.length ? (
                                        <Col span={6} className="px-2">
                                            <Form.Item
                                                label="Catégories de niveau 2"
                                                name="categorieLevel2"
                                            >
                                                <Select
                                                    size="large"
                                                    onChange={() =>
                                                        categoryLevel2Changed()
                                                    }
                                                >
                                                    <Select.Option value={-1}>
                                                        Toutes les catégories
                                                    </Select.Option>
                                                    {categorieLevel2.map(
                                                        (categ, i) => (
                                                            <Select.Option
                                                                key={
                                                                    categ.categorie_nom
                                                                }
                                                                value={
                                                                    categ.categorie_nom
                                                                }
                                                            >
                                                                {
                                                                    categ.categorie_nom
                                                                }
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}

                                    {categorieLevel3 &&
                                    categorieLevel3?.length ? (
                                        <Col span={6} className="px-2">
                                            <Form.Item
                                                label="Catégories de niveau 3"
                                                name="categorieLevel3"
                                            >
                                                <Select
                                                    size="large"
                                                    onChange={() =>
                                                        categoryLevel3Changed()
                                                    }
                                                >
                                                    <Select.Option value={-1}>
                                                        Toutes les catégories
                                                    </Select.Option>
                                                    {categorieLevel3.map(
                                                        (categ, i) => (
                                                            <Select.Option
                                                                key={
                                                                    categ.categorie_nom
                                                                }
                                                                value={
                                                                    categ.categorie_nom
                                                                }
                                                            >
                                                                {
                                                                    categ.categorie_nom
                                                                }
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                    {categorieLevel4 &&
                                    categorieLevel4?.length ? (
                                        <Col span={6} className="px-2">
                                            <Form.Item
                                                label="Catégories de niveau 4"
                                                name="categorieLevel4"
                                            >
                                                <Select
                                                    size="large"
                                                    onChange={() =>
                                                        categoryLevel4Changed()
                                                    }
                                                >
                                                    <Select.Option value={-1}>
                                                        Toutes les catégories
                                                    </Select.Option>
                                                    {categorieLevel4.map(
                                                        (categ, i) => (
                                                            <Select.Option
                                                                key={
                                                                    categ.categorie_nom
                                                                }
                                                                value={
                                                                    categ.categorie_nom
                                                                }
                                                            >
                                                                {
                                                                    categ.categorie_nom
                                                                }
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                    {categorieLevel5 &&
                                    categorieLevel5?.length ? (
                                        <Col span={6} className="px-2">
                                            <Form.Item
                                                label="Catégories de niveau 5"
                                                name="categorieLevel5"
                                            >
                                                <Select size="large">
                                                    <Select.Option value={-1}>
                                                        Toutes les catégories
                                                    </Select.Option>
                                                    {categorieLevel5.map(
                                                        (categ, i) => (
                                                            <Select.Option
                                                                key={
                                                                    categ.categorie_nom
                                                                }
                                                                value={
                                                                    categ.categorie_nom
                                                                }
                                                            >
                                                                {
                                                                    categ.categorie_nom
                                                                }
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                </Row>

                                <Row>
                                    <Col span={12} className="px-2">
                                        <Form.Item
                                            label="Voulez-vous appliquer un intervalle de recherche par montant ?"
                                            name="showFilterMontant"
                                        >
                                            <Radio.Group className="px-2">
                                                <Radio
                                                    value={false}
                                                    onChange={() =>
                                                        setShowMontantFiltre(
                                                            false
                                                        )
                                                    }
                                                >
                                                    Non
                                                </Radio>
                                                <Radio
                                                    value={true}
                                                    onChange={() =>
                                                        setShowMontantFiltre(
                                                            true
                                                        )
                                                    }
                                                >
                                                    Oui
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="px-3">
                                        {showMontantFilter && (
                                            <Form.Item
                                                extra={
                                                    <span
                                                        className="pointer"
                                                        onClick={() =>
                                                            openChangeRangeModal()
                                                        }
                                                    >
                                                        Cliquer ici pour changer
                                                        l'intervalle de
                                                        recherche par montant.
                                                    </span>
                                                }
                                                label="Montant"
                                                name="montants"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Champ obligatoire avec maximum de caractère 100 !",
                                                    },
                                                ]}
                                            >
                                                <Slider
                                                    disabled={isLoading}
                                                    range
                                                    marks={marks}
                                                    min={minValue}
                                                    max={maxValue}
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="d-flex flex-row-reverse my-2">
                                    <Button
                                        type="primary"
                                        className="mx-2"
                                        onClick={searchForm.submit}
                                    >
                                        Recherche
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            searchForm.resetFields();
                                            setFiltredData(paiements);
                                        }}
                                    >
                                        Annuler
                                    </Button>
                                </Row>
                            </Form>
                        </Collapse.Panel>
                    </Collapse>
                    <div className="py-4">
                        <AppTable data={filtredData} columns={columns} />
                    </div>


                    <Modal
                        title="Parametrage de l'intervalle de recherche "
                        centered
                        okText="Enregistrer"
                        cancelText="Annuler"
                        getContainer={false}
                        visible={rangeModal}
                        onOk={rangeForm.submit}
                        onCancel={closeRangeModal}
                        width={600}
                    >
                        <Form
                            layout="vertical"
                            form={rangeForm}
                            onFinish={changeRange}
                        >
                            <Row>
                                <Col span="12" className="px-2">
                                    <Form.Item
                                        label="Minimum"
                                        name="minimum"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Champ obligatoire  !",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            style={{ width: "100%" }}
                                            size="large"
                                            min={0}
                                            placeholder="0"
                                            onChange={minRangeChanged}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span="12" className="px-2">
                                    <Form.Item
                                        label="Maximum"
                                        name="maximum"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Champ obligatoire  !",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            style={{ width: "100%" }}
                                            size="large"
                                            min={formRangeMin}
                                            placeholder="1000"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </Card>
            ) : (
                <NotAuthorized />
            )}
            
            <div className="py-4 hidden">
                <table className="table table-striped" id="table-to-xls">
                    <thead>
                        <tr>
                            <th scope="col" colSpan={9} rowSpan={2}>
                                Tableau des dépense
                            </th>
                        </tr>
                        <tr></tr>
                        <tr>
                            <th scope="col" colSpan={2} >
                                Dépense
                            </th>
                            <th scope="col" >
                                Charge
                            </th>
                            <th scope="col" >
                                Montant
                            </th>
                            <th scope="col" >
                                Date paiement
                            </th>
                            <th scope="col" >
                                Date de décaissement
                            </th>
                            <th scope="col" >
                                Méthode de paiement
                            </th>
                            <th scope="col" >
                                Catégorie
                            </th>
                            <th scope="col" >
                                Centre de profit
                            </th>
                            <th scope="col" >
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtredData &&
                            filtredData.map((p) => (
                                <tr>
                                    <th colSpan={2}>{p.libelle}</th>
                                    <td>{p.depenseName}</td>
                                    <td>{p.montant}</td>
                                    <td>{p.datePaiement}</td>
                                    <td>{p.dateEncaissesment}</td>
                                    <td>{p.methodedePaiement}</td>
                                    <td>{p.categorie}</td>
                                    <td>{p.pc}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
        
            </div>
        </Spin>
    );
};
export default RapportDepense;
