import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Can from "../../../Can/Can";
import RapportHelperService from "../../../services/bilan.service";
import { default as exportMessages, default as messages } from "../../../UIcomponent/messages/messages";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";

function BalanceConfig() {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [compteConfigForm] = Form.useForm();
    const [rubriqueForm] = Form.useForm();
    const [compteConfigModal, setCompteConfigModal] = useState(false);
    const [rubriqueModal, setRubriqueModal] = useState(false);


    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    
    const [rubriques, setRubriques] = useState([]);
    
    
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    

    const options = [{code: 0 , text:"Aucune condition"}, {code: 1 , text:"Si la valeur est positif"}, {code: -1 , text:"Si la valeur est negatif"}]

    useEffect( () => {

      async function load(){
        await loadData();
      }
      load();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setRubriques([]);
      setEditMode(false);
      setUpdateId(null);
    };
    const loadData = async () => {
      try {
        setLoading(true);
        await getRubriques();
        setLoading(false);
      } catch( err ){
        setLoading(false);
        errorMessage();
        console.log( err );
      }
    }


    const getRubriques = async () => {
      const response = await RapportHelperService.getRubriques();
      setRubriques(response.data);
    };
    
    

  
    const addCompteRubrique = async (values) => {
      try {
        setLoading(true);
        const data = compteConfigForm.getFieldsValue();
        editMode ? await RapportHelperService.updateCompteRub(updateId, data) : await RapportHelperService.addCompteRubrique( data );
        editMode ? exportMessages.addSuccess("Mise à jours avec succès") :  exportMessages.addSuccess("Affectation du compte ajouter avec succès");
        closeCompteRubriqueModal();

        setLoading(false);
      } catch ( err ) {
        exportMessages.errorMessage();
        setLoading(false);
        console.log( err );
      }
    };

    const addRubrique = async (values) => {
      try {
        setLoading(true);
        const data = rubriqueForm.getFieldsValue();
        editMode ? await RapportHelperService.updateRubrique(updateId, data) : await RapportHelperService.addRubrique( data );
        editMode ? exportMessages.addSuccess("Mise à jours avec succès") : exportMessages.addSuccess("Rubrique Ajouter avec succès");
        closeRubriqueModal();
        setLoading(false);
      } catch ( err ) {
        errorMessage();
        setLoading(false);
        console.log( err );
      }
    };
    
    const updateCompteRubriqueMode = (record , rubrique_id ) => {
      record.rubrique = rubrique_id
      setCompteConfigModal(true);
      setUpdateId(record.compte_rubrique_id);
      compteConfigForm.setFieldsValue(record);
      setEditMode(true);
    };

    const updateRubriqueMode = (record) => {
      setRubriqueModal(true);
      rubriqueForm.setFieldsValue(record);
      setUpdateId(record.rubrique_id);
      setEditMode(true);
    };

    
  
    const closeCompteRubriqueModal = async () => {
      try {
        setLoading(true);
        setCompteConfigModal(false);
        setConfirmLoading(false);
        compteConfigForm.resetFields();
        clearData();
        await getRubriques();
        setLoading(false);
      } catch( err ){
        setLoading(false);
        errorMessage();
        console.log( err );
      }
    };

    const closeRubriqueModal = async () => {
      try {
        setLoading(true);
        setRubriqueModal(false);
        setConfirmLoading(false);
        rubriqueForm.resetFields();
        clearData();
        await getRubriques();
        setLoading(false);
      } catch( err ){
        setLoading(false);
        errorMessage();
        console.log( err );
      }
    }
  
    const clearData = () => {
      compteConfigForm.resetFields();
      rubriqueForm.resetFields();
      setEditMode(false);
      setUpdateId(null);
    };
      
    const closeCompteConfigModal = () => {
      setCompteConfigModal(false);
      setConfirmLoading(false);
      clearData();
    };

    const confirmDeleteCompteRubrique = (record) => {
      Modal.confirm({
        title: "Attention",
        icon: <ExclamationCircleOutlined />,
        content: "Êtes-vous sûr de vouloir supprimer cette affectation ? ",
        onOk: () => deleteCompteRubrique(record),
        okText: "Oui",
        cancelText: "Non",
      });
    };
    const confirmDeleteRubrique = (record) => {
      Modal.confirm({
        title: "Attention",
        icon: <ExclamationCircleOutlined />,
        content: "Êtes-vous sûr de vouloir supprimer cette rubrique ? ",
        onOk: () => deleteRubrique(record),
        okText: "Oui",
        cancelText: "Non",
      });
    };

    const deleteCompteRubrique = async (record) => {
      try{
        
        setConfirmLoading(true);
        await RapportHelperService.deleteCompteRub(record.compte_rubrique_id);
        messages.addSuccess("Affectation supprimer avec succès ! ");
        await getRubriques();
        setConfirmLoading(false);
      } catch (err){
        errorMessage();
        setConfirmLoading(false);
      }
    };
    const deleteRubrique = async (record) => {
      try{
        setConfirmLoading(true);
        await RapportHelperService.deleteRubrique(record.rubrique_id);
        messages.addSuccess("Rubrique supprimer avec succès ! ");
        await getRubriques();
        setConfirmLoading(false);
      } catch (err){
        errorMessage();
        setConfirmLoading(false);
      }
    };

  
    const errorMessage = () => {
      setConfirmLoading(false);
      messages.errorMessage(" Une erreur est survenu !");
    };
  
    const locale = {
      emptyText: (
        <Empty description={"Le tableau est vide pour le moment "}></Empty>
      ),
    };

    const getConditionText = ( value ) => {
      return options.find( e => e.code === value ).text;
    }
    const getNbrComptes = ( record ) => {
      return record.comptesRubriques.length
    }

    const rubriqueColumns = [
      {
        title: "Rubrique",
        dataIndex: "nom",
        sorter: (a, b) => a.nom.localeCompare(b.nom),
        width: "50%",
      },{
        title: "Nombre des Comptes",
        render: ( record ) => (
          <span> { getNbrComptes( record ) } </span>
        ),
      },{
        title: "Description",
        dataIndex: "description",
      },{
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Mettre à jour">
              {(!!currentUser|| Can("10_3")) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => updateRubriqueMode(record)}
                  icon={<EditOutlined />}
                />
              )}
            </Tooltip>
            <Tooltip title="Supprimer">
              {(!!currentUser|| Can("10_4")) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => confirmDeleteRubrique(record)}
                  icon={<DeleteOutlined />}
                />
              )}
            </Tooltip>
          </div>
        ),
      },
    ];

    const comptesColumns = ( rubriqueId ) =>  [
      {
        title: "Compte",
        dataIndex: "compte_starts_with",
        sorter: (a, b) => a.compte_starts_with.localeCompare(b.compte_starts_with),
        width: "25%"
      },{
        title: "Condition",
        dataIndex: "compte_condition",
        render: (text, record) => ( <span> { getConditionText( text ) } </span>),
        width: "60%"
      },{
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Mettre à jour">
              {(!!currentUser|| Can("10_3")) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => updateCompteRubriqueMode(record , rubriqueId)}
                  icon={<EditOutlined />}
                />
              )}
            </Tooltip>
            <Tooltip title="Supprimer">
              {(!!currentUser|| Can("10_4")) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => confirmDeleteCompteRubrique(record)}
                  icon={<DeleteOutlined />}
                />
              )}
            </Tooltip>
          </div>
        ),
      },
    ];
  


    return (
      <Spin spinning={isLoading} size="large">
        {(!!currentUser|| Can("10_1")) ? (
          <Card
            headStyle={{ backgroundColor: "#dee0e9" }}
            title="Liste des comptes par rubrique "
            style={{ width: "100%" }}
            extra={
              (!!currentUser|| Can("10_2")) && (
                <Button
                  className="mx-2"
                  shape="round"
                  type="primary"
                  onClick={() => setCompteConfigModal(true)}
                >
                  Ajouter un compte
                </Button>
              )
            }
          >

            <Table
              columns={rubriqueColumns}
              dataSource={rubriques}
              pagination={{ position: ["bottomCenter"], pageSize: 25 }}
              rowKey={(record) => record.rubrique_id}
              expandable={{
                rowExpandable: record => record.name !== 'Not Expandable',
                expandedRowRender: record => <>
                    <Table
                      rowKey={record.compte_rubrique_id}
                      locale={locale}
                      columns={comptesColumns(record.rubrique_id)}
                      dataSource={record.comptesRubriques}
                      pagination={{ position: ["bottomCenter"], pageSize: 10 }}
                    />
                </>,
              }}
            />
          </Card>
        ) : (
          <NotAuthorized />
        )}
        <Modal
          title="Ajouter une compte"
          centered
          okText="Enregistrer"
          cancelText="Annuler"
          getContainer={false}
          confirmLoading={confirmLoading}
          visible={compteConfigModal}
          onOk={compteConfigForm.submit}
          onCancel={closeCompteConfigModal}
          width={800}
        >
          <Form layout="vertical" form={compteConfigForm} onFinish={addCompteRubrique}>
              <Row>
                <Col span={22}>
                  <Form.Item
                    label="Rubrique"
                    name="rubrique"
                    rules={[{ required: true, message: "Champ obligatoire !" }]}
                  >
                    <Select size="large" style={{ width: "100%" }}>
                      { rubriques.map(( rubrique ) => (
                        <Option value={ rubrique.rubrique_id}> {rubrique.nom} </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                { !editMode  &&
                  <Col style={{ paddingTop: "31px" }} >
                    <Tooltip title="Ajouter une rubrique">
                      <Button
                        size="large"
                        onClick={() => setRubriqueModal(true)}
                        icon={<PlusOutlined />}
                      />
                    </Tooltip>
                  </Col>
                }
              </Row>
              <Row>

              <Col span={12} className="px-2">
                <Form.Item
                  label="Numéro de compte commance par"
                  name="compte_starts_with"
                  rules={[
                    {
                      required: true,
                      message:"Champ obligatoire avec !",
                    },
                  ]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        size="large"
                        min={0}
                        max={9999999}
                    />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Condition"
                  name="compte_condition"
                  rules={[
                    {
                      required: true,
                      message:"Champ obligatoire !",
                    },
                  ]}
                >
                    <Select size="large">
                        {options.map((c) => (
                            <Select.Option key={c.code} value={c.code} >
                              {c.text}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          title="Ajouter une rubrique"
          centered
          okText="Enregistrer"
          cancelText="Annuler"
          getContainer={false}
          confirmLoading={confirmLoading}
          visible={rubriqueModal}
          onOk={rubriqueForm.submit}
          onCancel={closeRubriqueModal}
          width={650}
        >
          <Form layout="vertical" form={rubriqueForm} onFinish={addRubrique}>
            <Row>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Intitulé de la rubrique"
                  name="nom"
                  rules={[
                    {
                      required: true,
                      message: "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" />
                </Form.Item>
              </Col>

              <Col span={24} className="px-2">
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      message: "Maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <TextArea
                    size="large"
                    type="text"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
    );
}
export default BalanceConfig;
