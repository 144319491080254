import React, { useEffect, useState } from "react";
import expertService from "../../../services/expert.service";

import {
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Image,
    Input,
    Layout,
    Row,
    Spin,
    message,
} from "antd";
import { useDispatch } from "react-redux";
import AppFooter from "../../../UIcomponent/footer/AppFooter";
// import { history } from "../../../helpers/history";
import { useHistory } from "react-router-dom";
import { loginExpert } from "../../../actions/auth";
import { useSelector } from "react-redux";

const ExpertLogin = () => {
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const msg = useSelector((state) => state.message);
    const dispatch = useDispatch();
    const [loginForm] = Form.useForm();
    const [entrepriseClient, setEntrepriseclient] = useState();
    const [clicked, setClicked] = useState(false);
    const history = useHistory()
    useEffect(() => {
        getEntrepriseClients();
    }, []);
    const login = async (value) => {
        await dispatch(
            loginExpert( value))
        // const res = await await expertService.loginExpert();
   
    };
    const getEntrepriseClients = async () => {
        const res = await expertService.getAllentrepriseclients();
        setEntrepriseclient(res);
    };
    return (
        <Layout title={"Espace pour les experts Comptables"}>
            <Row>
                <Col className="my-5 py-5" span={12} offset={2} ><>
                        Espace pour les experts Comptables
                        </></Col>
                <div className="divv">
                    <div>
                   
                        <Col span={10}>
                            <Card className="cardSigin">
                                <Divider
                                    orientation="right"
                                    className="divider"
                                >
                                    Bienvenue dans GCF
                                </Divider>

                                <Form
                                    name="basic"
                                    wrapperCol={{ span: 24 }}
                                    initialValues={{ remember: true }}
                                    autoComplete="off"
                                    form={loginForm}
                                    onFinish={login}
                                >
                                    <Form.Item
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Champ Obligatoire ! ",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            type="text"
                                            placeholder="Nom D'utilisateur : Admin"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="pass"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Champ Obligatoire !",
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            size="large"
                                            placeholder="Mot De Passe : 123456"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="remember"
                                        valuePropName="checked"
                                        wrapperCol={{
                                            offset: 8,
                                            span: 16,
                                        }}
                                    >
                                        <Checkbox>Rester connecté</Checkbox>
                                    </Form.Item>

                                    <div className="text-left">
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                size="large"
                                                className="buttonSignIn"
                                                htmlType="submit"
                                                onClick={loginForm.submit}
                                                disabled={clicked}
                                            >
                                                Se Connecter
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </Card>
                        </Col>
                       
                    </div>
                    
                </div>
            </Row>

            <AppFooter />
        </Layout>
    );
};
export default ExpertLogin;

//   const SiginIn = () => {

//     const history = useHistory();
//     const [loginForm] = Form.useForm();

//     const Login = async () => {
//       setClicked(true);
//       await dispatch(
//         login(
//           loginForm.getFieldValue("username"),
//           loginForm.getFieldValue("password")
//         )
//       );
//     };
//     setTimeout(() => {
//       setClicked(false);
//     }, 2000);
//     if (msg === "waiting") {
//       message.loading("dsfdg");
//     }
//     // if (msg.message === "") {
//     //   messag.addSuccess("fdfgf");
//     // }
//     const setExpirationDate = () => {
//       const rem = loginForm.getFieldValue("remember");
//       if (!rem) {
//         var date = moment(new Date()).add(0, "seconds").add(20, "minutes");
//         localStorage.setItem("expire", date);
//       }
//     };
//     isLoggedIn && setExpirationDate();

//     return (
//       <>
//         {msg.message === undefined ? (
//           <>
//             <Redirect to="/" />{" "}
//           </>
//         ) : (
//           <Spin spinning={msg === "waiting"}>
//             {" "}
//             <Layout>
//               <Row>
//                 <Col className="my-5 py-5" span={14}>
//                   <Image
//                     preview={false}
//                     src={backPhoto}
//                     className="backGroundPic mx-5 my-5"
//                   />
//                 </Col>
//                 <div className="divv">
//                   <div>
//                     <Col span={10}>
//                       <Card className="cardSigin">
//                         <Divider orientation="right" className="divider">
//                           Bienvenue dans GCF
//                         </Divider>

//                         <Form
//                           name="basic"
//                           wrapperCol={{ span: 24 }}
//                           initialValues={{ remember: true }}
//                           autoComplete="off"
//                           form={loginForm}
//                         >
//                           <Form.Item
//                             name="username"
//                             rules={[
//                               {
//                                 required: true,
//                                 message: "Champ Obligatoire ! ",
//                               },
//                             ]}
//                           >
//                             <Input
//                               size="large"
//                               type="text"
//                               placeholder="Nom D'utilisateur : Admin"
//                             />
//                           </Form.Item>

//                           <Form.Item
//                             className="pass"
//                             name="password"
//                             rules={[
//                               {
//                                 required: true,
//                                 message: "Champ Obligatoire !",
//                               },
//                             ]}
//                           >
//                             <Input.Password
//                               size="large"
//                               placeholder="Mot De Passe : 123456"
//                             />
//                           </Form.Item>
//                           <Form.Item
//                             name="remember"
//                             valuePropName="checked"
//                             wrapperCol={{
//                               offset: 8,
//                               span: 16,
//                             }}
//                           >
//                             <Checkbox>Rester connecté</Checkbox>
//                           </Form.Item>

//                           <div className="text-left">
//                             <Form.Item>
//                               <Button
//                                 type="primary"
//                                 size="large"
//                                 className="buttonSignIn"
//                                 htmlType="submit"
//                                 onClick={Login}
//                                 disabled={clicked}
//                               >
//                                 Se Connecter
//                               </Button>
//                             </Form.Item>
//                           </div>
//                         </Form>
//                       </Card>
//                     </Col>
//                   </div>
//                 </div>
//               </Row>

//               <AppFooter />
//             </Layout>
//           </Spin>
//         )}
//       </>
//     );
//   };

//   export default SiginIn;
