import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Spin,
    Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Can from "../../../Can/Can";
import ExerciceService from "../../../services/exercice.service";
import messages from "../../../UIcomponent/messages/messages";
import AppTable from "../../../UIcomponent/Table/AppTable";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";

const { TextArea } = Input;
const dates = [
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
];
function Exercice() {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [moyenExerciceModal, setExerciceModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [moyenForm] = Form.useForm();
    const [dataTable, setDataTable] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [updateEx, setUpdateEx] = useState();
    const [updateEtat, setUpdateEtat] = useState();

    const [updateId, setUpdateId] = useState();

    useEffect(() => {
        getExercices();
        return () => {
            clearStates();
        };
    }, []);

    const clearStates = () => {
        setDataTable([]);
        setEditMode(false);
        setUpdateId(null);
    };
    const getExercices = async () => {
        setLoading(true);
        const response = await ExerciceService.getExercices();
        if (response.status === 200 || 204) initTableData(response.data);
        else messages.errorMessage();
        setLoading(false);
    };

    const getEtat = (etat) => {
        if (etat === 0) return "Balance Non Chargée";
        if (etat === 1) return "Balance Affectée";
        if (etat === 2) return "Rapport En Cours de prépartion";
        if (etat === 3) return "Rapport Validé";
    };

    const ExerciceColumn = [
        {
            title: "Année",
            dataIndex: "annee",
            sorter: (a, b) => a.annee.localeCompare(b.annee),
        },
        {
            title: "Date debut",
            dataIndex: "showDateDebut",
        },
        {
            title: "Date fin",
            dataIndex: "showDateFin",
        },
        {
            title: "Etat",
            dataIndex: "etat",
            render: (text, record) => <div>{getEtat(record.etat)}</div>,
        },
        {
            title: "Action",
            key: "action",
            width: "15%",
            render: (text, record) => (
                <div>
                    <Tooltip title="Mettre à jour">
                        {(!!currentUser || Can("10_3")) && (
                            <Button
                                className="mx-1"
                                type="dashed"
                                shape="circle"
                                disabled={record.etat > 1}
                                onClick={() => updateMode(record)}
                                icon={<EditOutlined />}
                            />
                        )}
                    </Tooltip>
                    <Tooltip title="Supprimer">
                        {(!!currentUser || Can("10_4")) && (
                            <Button
                                className="mx-1"
                                type="dashed"
                                shape="circle"
                                disabled={record.etat > 0}
                                onClick={() => confirmDelete(record)}
                                icon={<DeleteOutlined />}
                            />
                        )}
                    </Tooltip>
                </div>
            ),
        },
    ];

    const confirmDelete = (record) => {
        Modal.confirm({
            title: "Attention",
            icon: <ExclamationCircleOutlined />,
            content: "Êtes-vous sûr de vouloir supprimer cette exercice ? ",
            onOk: () => deleteItem(record),
            okText: "Oui",
            cancelText: "Non",
        });
    };

    const updateMode = (record) => {
        const dates = [moment(record.date_debut) , moment(record.date_fin)];
        setExerciceModal(true);
        moyenForm.setFieldsValue(record);
        moyenForm.setFieldsValue({dates : dates})
        setUpdateId(record.exercice_id);
        setUpdateEx(record.annee);
        setUpdateEtat(record.etat);
        setEditMode(true);
    };

    const initTableData = (data) => {
        const showList = [];
        for (const exercice of data) {
            if (exercice.etat >= 0) {
                exercice.showDateDebut = moment(exercice.date_debut).format(
                    "YYYY-MM-DD"
                );
                exercice.showDateFin = moment(exercice.date_fin).format(
                    "YYYY-MM-DD"
                );
                showList.push(exercice);
            }
        }
        setDataTable(showList);
    };

    const deleteItem = async (item) => {
        const response = await ExerciceService.deleteExercice(item.exercice_id);
        if (response.status === 200 || 204) {
            console.log(response.status);
            messages.addSuccess("Suppression avec succès ! ");
            getExercices();
        } else messages.errorMessage();
    };

    const closingModal = () => {
        getExercices();
        setExerciceModal(false);
        setConfirmLoading(false);
        clearData();
    };

    const addExercice = async (values) => {
        if(checkTheYearDates(values)) return

        if (checkIfExerciceExist(values.annee)) return;
        setConfirmLoading(true);
        setExerciceModal(true);
        values.date_debut = moment(values.dates[0]).toDate();
        values.date_fin = moment(values.dates[1]).toDate();
        values.isActive = true;
        console.log(values);
        const response = editMode
            ? await ExerciceService.updateExercice(updateId, values)
            : await ExerciceService.addExercice(values);
        if (response.status === 200 || 204) {
            if (editMode) updateSuccess();
            else addSuccess();
        } else errorMessage();
        closingModal();
    };

    const checkIfExerciceExist = (annee) => {
        for (const element of dataTable) {
            if (element.annee === annee) {
                if (editMode ) {
                    if( updateEx !== annee ){
                        message.error("Cette exercice existe dèja !");
                        return true;
                    }
                }
                else if( !editMode ){
                    message.error("Cette exercice existe dèja !");
                    return true;
                }  
            }
        }
    };

    const checkTheYearDates = ( values ) => {
        const startDate = moment(values.dates[0]).format("YYYY");
        const endDate = moment(values.dates[1]).format("YYYY");
        console.log(startDate);
        console.log(endDate);
        console.log(values.annee);


        if( values.annee !== startDate && values.annee !== endDate ) {
            message.error("Au minimum La date de debut ou la date de fait doit dans l'année de l'exercice !");
            return true;
        }
    }

    const closeExerciceModal = () => {
        setExerciceModal(false);
        setConfirmLoading(false);
        clearData();
    };

    const clearData = () => {
        moyenForm.resetFields();
        setEditMode(false);
        setUpdateId(null);
        setUpdateEtat(null);
    };

    const addSuccess = () => {
        messages.addSuccess("Exercice ajoutée avec succès ");
        closingModal();
    };
    const updateSuccess = () => {
        messages.updateSuccess("Mise à jour d'un exercice avec success");
        closingModal();
    };
    const errorMessage = () => {
        setConfirmLoading(false);
        messages.errorMessage();
    };

    return (
        <Spin spinning={isLoading} size="large">
            {!!currentUser || Can("10_1") ? (
                <Card
                    headStyle={{ backgroundColor: "#dee0e9" }}
                    title="Liste des exercices "
                    style={{ width: "100%" }}
                    extra={
                        (!!currentUser || Can("10_2")) && (
                            <Button
                                className="mx-2"
                                shape="round"
                                type="primary"
                                onClick={() => setExerciceModal(true)}
                            >
                                Ajouter un exercice
                            </Button>
                        )
                    }
                >
                    <AppTable data={dataTable} columns={ExerciceColumn} />
                </Card>
            ) : (
                <NotAuthorized />
            )}
            <Modal
                title={ editMode ? "Mettre à jour l'exercice " : "Ajouter un exercice" }
                centered
                okText="Enregistrer"
                cancelText="Annuler"
                getContainer={false}
                confirmLoading={confirmLoading}
                visible={moyenExerciceModal}
                onOk={moyenForm.submit}
                onCancel={closeExerciceModal}
                width={600}
            >
                <Form layout="vertical" form={moyenForm} onFinish={addExercice}>
                    <Row>
                        <Col span={24} className="px-2">
                            <Form.Item
                                label="Année de l'exercice "
                                name="annee"
                                rules={[
                                    {
                                        required: true,
                                        message: "Champ obligatoire !",
                                    },
                                ]}
                            >
                                <Select size="large" disabled={updateEtat > 0}>
                                    {dates &&
                                        dates.map((date) => (
                                            <Select.Option value={date}>
                                                {date}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="px-2">
                            <Form.Item
                                label="Dates "
                                name="dates"
                                rules={[
                                    {
                                        required: true,
                                        message: "Champ obligatoire !",
                                    },
                                ]}
                            >
                                <DatePicker.RangePicker
                                    style={{ width: "100%" }}
                                    size="large"
                                    placeholder={["Date debut ", "Date Fin"]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Spin>
    );
}
export default Exercice;
