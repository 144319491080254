import { Card, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import DepenseService from "../../services/depense.service";
import { PieChart, Pie , Cell } from "recharts";

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";

const data = [
    {
        name: "Page A",
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];
const colors = [
    "#8884d8","#84D8B2","#D884AA",'#D4D884', '#84AAD8', '#B284D8'
]


export default function DashboardLeadChart() {
    const [isLoading, setLoading] = useState(false);
    const [firstChart, setfirstChart] = useState([]);
    const [secondeChart, setSecondChart] = useState([]);
    const [thirdChart, setThirdChart] = useState([]);


    useEffect(() => {
        getDepenses();
    }, []);

    const getDepenses = async () => {
        setLoading(true);
        const response = await DepenseService.getDepenses();
        getFirstChartData(response.data);
        getSecondChartData(response.data);
        getThridChartData(response.data);
        setLoading(false);
    };
    const getFirstChartData = (data) => {
        const list = [];
        var toPay  = 0;
        var engoing = 0;
        var payed = 0;
        for (const element of data) {
            if( element.statut  === 0 ) toPay += 1;
            if( element.statut  === 1 ) engoing += 1;
            if( element.statut  === 2 ) payed += 1;
        } 
        const toPayObject = { name: "À payer" , value : toPay };
        const engoingObject = { name : "En Cours", value : engoing };
        const payedObject = { name : "Payé" , value : payed };
        list.push(toPayObject,engoingObject,payedObject)
        setThirdChart(list);
    }
    const getSecondChartData = (data) => {
        const list = [];
        var recurrent  = 0;
        var noRecurrent = 0;
        for (const element of data) {
            if( element.depense_occurence !== null  ) recurrent += 1;
            else noRecurrent += 1
        } 
        list.push({name : "Réccurente" , value : recurrent },  { name : "Non Récurrente" , value : noRecurrent});
        setSecondChart(list);
    }
    const getThridChartData = (data) => {
        const profitCenters = []
        for (const element of data) {
            if( !profitCenters.find(e => e.name === element.profitCenter.profitCenter_nom) ){
                profitCenters.push({name : element.profitCenter.profitCenter_nom , nombre : 1});
            }else{
                const index = profitCenters.findIndex(e => e.name === element.profitCenter.profitCenter_nom);
                profitCenters[index].nombre = profitCenters[index].nombre + 1;
            }
        }   
        setfirstChart(profitCenters)

    }
    
    return (
        <Spin spinning={isLoading} size="large">
            <Row>
                <Col span={24}  >
                    <h5 className="text-center">Dépenses par centre de profit : </h5>
                    <BarChart
                        width={900}
                        height={300}
                        data={firstChart}
                
                    >
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="nombre" fill="#8884d8" > 
                        {
                            data.map((entry, index) => <Cell fill={colors[index]}/>)
                        }
                        </Bar>
                    </BarChart>

                </Col>
                <Col span={8} className="mt-3" >
                <h5 className="text-center" >Types des dépenses : </h5>

                    <PieChart width={400} height={400} className="mt-0 pt-0">
                        <Pie
                            className="pt-0 mt-0"
                            dataKey="value"
                            startAngle={0}
                            endAngle={380}
                            data={secondeChart}
                            outerRadius={100}
                            fill="#8884d8"
                            label
                        >
                        {
                            data.map((entry, index) => <Cell fill={colors[index]}/>)
                        }
                        </Pie>
                        <Legend />
                        <Tooltip />

                    </PieChart>
                </Col>

                <Col span={8} className="mt-3" offset={3} >
                <h5 className="text-center" >États des dépenses : </h5>

                    <PieChart width={400} height={400} className="mt-0 pt-0">
                        <Pie
                            className="pt-0 mt-0"
                            dataKey="value"
                            startAngle={0}
                            endAngle={380}
                            data={thirdChart}
                            outerRadius={100}
                            fill="#8884d8"
                            label
                        >
                        {
                            data.map((entry, index) => <Cell fill={colors[index]}/>)
                        }
                        </Pie>
                        <Legend />
                        <Tooltip />

                    </PieChart>
                </Col>


            </Row>
        </Spin>
    );
}
