import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Uploader from "../../../helpers/Uploader";
import fileService from "../../../services/file.service";
import paiementService from "../../../services/paiement.service";
import paiementMethodes from "../../../services/paiementMethodes.service";
import messageAlert from "../../../UIcomponent/messages/messages";
import "../Fichedepnese.css";
import EcheanceTable from "../Tables/EcheanceTable";
import PaiementTable from "../Tables/PaiementTable";
import exportMessages from "../../../UIcomponent/messages/messages";

const AddPaiement = () => {
  const [form] = Form.useForm();
  const [detailsForm] = Form.useForm();
  const [methodesPaiement, setmethodesPaiement] = useState([]);
  const [methodes, setMethodes] = useState([]);
  const [encaissement, setEncaissement] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);

  const location = useLocation();

  const { Option } = Select;
  const { Panel } = Collapse;
  const { Dragger } = Upload;
  const { TextArea } = Input;

  const props = {
    multiple: true,
    onRemove(file) {},
    beforeUpload(file) {
      return false;
    },
  };
  const history = useHistory();

  const goback = () => {
    history.push(
      location.state.from === "paiement" ? "/Paiement" : "/Depensev2"
    );
  };
  useEffect(() => {
    getMethodesPaiement();
  }, []);

  const initMethodes = (data) => {
    setMethodes(data);
    let methodesSelect = data.map((el) => (
      <Option value={el.methode_paiement_id}>{el.nom}</Option>
    ));
    setmethodesPaiement(methodesSelect);
  };

  const calculeMontantEcheances = () => {
    let montant = 0;
    for (const element of location.state.record.echeances) {
      montant = montant + element.reste;
    }
    return montant;
  };
  const calculeMontantPaiements = () => {
    let montant = 0;
    for (const element of location.state.record.paiements) {
      montant = montant + element.montant;
    }
    return montant;
  };

  const getMethodesPaiement = async () => {
    let methodes = await paiementMethodes.getPaiementMethodes();
    if (methodes.status === 200) initMethodes(methodes.data);
  };

  const onFinish = (paiementData) => {
    if (
      Number(paiementData.montant) >
      Number(location.state.record.montant.total_ttc)
    ) {
      showConfirmModal(paiementData);
      
    } else formatteAndSendData(paiementData);
  };

  const showConfirmModal = (paiementData) => {
    Modal.error({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Le montant saisi est supérieur au montant restant à payer !",
      okText: "Annuler",
    
    });
  };

  const formatteAndSendData = (paiementData) => {
    const dateEncaissement = paiementData.dateEncaissement
      ? paiementData.dateEncaissement
      : paiementData.datePaiement;
    const paiement = {
      libelle: paiementData.libelle,
      dateEncaissement: moment(dateEncaissement).toDate(),
      datePaiement: moment(paiementData.datePaiement).toDate(),
      description: paiementData.description,
      methodePaiement: paiementData.methodePaiement,
      montant: paiementData.montant,
    };
    sendData(paiement);
  };
  const sendData = async (paiementData) => {
    const depenseId = location.state.record.depense_id;
    let resopnse = await paiementService.addPaiement(depenseId, paiementData);
    if ([200, 201].includes(resopnse.status)) {
      fileService.upload(selectedFiles, resopnse.data.paiement_id, "2");
      exportMessages.addSuccess("Paiement ajouter avec success");
      goback();
    } else {
      messageAlert.errorMessage("une erreur est survenue");
    }
  };
  const methodeChanged = () => {
    const methodeId = form.getFieldValue("methodePaiement");
    const methode = methodes.find(
      (methode) => methode.methode_paiement_id === methodeId
    );
    setEncaissement(methode.encaissement);
  };

  const resetForm = () => {
    form.resetFields();
  };

  const fileChange = (vakue) => {
    let arr = vakue.fileList.map((el) => el.originFileObj);
    setselectedFiles(arr);
    // setselectedFiles(vakue.target.files);
  };

  return (
    <Card
      headStyle={{ backgroundColor: "#dee0e9" }}
      title={
        <>
          <Row>
            <Col span={10}>
              <span onClick={() => goback()} className="goback">
                Retour
              </span>
            </Col>
            <Col span={14}>Paiement de {location.state.record.titre}</Col>
          </Row>
        </>
      }
    >
      <Form layout="vertical">
        <Row>
          <Col span={8} className="px-2" form={detailsForm}>
            <Form.Item
              label="Montant depense global "
              initialValue={location.state.record.montant.total_ttc}
              name="montantGlobal"
            >
              <Input type="number" size="large" disabled />
            </Form.Item>
          </Col>
          <Col span={8} className="px-2">
            <Form.Item
              label="Montant à payer : "
              initialValue={calculeMontantEcheances()}
              name="toPay"
            >
              <Input type="number" size="large" disabled />
            </Form.Item>
          </Col>
          <Col span={8} className="px-2">
            <Form.Item
              label="Montant payé : "
              initialValue={calculeMontantPaiements()}
              name="paied"
            >
              <Input type="number" size="large" disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row className="pb-4 px-2">
        <Collapse className="collapsetable">
          <Panel
            header={
              location.state.record.echeances > 1
                ? "Liste des écheances"
                : "Paiement Prévu"
            }
            key="1"
          >
            <EcheanceTable echeances={location.state.record.echeances} />
          </Panel>
          <Panel header="Liste des paiements" key="2">
            <PaiementTable
              paiements={location.state.record.paiements}
              depense={location.state.record}
              showUpdate={false}
            />
          </Panel>
        </Collapse>
      </Row>
      <>
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Row>
            <Col span={6} className="px-2">
              <Form.Item
                label="Montant à payer :  "
                name="montant"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire  !",
                  },{
                    message: "Champ obligataoire  !",
                  },
                ]}
              >
                <Input type="number" min={0} size="large" />
              </Form.Item>
            </Col>
            <Col span={6} className="px-2">
              <Form.Item
                label="Méthode de paiement :"
                name="methodePaiement"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire ! ",
                  },
                ]}
              >
                <Select size="large" onChange={() => methodeChanged()}>
                  {methodesPaiement}
                </Select>
              </Form.Item>
            </Col>
            <Col span={encaissement ? 6 : 12} className="px-2">
              <Form.Item
                label="Date du paiement:"
                name="datePaiement"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire ! ",
                  },
                ]}
              >
                <DatePicker
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {encaissement && (
              <Col span={6} className="px-2">
                <Form.Item
                  label="Date de décaissement:"
                  name="dateEncaissement"
                  rules={[
                    {
                      required: true,
                      message: "Champ obligatoire ! ",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={8} className="px-2">
              <Form.Item
                label="Libelle : "
                name="libelle"
                initialValue={
                  "Paiement-" +
                  location.state.record.titre +
                  "-" +
                  moment(new Date()).format("YYYY-MM-DD")
                }
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire !",
                  },
                ]}
              >
                <Input size="large" rows={1} />
              </Form.Item>
            </Col>
            <Col span={16} className="px-2">
              <Form.Item label="Commentaires : " name="description">
                <TextArea size="large" rows={1} />
              </Form.Item>
            </Col>

            <Col span={24} className="px-2">
              <Form.Item label="Piéce jointe : " name="file">
                <Uploader text="Dépense" fileChange={fileChange} />
                {/* <Dragger className="" {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Déposer des fichiers ici ou cliquer pour sélectionner
                  </p>
                  <p className="ant-upload-hint">
                    Vous pouvez télécharger plusieurs fichiers à la fois
                  </p>
                </Dragger> */}
              </Form.Item>
            </Col>
          </Row>
          <Row className="d-flex flex-row-reverse">
            <Button type="primary" htmlType="submit" className="mx-2 px-4">
              Ajouter
            </Button>
            <Button type="" className="mx-2" onClick={() => resetForm()}>
              Annuler
            </Button>
          </Row>
        </Form>
      </>
    </Card>
  );
};
export default AddPaiement;
