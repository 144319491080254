const country = [
  { id: 1, name: "Algerie", telCode: "+213" , currency : "DZD" },
  { id: 2, name: "Belgique", telCode: "+32" , currency : "EUR" },
  { id: 3, name: "Egypte", telCode: "+20" , currency : "EGP" },
  { id: 4, name: "France", telCode: "+33" , currency : "EUR" },
  { id: 5, name: "Allemagne", telCode: "+49" , currency : "EUR" },
  { id: 6, name: "Italie", telCode: "+39" , currency : "EUR" },
  { id: 7, name: "Luxembourg", telCode: "+352" , currency : "EUR" },
  { id: 8, name: "Liban", telCode: "+961" , currency : "LBP" },
  { id: 9, name: "Libye", telCode: "+218" , currency : "LYD" },
  { id: 10, name: "Monaco", telCode: "+377" , currency : "EUR" },
  { id: 11, name: "Oman", telCode: "+968" , currency : "OMR" },
  { id: 12, name: "Portugal", telCode: "+351" , currency : "EUR" },
  { id: 13, name: "Qatar", telCode: "+974" , currency : "QAR" },
  { id: 14, name: "Arabie Seoudite", telCode: "+966" , currency : "SAR" },
  { id: 15, name: "Suisse", telCode: "+41" , currency : "CHE" },
  { id: 16, name: "Tunisie", telCode: "+216" , currency : "TND" },
  { id: 17, name: "Turquie", telCode: "+90" , currency : "TRY" },
  { id: 18, name: "Emirats Arabes Unis", telCode: "+971" , currency : "AED" },
  { id: 19, name: "Royaume-Uni", telCode: "+44" , currency : "GBP" },
  { id: 20, name: "Etats-Unis", telCode: "+1" , currency : "USD" },
];

export default country;