import {
    CREATE_FREQUENCE,
    RETRIEVE_FREQUENCE,
    } from "../actions/type";
    
    const initialState = [];
    
    function frequenceReducer(frequence = initialState, action) {
      const { type, payload } = action;
    
      switch (type) {
        case CREATE_FREQUENCE:
          return [...frequence, payload];
    
        case RETRIEVE_FREQUENCE:
          return payload;

    
        // case UPDATE_USERS:
        //   return users.map((user) => {
        //     if (user.id === payload.id) {
        //       return {
        //         ...user,
        //         ...payload,
        //       };
        //     } else {
        //       return user;
        //     }
        //   });
    
        // case DELETE_DEPENSE:
        //   return depenseType.filter(({ id }) => id !== payload.id);

        default:
          return frequence;
      }
    };
    
    export default frequenceReducer;