import { SET_MESSAGE, CLEAR_MESSAGE, LOGIN_SUCCESS, LOGIN_FAIL } from "./type";

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  payload: message,
});
export const setMessage1 = (message) => ({
  type: LOGIN_SUCCESS,
  payload: message,
});
export const setMessage2 = (message) => ({
  type: LOGIN_FAIL,
  payload: message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});
