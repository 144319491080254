import {
    CREATE_DEPENSE,
    RETRIEVE_DEPENSE,
    RETRIEVE_DEPENSERECCURENT,
    DELETE_DEPENSE
    } from "../actions/type";
    
    const initialState = [];
    
    function depensesReducer(depense = initialState, action) {
      const { type, payload } = action;
      switch (type) {
        case CREATE_DEPENSE:
          return [...depense, payload];
    
        case RETRIEVE_DEPENSE:
          return payload;

          case RETRIEVE_DEPENSERECCURENT:
            return payload;
    
        case DELETE_DEPENSE:
          return depense.filter(({ id }) => id !== payload.id);

        default:
          return depense;
      }
    };
    
    export default depensesReducer;