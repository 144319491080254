import React from "react";
import { useDispatch } from "react-redux";
import { Card } from "antd";
import DashboardLeadChart from "../Dashboard/DashboardLeadChart";

function Home() {
    const dispatch = useDispatch();
    return (
        <>
            <Card style={{ width: "100%" }}>
                <h4 className="text-center">
                    Bienvenue à l'application de gestion financière et comptable
                    <br />
                </h4>
            </Card>
            <Card style={{ width: "100%" }}>
                <div className=" my-5">
                    <DashboardLeadChart></DashboardLeadChart>
                </div>
            </Card>
        </>
    );
}
export default Home;
