import authHeader from "./auth-header";

import axios from "axios";
import { environment } from "../env/config.env";
import moment from "moment";
const API_URL = environment.api;
let EntrepriseId ; 
const addPaiement = (id, paiement) => {
  return axios.post(API_URL + "paiement/" + id + "/" +EntrepriseId, paiement, {
    headers: authHeader(),
  });
};
const updatePaiement = (depenseId, paiementId, paiement) => {
  return axios.put(
    API_URL + "paiement/" + depenseId + "?paiementId=" + paiementId,
    paiement,
    {
      headers: authHeader(),
    }
  );
};

const getPaiements = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "paiements/" + EntrepriseId, { headers: authHeader() });
};

const getPaiement = (id) => {
  return axios.get(API_URL + "paiement/" + id, {
    headers: authHeader(),
  });
};

const deletePaiement = (depenseId, paiementId) => {
  const paiement = {
    montant: 0,
    libelle: "",
    description: "",
    datePaiement: moment(new Date()).toDate(),
    dateEncaissement: moment(new Date()).toDate(),
    methodePaiement: 0,
  };
  return axios.put(
    API_URL + "paiement/delete/" + depenseId + "?paiementId=" + paiementId,
    paiement,
    {
      headers: authHeader(),
    }
  );
};

const paiementService = {
  addPaiement,
  getPaiements,
  getPaiement,
  updatePaiement,
  deletePaiement,
};
export default paiementService;
