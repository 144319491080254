import {
    ExclamationCircleOutlined,
    LogoutOutlined,
    ToolOutlined,
    UserOutlined
} from "@ant-design/icons";
import { Layout, Menu, Modal, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { logout } from "../../actions/auth";
import Can from "../../Can/Can";
import modulenamesService from "../../services/nom_modules.service";
import alertMessage from "../messages/messages";
const { SubMenu } = Menu;

const { Header } = Layout;

function NavbarTop({ user }) {
    const history = useHistory();
    const location = useLocation();

    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [myKey, setMyKey] = useState([]);
    const [myUser, setMyUser] = useState("");
    const [modules, setModules] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getRoute();
        getallModuleNames();
        
    }, [location]);
    
    const getallModuleNames = async () => {
        const res = await modulenamesService.getallModuleNamesTop() ;
        setModules( res.data );
    };

    const getRoute = () => {
        const selectedRoute = [];
        switch (location.pathname) {
            case "/methode_paiement":
                selectedRoute.push("2");
                break;
            case "/Type_Paiement":
                selectedRoute.push("3");
                break;
            case "/Taxes":
                selectedRoute.push("4");
                break;
            case "/Contrat":
                selectedRoute.push("5");
                break;
            case "/Vehicules":
                selectedRoute.push("6");
                break;
            case "/Fournisseurs":
                selectedRoute.push("7");
                break;
            case "/Frequence":
                selectedRoute.push("8");
                break;
            case "/TypeCategorie":
                selectedRoute.push("9");
                break;
            case "/TypeEcheance":
                selectedRoute.push("10");
                break;
            case "/exercice":
                selectedRoute.push("11");
                break;
            case "/rapport":
                selectedRoute.push("12");
                break;
            case "/balance_config":
                selectedRoute.push("13");
                break;
            default:
        }
        setMyKey(selectedRoute);
    };
    const logOut = () => {
        dispatch(logout());
       !!currentUser.type ?history.push("/signinexpert") :  history.push("/signin");
    };

    const navigate = (location) => {
        history.push(location);
    };
    const goToProfil = () => {
        alertMessage.errorMessage(
            "Cette fonctionnalité est en cours de développement  "
        );
    };

    const confirmLogout = () => {
        Modal.confirm({
            title: "Attention",
            icon: <ExclamationCircleOutlined />,
            content: "Êtes-vous sûr de vouloir vous déconnecter ? ",
            okText: "Oui",
            onOk: () => logOut(),
            cancelText: "Non",
        });
    };

    const getModuleName = ( serachModules , defaultName ) => {
        return serachModules ? serachModules?.find( ( e ) => e.defaul_name === defaultName )?.module_nom ?? defaultName : defaultName;
    }

    return (
        <Spin spinning={loading}>
            <Header
                className="header mb-12"
                theme="dark"
                style={{
                    backgroundColor: "#1b3b59",
                }}
            >
                <div className="logo" />
                <Menu
                    mode="horizontal"
                    defaultOpenKeys={["30"]}
                    theme="dark"
                    selectedKeys={myKey}
                    style={{
                        backgroundColor: "#1b3b59",
                        minWidth: "100%",
                        width: "100%",
                        maxWidth: "100%",
                        display: "inline",
                        textAlign: "center",
                    }}
                >
                    <>
                        <Menu.Item
                            key="30"
                            onClick={() => goToProfil()}
                            icon={<UserOutlined />}
                        >
                            {user}
                        </Menu.Item>

                        <SubMenu
                            key="Param"
                            icon={<ToolOutlined />}
                            title={(module &&module[0]?.module_nom )? module[0].module_nom
                                : "Paramétres"}
                        >
                            <Menu.Item
                                onClick={() => navigate("/methode_paiement")}
                                key="2"
                                disabled={((!currentUser.type&&!Can("6_1")))}
                            >
                                <span>
                                    { getModuleName( modules, "Méthode de Paiement") }
                                </span>
                            </Menu.Item>

                            <Menu.Item
                                onClick={() => navigate("/Taxes")}
                                key="4"
                                disabled={(!currentUser.type&&!Can("8_1"))}
                            >
                                <span>
                                    { getModuleName( modules ,"Taxes et TVA") }
                                </span>
                            </Menu.Item>

                            <Menu.Item
                                onClick={() => navigate("/Contrat")}
                                key="5"
                                disabled={(!currentUser.type&&!Can("10_1"))}
                            >
                                <span>
                                    { getModuleName(modules, "Contrat") }
                                </span>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => navigate("/Vehicules")}
                                key="6"
                                disabled={(!currentUser.type&&!Can("11_1"))}
                            >
                                <span>
                                    { getModuleName( modules, "Véhicules") }
                                </span>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => navigate("/Fournisseurs")}
                                key="7"
                                disabled={(!currentUser.type&&!Can("12_1"))}
                            >
                                <span>
                                    { getModuleName( modules, "Fournisseurs") }

                                </span>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => navigate("/Frequence")}
                                key="8"
                                disabled={(!currentUser.type&&!Can("13_1"))}
                            >
                                <span>
                                    
                                    <span>
                                        { getModuleName(modules, "Fréquences") }
                                    </span>
                                </span>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => navigate("/exercice")}
                                key="11"
                                disabled={(!currentUser.type&&!Can("13_1"))}
                            >
                                <span>
                                    
                                    <span>
                                        { getModuleName(modules, "Exercice") }
                                    </span>
                                </span>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => navigate("/rapport_helper")}
                                key="12"
                                disabled={(!currentUser.type&&!Can("13_1"))}
                            >
                                <span>
                                    <span>
                                        { getModuleName(modules, "Rapport") }
                                    </span>
                                </span>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => navigate("/balance_config")}
                                key="13"
                                disabled={(!currentUser.type&&!Can("13_1"))}
                            >
                                <span>
                                    <span>
                                        { getModuleName(modules, "Configuration des rubriques") }
                                    </span>
                                </span>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => navigate("/TypeCategorie")}
                                key="9"
                            >
                                <span>
                                    <span>
                                        { getModuleName(modules, "Type De Catégorie") }
                                    </span>
                                </span>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => navigate("/NomModule")}
                                key="10"
                            >
                                <span>
                                    { getModuleName(modules, "Noms Des Modules") }
                                </span>
                            </Menu.Item>
                        </SubMenu>

                        <Tooltip title="Déconnexion">
                            <Menu.Item
                                key="20"
                                onClick={() => confirmLogout()}
                                icon={<LogoutOutlined />}
                            >
                                Déconnexion
                            </Menu.Item>
                        </Tooltip>
                    </>
                </Menu>
            </Header>
        </Spin>
    );
}
export default NavbarTop;
