import {
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import UserService from "../../../services/user.service";
import messages from "../../../UIcomponent/messages/messages";

const PasswordModal = ({ userId, isVisible, closingModal }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [passwordForm] = Form.useForm();

  useEffect(() => {
    return () => {
      clearData();
    };
  }, []);

  const confirmUpdate = (values) => {
    Modal.confirm({
      className: "mt-5",
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content:
        "Êtes-vous sûr de vouloir modifier le mot de passe de l'utilisateur  ",
      onOk: () => updatePassword(values),
      okText: "Oui",
      cancelText: "Non",
    });
  };

  const updatePassword = async (values) => {
    const updatePasswordRequest = values;
    const updatedUserId = JSON.parse(localStorage.getItem("user")).id;
    updatePasswordRequest.adminId = updatedUserId;
    const response = await UserService.updatePassword(
      userId,
      updatePasswordRequest
    );
    if (response.status === 200 || 204) {
      if (response.data.result) {
        updateSuccess();
        close();
      } else {
        messages.costumErrorMessage(response.data.message);
      }
    } else errorMessage();
  };

  const close = () => {
    closingModal();
    clearData();
  };

  const clearData = () => {
    passwordForm.resetFields();
  };

  const updateSuccess = () => {
    messages.updateSuccess("Mise à jours avec success");
    closingModal();
  };
  const errorMessage = () => {
    setConfirmLoading(false);
    messages.errorMessage();
  };

  return (
    <Modal
      title={"Modifier le mot de passe "}
      centered
      okText="Enregistrer"
      cancelText="Annuler"
      getContainer={false}
      visible={isVisible}
      confirmLoading={confirmLoading}
      onOk={passwordForm.submit}
      onCancel={close}
      width={600}
    >
      <Form layout="vertical" form={passwordForm} onFinish={confirmUpdate}>
        <Row>
          <Col span={24} className="px-2">
            <Form.Item
              label="Votre Mot de passe"
              name="adminPassword"
              rules={[
                { required: true, message: "Champ est obligatoire" },
                { max: 99, message: " maximum de 100 caractères !" },
              ]}
            >
              <Input.Password
                size="large"
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>
          <Col span={24} className="px-2">
            <Form.Item
              label="Le nouveau mot de passe de l'utilisateur "
              name="userPassword"
              help="Minimum 8 caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial ( @ $ ! % * ? & )"
              rules={[
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/g,
                  message: "Format Incorrecte",
                },

                { required: true, message: "Champ est obligatoire" },
                { max: 99, message: " maximum de 100 caractères !" },
              ]}
            >
              <Input.Password
                size="large"
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default PasswordModal;
