import {
  CloseOutlined,
  CloudDownloadOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Tooltip
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { getAllFournisseur } from "../../actions/fournisseur";
import { getAllFrequence } from "../../actions/frequence";
import country from "../../helpers/country";
import {
  default as currency,
  default as currencys
} from "../../helpers/currency";
import Uploader from "../../helpers/Uploader";
import DepenseService from "../../services/depense.service";
import entrepriseService from "../../services/entreprise.service";
import fileService from "../../services/file.service";
import TaxeService from "../../services/Taxe.service";
import exportMessages from "../../UIcomponent/messages/messages";
import ProfitCenterAndCategorieSelect from "./ProfitCenterAndCategorieSelect";
import UpdateDetailsDepense from "./UpdateDetailsDepense";

const { Option, OptGroup } = Select;

function UpdateDepenseForm({ depense }) {
  const location = useLocation();
  const [montantTotal, setMontantTotal] = useState({});
  const [selectedFiles, setselectedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState();
  const [message, setMessage] = useState();
  const [fileInfos, setFileInfos] = useState();
  const [FileRes, setFileRes] = useState();
  const [defaultValues, setdefaultValues] = useState();

  const [isLoading, setLoading] = useState(false);
  const [echanceForm, setEchanceFormVisible] = useState(false);
  const [recurrenceForm, setRecurrenceFormVisible] = useState(false);
  const [categorieState, setCategorie] = useState();
  const [profitCenterState, setProfitCenter] = useState();
  const [ligne_depenses, setLignesDepense] = useState([]);
  const [visible, setVisible] = useState(false);
  const [alltaxes, setTaxes] = useState([]);
  const [dbTaxe, setBdTaxes] = useState([]);
  const [dbTva, setBdTva] = useState([]);
  const [dbTimbre, setBdTimbre] = useState([]);
  const [fournisseurForm, setFounisseurForm] = useState(false);
  const [validationButton, setValidationButton] = useState(false);

  const history = useHistory();
  const [depenseForm] = Form.useForm();
  const [isDetailsFormDisabled, setDetailsFormDisabled] = useState(true);
  const [isTaxesSelectDisabled, setTaxesSelectDisabled] = useState(true);

  const [depensetoUpdate, setDepensetoUpdate] = useState(depense);
  const frequenceData = useSelector((state) => state.frequenceReducer);
  const fournisseurData = useSelector((state) => state.fournisseurReducer);
  const dispatch = useDispatch();
  const getDepenses = async () => {
    let res = await DepenseService.getDepenses();
    return res.data;
  };

  useEffect(() => {
    getTaxes();
    dispatch(getAllFrequence());
    dispatch(getAllFournisseur());
    getEntrepriseInfo();
    initUpdateForm();
    return () => {};
  }, [depensetoUpdate]);

  const initUpdateForm = () => {
    depenseForm.setFieldsValue(depense);
    const profitCenterId = depense.profitCenter.profitCenter_id;
    const categorieId = depense?.categorie?.category_id;
    const type = depense.echeances.length > 1 ? true : false;
    const montant = depense.montant.total_ttc;
    const fournisseur_id = !depense.interne
      ? depense.fournisseur.fournisseur_id
      : null;
    const date_premier_paiement = moment(depense.firstPaiementDate);
    const nbr_echeance = depense.echeances.length;
    const taxes = [];
    for (const taxe of depense.montant.taxes) taxes.push(taxe.taxe_id);
    const total_ht = depense.montant.total_ht;
    const total_taxes = montant - total_ht;
    const reccurent = depense?.depense_occurence ? true : false;
    const frequence_echeance = depense.echeances[0]?.frequence_echeance ?? null;
    const periode = depense.depense_occurence?.periode ?? null;
    const frequence_recurrence = getFrequenceIndex(
      depense?.depense_occurence?.frequence?.frequence_id
    );

    depenseForm.setFieldsValue({
      type,
      montant,
      taxes,
      fournisseur_id,
      date_premier_paiement,
      nbr_echeance,
      total_ht,
      total_taxes,
      frequence_echeance,
      periode,
      reccurent,
      frequence_recurrence,
    });
    depenseStatusChanged();

    setFounisseurForm(depense.interne);
    setRecurrenceFormVisible(reccurent);
  };
  const getFrequenceIndex = (id) => {
    for (const [i, frequence] of frequenceData.entries()) {
      if (frequence.frequence_id === id) return i;
    }
  };

  const getEntrepriseInfo = async () => {
    setLoading(true);
    const response = await entrepriseService.getEntreprise(1);
    depenseForm.setFieldsValue({ entreprise: response.data.nom });
    setLoading(false);
  };

  const setMontantTTC = () => {
    const montant = {
      total_ttc: depenseForm.getFieldValue("montant"),
      total_ht: depenseForm.getFieldValue("total_ht"),
    };
    setMontantTotal(montant);
  };

  const showModal = () => {
    setMontantTTC();
    setVisible(true);
  };
  const enableTaxesSelect = () => {
    if (depenseForm.getFieldValue("montant") > 0) setTaxesSelectDisabled(false);
    else setTaxesSelectDisabled(true);
  };

  const getTaxes = async () => {
    const response = await TaxeService.getallTaxes();
    const taxeAndTva = response.data;
    const taxe = taxeAndTva.filter((e) => e.type === "Taxe");
    const tva = taxeAndTva.filter((e) => e.type === "Tva");
    const timbre = taxeAndTva.filter((e) => e.type === "Timbre");
    setTaxes(taxeAndTva);
    setBdTaxes(taxe);
    setBdTva(tva);
    setBdTimbre(timbre);
  };

  const addDepense = () => {
    if (checkEcheanceChangement()) {
      if (depenseForm.getFieldValue("type")) {
        Modal.confirm({
          title: "Attention",
          width: 600,
          icon: <ExclamationCircleOutlined />,
          content:
            "La modification du nombre des échéances, la fréquence des échéances, de la date du premier paiement ou du montant total entraîne la modification des échéances existants.",
          okText: "Continuer",
          onOk: () => updateDepense(),
          cancelText: "Annuler",
        });
      }else{
        updateDepense()  
      }
    }else{
      updateDepense() 
    }
  };

  const updateDepense = () => {
    setLoading(true);

    const date_creation = new Date();
    const depenseFormValues = depenseForm.getFieldsValue();
    const echeances = [];
    const occurence_dates = [];
    const firstPaiement = depenseFormValues.date_premier_paiement;
    if (depenseFormValues.type) {
      const montantEcheance =
        Math.round(
          (depenseFormValues.montant / depenseFormValues.nbr_echeance) * 100
        ) / 100;

      const frequence = frequenceData[depenseFormValues.frequence_echeance];
      const type = handelTypes(frequence.type);
      echeances.push({
        libelle: "Echeance-001",
        date_limite: firstPaiement.toDate(),
        montant: montantEcheance,
        reste: montantEcheance,
        frequence_echeance: depenseFormValues.frequence_echeance,
        etat: "à payé",
      });
      var paiementDate = firstPaiement;
      for (let i = 0; i < depenseFormValues.nbr_echeance - 1; i++) {
        paiementDate = moment(paiementDate).add(frequence.periode, type);
        const echanceNumber = "".padStart(2, 0) + Number(i + 2);
        echeances.push({
          libelle: "Echeance-" + echanceNumber,
          date_limite: paiementDate.toDate(),
          montant: montantEcheance,
          reste: montantEcheance,
          frequence_echeance: depenseFormValues.frequence_echeance,
          etat: "à payé",
        });
      }
    } else {
      echeances.push({
        libelle: "Echeance-001",
        date_limite: firstPaiement.toDate(),
        montant: depenseFormValues.montant,
        reste: depenseFormValues.montant,
        etat: "à payé",
      });
    }
    if (depenseFormValues.reccurent) {
      const paiementDate = depenseFormValues.date_premier_paiement;
      const periode_recurrence = frequenceData[depenseFormValues.periode];
      const limiteDate = moment(paiementDate).add(
        periode_recurrence.periode,
        handelTypes(periode_recurrence.type)
      );
      const frequence_recurrence =
        frequenceData[depenseFormValues.frequence_recurrence];
      var currentDate = paiementDate;
      depenseFormValues.frequence_recurrence =
        frequence_recurrence.frequence_id;
      var occurence = 0;
      while (currentDate.isBefore(limiteDate)) {
        currentDate = moment(currentDate).add(
          frequence_recurrence.periode,
          handelTypes(frequence_recurrence.type)
        );
        occurence_dates.push({ date_prevu: moment(currentDate).toDate() });
        occurence = occurence + 1;
      }
    }
    const montant = {
      total_ht: depenseFormValues.total_ht,
      total_ttc: depenseFormValues.montant,
      taxes: depenseFormValues.taxes,
    };

    if (!ligne_depenses || ligne_depenses === undefined)
      setLignesDepense(new Array(0));
    const categAndProfitArray = location.state?.record.categorieAndPc;
    const categorie = categorieState
      ? categorieState
      : categAndProfitArray[categAndProfitArray.length - 1];
    const profitCenter = profitCenterState
      ? profitCenterState
      : categAndProfitArray[0];

    const depense = {
      ...depenseFormValues,
      categorie,
      profitCenter,
      date_creation,
      occurence,
      occurence_dates,
      echeances,
      ligne_depenses,
      montant,
    };
    saveDepense(depense);
  };

  const checkEcheanceChangement = () => {
    return (
      depenseForm.isFieldTouched("nbr_echeance") ||
      depenseForm.isFieldTouched("frequence_echeance") ||
      depenseForm.isFieldTouched("montant") ||
      depenseForm.isFieldTouched("date_premier_paiement")
    );
  };

  const enablingForm = () => {
    setValidationButton(true);
  };

  const saveDepense = async (newDepense) => {
    newDepense.file = null;
    const depenseId = depense.depense_id;
    const response = await DepenseService.updateDepense(depenseId, newDepense);
    if ([201, 200].includes(response.status)) {
      upload()
      exportMessages.addSuccess("Dépense mise à jours ");
      history.push("/Depensev2");
    } else {
      exportMessages.errorMessage("une erreur est surevenue");
    }
    setLoading(false);
  };

  const handelTypes = (type) => {
    switch (type) {
      case "année":
        return "y";
      case "mois":
        return "M";
      default:
        return "d";
    }
  };

  const totalTaxeChanged = () => {
    const selectedTaxes = depenseForm.getFieldValue("taxes");
    const total_ttc = depenseForm.getFieldValue("montant");
    const taxes = alltaxes.filter(
      (taxe) => selectedTaxes.includes(taxe.taxe_id) && taxe.type !== "Timbre"
    );
    const timbres = alltaxes.filter(
      (taxe) => selectedTaxes.includes(taxe.taxe_id) && taxe.type === "Timbre"
    );

    let total_timbres = 0;
    let total_taxes = 0;
    let total_ht = total_ttc;
    for (const element of timbres) {
      total_timbres += Number(element.valeur);
    }
    for (const element of taxes) {
      total_taxes += Number(element.valeur);
    }
    const taxes_value = (total_ht * total_taxes) / 100 + total_timbres;
    const finalTaxesValue = Math.round(taxes_value * 100) / 100;
    total_ht = Math.round((total_ht - taxes_value) * 100) / 100;
    depenseForm.setFieldsValue({ total_taxes: finalTaxesValue, total_ht });
    if (depenseForm.getFieldValue("total_ht") > 0)
      setDetailsFormDisabled(false);
  };

  const profitCenterSelection = (data) => {
    setProfitCenter(data.profitCenter);
    setCategorie(data.categorie);
    depenseForm.setFieldsValue({ categorieAndProfitCenter: data.profitCenter });
  };

  const depenseStatusChanged = () => {
    setEchanceFormVisible(depenseForm.getFieldValue("type"));
  };
  const selectFileEcheance = (event) => {};
  const setVisiblityFalse = () => {
    setVisible(false);
  };

  const changeName = (name) => {
    if (name === "année") return "ans";
    return name;
  };

  const handelDepenseSubFormClosure = (montant, ligne_depense) => {
    setLignesDepense(ligne_depense);
    setVisiblityFalse();
  };

  const resetForm = () => {
    depenseForm.resetFields();
    history.push("/Depensev2");
  };

  const props = {
    name: "file",
    multiple: true,
    onChange(files) {
      setselectedFiles(files.fileList);
    },
    onRemove(file) {},
    beforeUpload(file) {
      return false;
    },
  };

  const upload = async () => {
   if ( selectedFiles.length === 0){
     return
   }
    const res = await fileService.upload(
      selectedFiles,
      depense.depense_id,
      "1"
    );
    if (res.every((el) => el.status === 201)) {
      setselectedFiles();
      let newFilesData = res.map((el) => el.data);

      setselectedFiles();
      setDepensetoUpdate({
        ...depensetoUpdate,
        file_id: [...depensetoUpdate.file_id, ...newFilesData],
      });
      exportMessages.addSuccess("Fichier est bien ajouté");
    } else {
      exportMessages.errorMessage("une erreur est survenue");
    }
  };
  const fileChange = (value) => {
    let arr = value.fileList.map((el) => el.originFileObj);
    setselectedFiles(arr);
  };
  const getReccurenceValue = (event) => {
    setRecurrenceFormVisible(event.target.value);
  };
  const getFournisseurValue = (event) => {
    setFounisseurForm(event.target.value);
  };

  const getCurrency = (devise) => {
    return currencys.filter((currency) => currency.id === devise).code;
  };

  const onChange = () => {};

  const downloadFile = async (file) => {
    await fileService.getFile(file.file_id, "1").then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
    });
  };
  const deleteFile = async (file) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce fichier ? ",
      onOk: async () => {
        let res = await fileService.deleteFile(file, "1");
        // location.state.record = {
        //   ...depense,
        //   file_id: depense.file_id.filter((el) => el !== file),
        // };

        setselectedFiles();
        let newDepense = {
          ...depensetoUpdate,
          file_id: [...depensetoUpdate.file_id.filter((el) => el !== file)],
        };
        setDepensetoUpdate(newDepense);
      },
      okText: "Oui",
      cancelText: "Non",
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        layout="vertical"
        form={depenseForm}
        onFinish={addDepense}
        onValuesChange={() => enablingForm()}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Informations" key="1">
            <Row>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Titre de la dépense"
                  name="titre"
                  rules={[
                    { required: true, message: "Champ obligatoire !" },
                    { message: "maximum 100 caractères ", max: 99 },
                  ]}
                >
                  <Input placeholder="" size="large" maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: "Champ obligatoire !" }]}
                >
                  <Select
                    size="large"
                    defaultValue="--Sélectionner un statut--"
                    onChange={() => depenseStatusChanged()}
                  >
                    <Select.Option value={false}>
                      Paiement au comptant
                    </Select.Option>
                    <Select.Option value={true}>
                      Paiement à échéance
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={6} className="px-2">
                <Form.Item
                  name="montant"
                  label="Montant TTC"
                  rules={[{ required: true, message: "Champ Obligatoire" }]}
                >
                  <Input size="large" onChange={() => enableTaxesSelect()} />
                </Form.Item>
              </Col>
              <Col span={6} className="px-2">
                <Form.Item
                  label="Devise"
                  name="devise"
                  rules={[{ required: true, message: "Champ obligatoire !" }]}
                >
                  <Select size="large">
                    {currency.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  name="taxes"
                  label="Taxes applique"
                  rules={[{ required: true, message: "Champ Obligatoire" }]}
                >
                  <Select
                    size="large"
                    onChange={totalTaxeChanged}
                    mode="multiple"
                  >
                    <OptGroup label="TVA">
                      {dbTva.map((tva, index) => (
                        <Select.Option key={tva.taxe_id} value={tva.taxe_id}>
                          {tva.nom} - {tva.valeur} %
                        </Select.Option>
                      ))}
                    </OptGroup>
                    <OptGroup label="Taxes">
                      {dbTaxe.map((taxe, index) => (
                        <Select.Option key={taxe.taxe_id} value={taxe.taxe_id}>
                          {taxe.nom} - {taxe.valeur} %
                        </Select.Option>
                      ))}
                    </OptGroup>
                    <OptGroup label="TimbreFiscal">
                      {dbTimbre.map((taxe, index) => (
                        <Select.Option key={taxe.taxe_id} value={taxe.taxe_id}>
                          {taxe.nom} - {taxe.valeur} {getCurrency(taxe.devise)}
                        </Select.Option>
                      ))}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} className="px-2">
                <Form.Item name="total_taxes" label="Montant des Taxes">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col span={6} className="px-2">
                <Form.Item name="total_ht" label="Montant Hors Taxe">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>

              <Col span={12} className="px-2">
                <Form.Item label=" ">
                  <Button type="dashed" block size="large" onClick={showModal}>
                    Ajouter plus de détails
                  </Button>
                </Form.Item>
              </Col>

              <Col span={18} className="px-2">
                <Form.Item
                  label="Categorie et centre de profit "
                  name="categorieAndProfitCenter"
                  rules={[{ required: true, message: "Champ obligatoire !" }]}
                  initialValue={location.state?.record.categorieAndPc}
                >
                  <ProfitCenterAndCategorieSelect
                    defaultValues={location.state?.record.categorieAndPc}
                    profitCenterSelection={(data) =>
                      profitCenterSelection(data)
                    }
                  ></ProfitCenterAndCategorieSelect>
                </Form.Item>
              </Col>
              <Col span={6} className="px-2">
                <Form.Item
                  label="Pays"
                  name="pays"
                  rules={[{ required: true, message: "Champ obligatoire !" }]}
                >
                  <Select size="large">
                    {country.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12} className="px-2">
                <Form.Item
                  name="interne"
                  label="Cette dépense est-elle une dépense interne ?"
                  rules={[{ required: true, message: "Champ obligatoire !" }]}
                >
                  <Radio.Group onChange={(e) => getFournisseurValue(e)}>
                    <Radio value={true}>Oui</Radio>
                    <Radio value={false}>Non</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {fournisseurForm ? (
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Entreprise"
                    name="entreprise"
                    rules={[{ required: true, message: "Champ obligatoire !" }]}
                  >
                    <Input size="large" disabled />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Fournisseur"
                    name="fournisseur_id"
                    rules={[{ required: true, message: "Champ obligatoire !" }]}
                  >
                    <Select size="large" style={{ width: "100%" }}>
                      {fournisseurData.map((fournisseur) => (
                        <Select.Option
                          key={fournisseur.fournisseur_id}
                          value={fournisseur.fournisseur_id}
                        >
                          {fournisseur.nom}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {echanceForm ? (
                <>
                  <Col span={8} className="px-2">
                    <Form.Item
                      label="Nombre d'echeance"
                      name="nbr_echeance"
                      rules={[
                        { required: true, message: "Champ obligatoire !" },
                      ]}
                    >
                      <Select size="large" style={{ width: "100%" }}>
                        {[...Array(99)].map((x, i) => (
                          <Select.Option key={i + 1} value={i + 1}>
                            {i + 1}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8} className="px-2">
                    <Form.Item
                      label="Frequence des paiements"
                      name="frequence_echeance"
                      rules={[
                        { required: true, message: "Champ obligatoire !" },
                      ]}
                    >
                      <Select size="large" style={{ width: "100%" }}>
                        {frequenceData.map((frequence, index) => (
                          <Select.Option key={index} value={index}>
                            Chaque {frequence.periode}
                            {changeName(frequence.type)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8} className="px-2">
                    <Form.Item
                      label="Date du premier paiement"
                      name="date_premier_paiement"
                      rules={[
                        { required: true, message: "Champ obligatoire !" },
                      ]}
                    >
                      <DatePicker
                        placeholder=""
                        size="large"
                        style={{ width: "100%" }}
                        onChange={onChange}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Date du premier paiement"
                      name="date_premier_paiement"
                      rules={[
                        { required: true, message: "Champ obligatoire !" },
                      ]}
                    >
                      <DatePicker
                        placeholder=""
                        size="large"
                        style={{ width: "100%" }}
                        onChange={onChange}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col span={24} className="px-2">
                <Form.Item
                  name="reccurent"
                  label="Cette depense est elle recurrente ? "
                  rules={[{ required: true, message: "Champ obligatoire !" }]}
                >
                  <Radio.Group onChange={(e) => getReccurenceValue(e)}>
                    <Radio value={true}>Oui</Radio>
                    <Radio value={false}>Non</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {recurrenceForm && (
                <>
                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Frequence de recurrence"
                      name="frequence_recurrence"
                      rules={[
                        { required: true, message: "Champ obligatoire !" },
                      ]}
                    >
                      <Select size="large" style={{ width: "100%" }}>
                        {frequenceData.map((frequence, index) => (
                          <Select.Option key={index} value={index}>
                            Tous les {frequence.periode} {frequence.type}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} className="px-2">
                    <Form.Item
                      label="Periode"
                      name="periode"
                      rules={[
                        { required: true, message: "Champ obligatoire !" },
                      ]}
                    >
                      <Select size="large" style={{ width: "100%" }}>
                        {frequenceData.map((frequence, index) => (
                          <Select.Option key={index} value={index}>
                            Durant {frequence.periode} {frequence.type}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Description et piéces jointes" key="2">
            <Form.Item label="Description" name="description">
              <Input.TextArea cols={5} rows={5} />
            </Form.Item>
            {depensetoUpdate.file_id.map((file) => (
              <Alert
                key={file.file_id}
                message={file.name}
                type="success"
                className="my-1"
                action={
                  <Space direction="horizonal">
                    <Tooltip title="Supprimer">
                      <Button
                        type="danger"
                        shape="circle"
                        icon={<CloseOutlined />}
                        onClick={() => {
                          deleteFile(file);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Telecharger">
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<CloudDownloadOutlined />}
                        onClick={() => downloadFile(file)}
                      />
                    </Tooltip>
                  </Space>
                }
              />
            ))}

            <Form.Item name="file">
              <Uploader text="Charge" fileChange={fileChange} />
            </Form.Item>
            {/* <Button
              type="primary"
              className="ml-2"
              shape="round"
              onClick={async () => {
                upload();
              }}
            >
              Ajouter fichier
            </Button> */}
          </Tabs.TabPane>
        </Tabs>

        <Row className="d-flex flex-row-reverse">
          <Button
            type="primary"
            htmlType="submit"
            className="mx-2 px-4"
            disabled={!validationButton}
          >
            Valider
          </Button>
          <Button type="" className="mx-2" onClick={() => resetForm()}>
            Annuler
          </Button>
        </Row>
      </Form>

      <UpdateDetailsDepense
        isVisible={visible}
        montantTotal={montantTotal}
        lignesDepense={depense.ligne_depenses}
        closeModal={(depense, ligne_depense) =>
          handelDepenseSubFormClosure(depense, ligne_depense)
        }
      ></UpdateDetailsDepense>
    </Spin>
  );
}
export default UpdateDepenseForm;
