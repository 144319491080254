import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Select, Image, Row, Col , Modal } from "antd";
import expertService from "../../../services/expert.service";
import { history } from "../../../helpers/history";
import userImage from "../../../assets/images/user.png";
import entrepriseImage from "../../../assets/images/entreprise.png";
import "./expert.css";
import { ExclamationCircleOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import authService from "../../../services/auth.service";
import exportMessages from "../../../UIcomponent/messages/messages";
import { useDispatch } from "react-redux";
import { logout } from "../../../actions/auth";


const ExpertDashboared = () => {
    const { Option } = Select;
    const dispatch = useDispatch();

    const [visibleModalUser, setVisibleModalUser] = useState(false);
    const [entreprises, setEntreprises] = useState();
    const [modeEntreprise , setModeEntreprise] = useState(false)
    const [entrepriseSelectd, setEntrepriseSelected] = useState();
    const [moyenForm] = Form.useForm();
    const [entrepriseForm] = Form.useForm();

    const [userForm] = Form.useForm();
    
    useEffect(() => {
        getEntrepriseClients();
    }, []);
    const formatEntreprisSelect = (data) => {
        const dataFormmated = data.map((el) => (
            <Option
                label={el.entreprise_client_name}
                value={el.entreprise_client_id_cryp}
            >
                {el.entreprise_client_name}
            </Option>
        ));
        return dataFormmated;
    };
    const getEntrepriseClients = async () => {
        const res = await expertService.getAllentrepriseclients();
        console.log(res);
        const resformatted = formatEntreprisSelect(res.data);
        setEntreprises(resformatted);
    };
    const handleModalAddUser = () => {
        setVisibleModalUser(true);
    };
    const handleModalAddRole= ()=>{
        setVisibleModalUser(true);
        setModeEntreprise(true)

    }
    const handleCancelModalUser = () => {
        setVisibleModalUser(false);
        userForm.resetFields()
        entrepriseForm.resetFields()
        getEntrepriseClients();
        setModeEntreprise(false)
    };
    const handleChoice = (choice) => {
        setEntrepriseSelected(choice);
    };
    const validChoice = () => {
        localStorage.setItem("entreprise", entrepriseSelectd);

        history.push("/");
    };
    const addUserData = async (values) => {
        const res = await expertService.registerUser(
            values,
            values.entrepriseUser
        );
        if (res.status===200){
            exportMessages.addSuccess('Utilisateur ajouté avec succées ')
          
        }
        else{
            exportMessages.errorMessage('un erreur est survenue')
        }
        handleCancelModalUser()
     

    };
    const logOut = () => {
        dispatch(logout());

        history.push("/signinexpert");
    };
    function confirmLogout() {
        Modal.confirm({
            title: "Attention",
            icon: <ExclamationCircleOutlined />,
            content: "Êtes-vous sûr de vouloir vous déconnecter ? ",
            okText: "Oui",
            onOk: () => logOut(),
            cancelText: "Non",
        });
    }
    const addEntreprise=async(entreprise)=>{
        const res  = await expertService.addentrepriseclient(entreprise)
        handleCancelModalUser()

    }
    return (
        <Card style={{ backgroundColor: "#dee0e9" }}
        extra={
            <Button
            className="mx-2"
            shape="round"
            type="primary"                                onClick={() => confirmLogout()}
            >Déconnexion</Button>
        }>
            <Row>
                <Col span={20} offset={2}>
                {  ( <Form
                        form={moyenForm}
                        onFinish={validChoice}
                        layout="vertical"
                    >
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Champ obligatoire !",
                                },
                            ]}
                            label="Séléctionner une entreprise"
                            name="entreprise"
                        >
                            <Select size="large" onChange={handleChoice}>
                                {entreprises}
                            </Select>
                        </Form.Item>

                        <Button
                            htmlType="submit"
                            onClick={moyenForm.submit}
                            type="primary"
                            className="mx-2 px-4 my-2"
                        >
                            Valider Le choix
                        </Button>
                    </Form>)}
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#dee0e9" }}>
                <Row>
                    <Col span={8} offset={4}>
                        <>
                            <Image
                                onClick={() => handleModalAddUser()}
                                preview={false}
                                src={userImage}
                                className="backGroundPic mx-5 my-5 clickabl"
                            />
                        </>
                    </Col>
                    <Col span={8} offset={0}>
                        <Image
                                                        onClick={() => handleModalAddRole()}

                            preview={false}
                            src={entrepriseImage}
                            className="backGroundPic mx-5 my-5 clickabl"
                        />
                    </Col>
                </Row>
            </Card>
            <Modal
            title={modeEntreprise ? "Créer une nouvelle entreprise" : "Créer un compte "}
                visible={visibleModalUser}
                onCancel={handleCancelModalUser}
                onOk={!modeEntreprise ?userForm.submit : entrepriseForm.submit}
            >
                {!modeEntreprise ?(<Form layout="vertical" form={userForm} onFinish={addUserData}>
                    <Row>
                        <Col span={12} className="px-2">
                            <Form.Item
                                label="Nom et Prénom"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Champ obligatoire avec maximum de caractère 100 !",
                                        max: 99,
                                    },{pattern :'^([a-zA-ZÀ-ÿ ]+)$' , message  : "Champ accepte que des lettres alphabétiques"}
                                ]}
                            >
                                <Input
                                    placeholder=" "
                                    size="large"
                                    maxLength={100}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                            <Form.Item
                                label="Nom de l'utilisateur"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: "Champ est obligatoire",
                                    },

                                    {
                                        max: 99,
                                        message: " maximum de 100 caractères !",
                                    },      
]}
                            >
                                <Input size="large" maxLength={100} />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Champ est obligatoire",
                                    },
                                    {
                                        type: "email",
                                        message: "Adresse mail Invalide",
                                    },
                                    {
                                        max: 99,
                                        message: " maximum de 100 caractères !",
                                    },
                                ]}
                            >
                                <Input size="large" maxLength={100} />
                            </Form.Item>
                        </Col>

                        <Col span={12} className="px-2">
                            <Form.Item
                                label="Mot de passe"
                                name="password"
                                help="Minimum 8 caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial ( @ $ ! % * ? & )"
                                rules={[
                                    {
                                        pattern:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/g,
                                        message: "Format Incorrecte",
                                    },
                                    {
                                        required: true,
                                        message: "Champ est obligatoire",
                                    },
                                    {
                                        max: 99,
                                        message: " maximum de 100 caractères !",
                                    },
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    type="password"
                                    iconRender={(visible) =>
                                        visible ? (
                                            <EyeTwoTone />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col offset={1} span={20}>
                            <Form.Item
                                name="entrepriseUser"
                                label="Séléctionner une entreprise"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Champ obligatoire avec maximum de caractère 100 !",
                                        max: 99,
                                    },
                                ]}
                            >
                                <Select>{entreprises}</Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>):<Form layout="vertical" form={entrepriseForm} onFinish={addEntreprise}>
                    <Form.Item label ="Nom de l'entreprise" name="entreprise_client_name"  rules={[
                                    {
                                        required: true,
                                        message:
                                            "Champ obligatoire avec maximum de caractère 100 !",
                                        max: 99,
                                    },
                                ]}>
                                    <Input size="large" type="text"/>
                        
                        </Form.Item></Form>}
            </Modal>
        </Card>
    );
};
export default ExpertDashboared;
