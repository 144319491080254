import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Tooltip,
  Tree,
} from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import Can from "../../../Can/Can";
import CAN from "../../../Can/Can";
import TreeDataColumn from "../../../helpers/tableColumns/treeData";
import AuthService from "../../../services/auth.service";
import ModuleService from "../../../services/module.service";
import PermissionService from "../../../services/permissionType.service";
import RolePermissionService from "../../../services/rolePermission.service";
import UserService from "../../../services/user.service";
import messages from "../../../UIcomponent/messages/messages";
import AppTable from "../../../UIcomponent/Table/AppTable";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";
import PasswordModal from "./PasswordModal";

const { TabPane } = Tabs;
function ListeUser() {
  const [dataTableRoles, setDataTableRoles] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [tabIndex, setTabIndex] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isModalLoading, setModalLoading] = useState(false);
  const [sleectDisabled , setSelectDisabld]=useState(false)
  const [isPasswordModal, setPasswordModal] = useState(false);
  const [userForm] = Form.useForm();
  const [roleForm] = Form.useForm();

  //Tree controlles
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [roleArray, setRoleArray] = useState();
  const [switchRole, setSwitchRole] = useState(false);
  const [recordData, setRecordData] = useState();
  const [arrayRole, setArrayRole] = useState([]);

  const [userRole, setUserRole] = useState([]);

  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);


  const UserColumn = [
    {
      title: "Nom et prenom",
      dataIndex: "name",
      width: "20%",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "nom utilisateur",
      dataIndex: "username",
      width: "20%",

      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      width: "20%",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },{
      title: "Rôle",
      dataIndex: "showRole",
      width: "20%",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            {
            ((Can("1_3")|| !!currentUser.type)|| (!!currentUser.type)) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                disabled={dataTableRoles.indexOf(record)===dataTableRoles.length-1 && !currentUser.type}

                onClick={() => updateMode(record, "user")}
                icon={<EditOutlined />}
              />
            )}
          </Tooltip>
          <Tooltip title="Supprimer">
            {(Can('1_4') || !!currentUser) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                disabled={dataTable.indexOf(record)===0 && !currentUser.type}
                onClick={() => confirmDelete(record, "user")}
                icon={<DeleteOutlined />}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];
  const RolesColumn = [
    {
      title: "Nom",
      dataIndex: "name_role",
      width: "75%",
      sorter: (a, b) => a.name_role.localeCompare(b.name_role),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
         {(Can('1_3') || !!currentUser)&&( <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={record.id_role===1 && !currentUser.type}

              onClick={() => updateMode(record, "role")}
              icon={<EditOutlined />}
            />
          </Tooltip>)}
          {(Can('1_4') || !!currentUser)&&(<Tooltip title="Supprimer">
            <Button
              className="mx-1"
              disabled={record.id_role===1 && !currentUser.type}

              type="dashed"
              shape="circle"
              onClick={() => confirmDelete(record, "role")}
              icon={<DeleteOutlined />}
            />
          </Tooltip>)}
        </div>
      ),
    },
  ];

  const confirmDelete = (record, module) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: record.id_role
        ? "Êtes-vous sûr de vouloir supprimer ce rôle et les permissions associées ? "
        : "Êtes-vous sûr de vouloir supprimer cette utilisateur ? ",
      onOk: () => deleteItem(record, module),
      okText: "Oui",
      cancelText: "Non",
    });
  };

  const clearData = () => {
    userForm.resetFields();
    roleForm.resetFields();
    setDataTable([]);

    setEditMode(false);
    setUpdateId(null);
    setModalAdd(false);
    setRecordData();
    setCheckedKeys([]);
    setArrayRole([]);
    setSelectedKeys([]);
  };

  const getAllusers = async () => {
    setLoading(true);
    const response = await UserService.getAll();

    if (response.status === 200 || 204) initTableData(response.data);
    else messages.errorMessage();
    setLoading(false);
  };
  const getUserRole = async (id) => {
    const response = await UserService.userRole(id);
    if (response.status === 200 || 204){
    if (response.data.general_role.id_role === 1 ){
      setSelectDisabld(true)
    }
     userForm.setFieldsValue({ role_id: response.data.general_role.id_role });
    }
    else messages.errorMessage();
  };

  const getAllRoles = async () => {
    setLoading(true);
    const response = await RolePermissionService.getAllRoles();
    if (response.status === 200 || 204) {
      initTableDataRoles(response.data);
    } else messages.errorMessage();
    setLoading(false);
  };

  const getAllModules = async () => {
    TreeDataColumn.treeData = [];
    const response = await ModuleService.getAll();
    const responsePermissionType = await PermissionService.getAll();
    if (response.status === 200) {
      for (const element of response.data) {
        const object = {
          title: element.name_module,
          key: element.id_module + "",
          children: [],
        };
        for (const res of responsePermissionType.data) {
          if (res.name === "Échéance") {
            if (element.name_module === "Dépense") {
              object.children.push({
                title: res.name,
                key: element.id_module + "_" + res.id,
              });
            }
          } else if (res.name === "Détail") {
            if (
              [
                "Dépense",
                "Centres de profit",
                "Catégories",
                "Paiement",
              ].includes(element.name_module)
            ) {
              object.children.push({
                title: res.name,
                key: element.id_module + "_" + res.id,
              });
            }
          } else
            object.children.push({
              title: res.name,
              key: element.id_module + "_" + res.id,
            });
        }
        TreeDataColumn.treeData.push(object);
      }
    } else {
      messages.errorMessage();
    }
  };
  const initTableData = (data) => {
    // for (const user of data) {
      
    // }
    setDataTable(data);
  };
  const initTableDataRoles = (data) => {
    setDataTableRoles(data);
  };
  const onClickAnnuler = () => {
    setModalAdd(false);
    setEditMode(false);
    setCheckedKeys([]);
    userForm.resetFields();
    roleForm.resetFields();
    setSwitchRole(!switchRole);
  };
  const formatteData = () => {
    const permission_array = [];
    for (const element of checkedKeys) {
      if (element.includes("_")) permission_array.push(element);
    }
    const modules = [];
    for (const element of permission_array) {
      const module_id = element.substring(0, element.indexOf("_"));
      if (!modules.includes(module_id)) modules.push(module_id);
    }
    const formattedArray = [];
    for (const module of modules) {
      const modulePermission = [];
      for (const permission of permission_array) {
        if (permission.includes(module + "_"))
          modulePermission.push(
            permission.substring(permission.indexOf("_") + 1, permission.length)
          );
      }
      formattedArray.push({
        module_id: module,
        permission_id: modulePermission,
        value: 1,
      });
    }
    return formattedArray;
  };

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };
  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  const callback = (key) => {
    setTabIndex(key);
  };

  const getPermissionByRoleId = async (id) => {
    const response = await RolePermissionService.getPermissionByRoleId(id);
    for (const element of response.data) {
      const module = element.module_permission.general_module.id_module;
      const permission = element.module_permission.permission_type.id;
      const module_permission = module + "_" + permission;
      checkedKeys.push(module_permission);
    }
    setModalAdd(true);
  };

  const updateMode = async (record, module) => {
    setModalLoading(true);
    setEditMode(true);
    if (module === "user") {
      userForm.setFieldsValue(record);
      getUserRole(record.id);
      setUpdateId(record.id);
      setModalAdd(true);
    } else {
      roleForm.setFieldsValue({ name: record.name_role });
      setUpdateId(record.id_role);
      getPermissionByRoleId(record.id_role);
    }
    setRecordData(record);
    setModalLoading(false);
  };

  const addUserData = async (values) => {
    setModalLoading(true);
    const response = editMode
      ? await UserService.updateUser(updateId, values, values.role_id)
      : await AuthService.register(values);
    if (response.status === 200 || 204) {
      if (editMode) {
        messages.updateSuccess("Mise à jour du avec succès ! ");
        getAllusers();
        setRecordData();
      } else {
        messages.addSuccess("Utilisateur ajouté avec succès ! ");
        getAllusers();
      }
    } else messages.errorMessage();
    setModalLoading(false);
    clearData();
  };

  const addRoleData = async (values) => {
    setModalLoading(true);
    const roleData = formatteData();
    if (editMode) {
      const response = await RolePermissionService.updateRolePermission(
        roleData,
        values.name,
        updateId
      );
      if (response.status === 200 || 204) {
        messages.updateSuccess("Mise à jour du avec succès ! ");
      } else messages.errorMessage();
      setSelectedKeys([]);
    } else {
      const response = await RolePermissionService.addRolePermission(
        roleData,
        values.name
      );
      if (response.status === 200 || 204)
        messages.updateSuccess("Mise à jour du avec succès ! ");
      else messages.errorMessage();
      setSelectedKeys([]);
    }
    getAllRoles();
    getAllusers();
    clearData();
    setModalLoading(false);
  };
  const deleteItem = async (item, module) => {
    if (module === "user") {
      const response = await UserService.removeUser(item.id);
      if (response.status === 204 || 200)
        messages.addSuccess("Suppression avec succès ! ");
      else messages.errorMessage();
    } else {
      const response = await RolePermissionService.removeRole(item.id_role);
      if (response.status === 204 || 200)
        messages.addSuccess("Suppression avec succès ! ");
      else messages.errorMessage();
    }
    getAllusers();
    getAllRoles();
  };
 
  useEffect(() => {
    getAllusers();
    getAllRoles();
    getAllModules();
    return () => {
      clearData();
    };
  }, []);

  const listeRole =
    dataTableRoles &&
    dataTableRoles.map((role) => (
      <Option key={role.id_role} value={role.id_role}>
        {role.name_role}
      </Option>
    ));

  return (
    <Spin spinning={isLoading} size="large">
      {(Can("1_1") || !!currentUser.type)  ? (
        <Card
          headStyle={{ backgroundColor: "#dee0e9" }}
          title="Gestion des utlisateurs et des rôles"
          style={{ width: "100%" }}
          extra={
        ( Can('1_2')||!!currentUser.type )&&(   <Button
              className="mx-2"
              shape="round"
              type="primary"
              onClick={() => {
                setModalAdd(true);
              }}
            >
              {tabIndex + "" === 1 + "" && "Ajouter un utilisateur "}
              {tabIndex + "" === 2 + "" && "Ajouter un Rôle "}
            </Button>)
          }
        >
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Gestion des utilisateurs" key="1">
              <AppTable data={dataTable} columns={UserColumn} />
            </TabPane>
            <TabPane tab="Gestion des rôles" key="2">
              <AppTable data={dataTableRoles} columns={RolesColumn} />
            </TabPane>
          </Tabs>
        </Card>
      ) : (
        <NotAuthorized />
      )}

      <Modal
        title={
          tabIndex + "" === 1 + ""
            ? editMode
              ? "Modifier L'utilisateur"
              : "Ajouter un utilisateur"
            : editMode
            ? "Modifier un rôle"
            : "Ajouter un rôle"
        }
        centered
        okText="Enregistrer"
        cancelText="Annuler"
        confirmLoading={isModalLoading}
        getContainer={false}
        visible={modalAdd}
        onOk={tabIndex + "" === 1 + "" ? userForm.submit : roleForm.submit}
        onCancel={() => onClickAnnuler()}
        width={900}
        footer={[
          <Row>
            <Col span={6}>
              {tabIndex + "" === 1 + "" && editMode && (
                <Button
                  type="dashed"
                  className="mr-5 pr-5"
                  danger
                  onClick={() => setPasswordModal(true)}
                >
                  Modifier le mot de passe
                </Button>
              )}
            </Col>
            <Col span={18}>
              <Button onClick={() => onClickAnnuler()}> Annuler</Button>
              <Button
                type="primary"
                onClick={
                  tabIndex + "" === 1 + "" ? userForm.submit : roleForm.submit
                }
              >
                Enregistrer
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <PasswordModal
          isVisible={isPasswordModal}
          userId={updateId}
          closingModal={() => setPasswordModal(false)}
        ></PasswordModal>

        {tabIndex + "" === 1 + "" ? (
          <Form layout="vertical" form={userForm} onFinish={addUserData}>
            <Row>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Nom et Prénom"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                    {pattern :'^([a-zA-ZÀ-ÿ ]+)$' , message  : "Champ accepte que des lettres alphabétiques"}
                  ]}
                >
                  <Input placeholder=" " size="large" maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Nom de l'utilisateur"
                  name="username"
                  rules={[
                    { required: true, message: "Champ est obligatoire" },
                    { max: 99, message: " maximum de 100 caractères !" },
                  ]}
                >
                  <Input size="large" maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Champ est obligatoire" },
                    { type: "email", message: "Adresse mail Invalide" },
                    { max: 99, message: " maximum de 100 caractères !" },
                  ]}
                >
                  <Input size="large" maxLength={100} />
                </Form.Item>
              </Col>
              {!editMode && (
                <Col span={12} className="px-2">
                  <Form.Item
                    label="Mot de passe"
                    name="password"
                    help="Minimum 8 caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial ( @ $ ! % * ? & )"
                    rules={[
                      {
                        pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/g,
                        message: "Format Incorrecte",
                      },
                      { required: true, message: "Champ est obligatoire" },
                      { max: 99, message: " maximum de 100 caractères !" },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      type="password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={editMode ? 12 : 24} className="px-2">
                <Form.Item
                  label="Liste des rôles"
                  name="role_id"
                  rules={[{ required: true, message: "Champ obligatoire !" }]}
                >
                  <Select disabled={sleectDisabled} size="large">{listeRole}</Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form layout="vertical" form={roleForm} onFinish={addRoleData}>
            <Row>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Nom du rôle"
                  name="name"
                  rules={[
                    { required: true, message: "Champ est obligatoire" },
                    { max: 99, message: "Maximum de 100 caractères !" },
                  ]}
                >
                  <Input
                    size="large"
                    maxLength={100}
                    placeholder="Exemple : Gestionnaire de paie"
                  />
                </Form.Item>
              </Col>
              <Col span={24} className="px-2">
                <Form.Item title="Liste des modules et permission">
                  <Tree
                    checkable
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    onSelect={onSelect}
                    defaultExpandAll={false}
                    autoExpandParent={false}
                    selectedKeys={selectedKeys}
                    treeData={TreeDataColumn.treeData}
                  />{" "}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </Spin>
  );
}

export default ListeUser;
