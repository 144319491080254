import {
    RETRIEVE_ECHEANCE,
    } from "../actions/type";
    
    const initialState = [];
    
    function echeanceReducer(echeance = initialState, action) {
      const { type, payload } = action;
    
      switch (type) {
           
        case RETRIEVE_ECHEANCE:
          return payload;

        default:
          return echeance;
      }
    };
    
    export default echeanceReducer;