import {
    RETRIEVE_MODULES,
  
    } from "../actions/type";
    
    const initialState = [];
    
    function moduleReducer(module = initialState, action) {
      const { type, payload } = action;
    
      switch (type) {
        case RETRIEVE_MODULES:
          return payload;    
        default:
          return module;
      }
    };
    
    export default moduleReducer;