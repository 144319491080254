import authHeader from "./auth-header";
import axios from "axios";
import { environment } from "../env/config.env";

const API_URL = environment.api;
const EntrepriseId = (localStorage.getItem('entreprise'))

const addBalance = async ( balance , exercice ) => {
    return await axios.post( API_URL + "balance/upload?entreprise="+EntrepriseId+"&exercice="+exercice  , balance, {
        headers: {"Content-Type": "multipart/form-data"},
      },{ headers: authHeader() }
    );
};

const getBalance = async ( exercice ) => {
    const ex = exercice + "";
    return await axios.get( API_URL + "balance/byExercice?entreprise="+EntrepriseId+"&exercice_id="+ex,{ headers: authHeader() });
};
const getOldExerciceBilan = async ( exercice ) => {
    const ex = exercice + "";
    console.log(ex);
    return await axios.get( API_URL + "bilan/fluxByExercice?entreprise="+EntrepriseId+"&exercice_id="+ex,{ headers: authHeader() });
};
const checkIfTablesExist = async (exercice) => {
    const ex = exercice + "";
    return await axios.get( API_URL + "balance/checkData?entreprise="+EntrepriseId+"&exercice_id="+ex,{ headers: authHeader() });
}

const addBilan = async (data , exercice) =>{
    const ex = exercice + "";
    return await axios.post( API_URL + "bilan/addBilan?entreprise="+EntrepriseId+"&exercice_id="+ex , data , { headers: authHeader() });
}

const addNewNote = async (data , exercice) => {
    const ex = exercice + "";
    return await axios.post( API_URL + "bilan/addNote?entreprise="+EntrepriseId+"&exercice_id="+ex , data , { headers: authHeader() });
  
}

const addNoteToRapport = async (data , exercice) => {
    const ex = exercice + "";
    return await axios.post( API_URL + "bilan/addNoteToRapport?entreprise="+EntrepriseId+"&exercice_id="+ex , data , { headers: authHeader() });
  
}

const updateCompteRubrique = async (id , rubrique,classe) => {
    const compte = {
        balance_id : id,
        classComptable : classe,
        rubrique : rubrique,
    }
    return await axios.put( API_URL + "balance/update" , compte , { headers: authHeader() });
}
const updateNote = ( id, note ) => {
    return axios.get( API_URL + "bilan/note/"+id+"?note="+note , { headers: authHeader() });
}

const updateHelperNote = (id , data) => {
    return axios.put(API_URL + "bilan/helper/"+id ,data, { headers: authHeader() })
} 

const updateRubData = async (tree) => {
    return await axios.put( API_URL + "bilan/updateRubs", tree , { headers: authHeader() });
}
const getRapportHelpers = () => {
    return axios.get( API_URL + "bilan/helpers?entreprise="+EntrepriseId , { headers: authHeader() }); 
}

const deleteHelper = (id) => {
    return axios.delete( API_URL + "bilan/helper/"+id , { headers: authHeader() }); 
}

const getRubriques = () => {
    return axios.get( API_URL + "comptes/rubriques?entreprise="+EntrepriseId , { headers: authHeader() }); 
}

const getRubriqueComptes = () => {
    return axios.get( API_URL + "comptes/compte_rubriques?entreprise="+EntrepriseId , { headers: authHeader() }); 
}

const addRubrique = async ( data ) => {
    return await axios.post( API_URL + "comptes/rubrique?entreprise="+EntrepriseId, data , { headers: authHeader() });
}

const addCompteRubrique = async ( data ) => {
    return await axios.post( API_URL + "comptes/compte_rubrique?entreprise="+EntrepriseId, data , { headers: authHeader() });
}

const updateRubrique = async ( id, data ) => {
    return await axios.put( API_URL + `comptes/rubrique/${id}?entreprise=${EntrepriseId}`, data , { headers: authHeader() });
}
const updateCompteRub = async ( id, data ) => {
    console.log( id , data)
    return await axios.put( API_URL + `comptes/compte_rubrique/${id}?entreprise=${EntrepriseId}`, data , { headers: authHeader() });
}

const deleteRubrique = async( id )=> {
    return await axios.delete( API_URL + `comptes/rubrique/${id}?entreprise=${EntrepriseId}`,{ headers: authHeader() });
}
const deleteCompteRub = async( id )=> {
    return await axios.delete( API_URL + `comptes/compte_rubrique/${id}?entreprise=${EntrepriseId}`, { headers: authHeader() });
}


const deleteBilanData = async ( exercice_id ) => {
    return await axios.delete( API_URL + `balance/delete?entreprise=${EntrepriseId}&exercice=${exercice_id}`, { headers: authHeader() });
}

const exportMethodes = {
    addBalance,
    getBalance,
    addBilan,
    checkIfTablesExist,
    updateCompteRubrique,
    updateNote,
    updateRubData,
    addNewNote,
    getRapportHelpers,
    updateHelperNote,
    deleteHelper,
    getOldExerciceBilan,
    addNoteToRapport,

    
    getRubriqueComptes,
    getRubriques,
    addRubrique,
    addCompteRubrique,
    updateRubrique,
    updateCompteRub,
    deleteRubrique,
    deleteCompteRub,

    deleteBilanData
};
export default exportMethodes;