import { CREATE_CATEGORIE, RETRIEVE_CATEGORIES } from "../actions/type";

const initialState = [];

function categorieReducer(categories = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_CATEGORIE:
      return [...categories, payload];

    case RETRIEVE_CATEGORIES:
      return payload;
    default:
      return categories;
  }
}

export default categorieReducer;
