import {
  RETRIEVE_CATEGORIES,
  CREATE_CATEGORIE,
  UPDATE_CATEGORIE,
} from "./type";
import categorieService from "../services/categorie.service";

export const getAllCategories = () => async (dispatch) => {
  try {
    const res = await categorieService.getCategories();
    dispatch({
      type: RETRIEVE_CATEGORIES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addCategorie = (data) => async (dispatch) => {
  try {
    const res = await categorieService.addCategorie(data);
    dispatch({
      type: CREATE_CATEGORIE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateCategorie = (id, data) => async (dispatch) => {
  try {
    const res = await categorieService.updateCategorie(id, data);

    dispatch({
      type: UPDATE_CATEGORIE,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteCategorie = (id) => async (dispatch) => {
  try {
    const res = await categorieService.deleteCategorie(id);

    dispatch({
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
