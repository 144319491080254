import {
    RETRIEVE_PERMISSION,
  
    } from "../actions/type";
    
    const initialState = [];
    
    function permissionReducer(permission = initialState, action) {
      const { type, payload } = action;
    
      switch (type) {
        case RETRIEVE_PERMISSION:
          return payload;    
        default:
          return permission;
      }
    };
    
    export default permissionReducer;