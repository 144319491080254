import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  LOGIN_FAIL,
  LOGIN_FETCHING,
  LOGIN_SUCCESS,
} from "../actions/type";

const initialState = { message: "" };

function message(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_FETCHING:
      return { message: "waiting" };

    case LOGIN_SUCCESS:
      return { message: payload.message };
    case LOGIN_FAIL:
      return { message: payload.message };

    case CLEAR_MESSAGE:
      return { message: "" };

    default:
      return state;
  }
}
export default message;
