import { Tabs , Card } from 'antd';
import MoyenPaiement from './MoyenPaiement';
import MethodePaiement from './MethodePaiement';


const { TabPane } = Tabs;

function Paiement() {

    return (
        <Card
            headStyle={{backgroundColor: "#dee0e9"}}
            title="Méthodes et Moyens de paiement"
            style={{ width: "100%" }}
        >
        <Tabs defaultActiveKey="1">
            <TabPane tab="Méthodes" key="1">
                <MethodePaiement></MethodePaiement>
            </TabPane>
            <TabPane tab="Moyens" key="2">
                <MoyenPaiement></MoyenPaiement>
            </TabPane>
        </Tabs>
        </Card>

    );
}
export default Paiement;