import axios from "axios";
import authHeader from "./auth-header";

import { environment } from "../env/config.env";
const API_URL = environment.api;
let EntrepriseId = (localStorage.getItem('entreprise'))

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getAll = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "users/"+EntrepriseId, { headers: authHeader() });
};
const removeUser = (id) => {
  return axios.delete(API_URL + "user/" + id, { headers: authHeader() });
};
const getUser = (id) => {
  return axios.get(API_URL + "user/" + id, { headers: authHeader() });
};
const updateUser = (id, data, role) => {
  return axios.put(API_URL + "user/" + id + "?role_id=" + role, data, {
    headers: authHeader(),
  });
};
const userRole = (id) => {
  return axios.get(API_URL + "userPermission/" + id, { headers: authHeader() });
};
const updatePassword = (id, data) => {
  return axios.put(API_URL + "userPassword/" + id, data, {
    headers: authHeader(),
  });
};

const exportObj = {
  getPublicContent,
  getUserBoard,
  getAll,
  removeUser,
  getUser,
  updateUser,
  userRole,
  updatePassword,
};
export default exportObj;
