import {
  CloseOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Modal,
  Row,
  Space,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import currencys from "../../helpers/currency";
import Uploader from "../../helpers/Uploader";
import DepenseService from "../../services/depense.service";
import fileService from "../../services/file.service";
import attachment from "./attachment.png";
import exportMessages from "../../UIcomponent/messages/messages";
import "./Fichedepnese.css";
import EcheanceTable from "./Tables/EcheanceTable";
import PaiementTable from "./Tables/PaiementTable";
import { Tag } from "antd";

function FicheDepense() {
  const history = useHistory();
  const location = useLocation();
  const [depense, setDepense] = useState({});
  const [selectedFiles, setselectedFiles] = useState([]);

  const goback = () => {
    history.push(
      location.state.from === "depense" ? "/Depensev2" : "/Paiement"
    );
  };
  const fileChange = (vakue) => {
    let arr = vakue.fileList.map((el) => el.originFileObj);
    setselectedFiles(arr);

    // setselectedFiles(vakue.target.files);
  };
  const downloadFile = async (file) => {
    await fileService.getFile(file.file_id, "1").then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
    });
  };
  const deleteFile = async (file) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce fichier ? ",
      onOk: async () => {
        let res = await fileService.deleteFile(file, "1");
        location.state.record = {
          ...depense,
          file_id: depense.file_id.filter((el) => el !== file),
        };
        setDepense({
          ...depense,
          file_id: depense.file_id.splice(depense.file_id.indexOf(file), 1),
        });
        setselectedFiles();
        history.push("/ficheDepense", { record: depense });
      },
      okText: "Oui",
      cancelText: "Non",
    });
  };
  const afficher = () => {
    let categorie = location.state.record.categorie;
    let element = categorie;
    const arr = [];
    while (element?.categorieParent != null) {
      arr.push(element.category_id);
      element = { ...element.categorieParent };
    }
    arr.push(element?.category_id);
    arr.reverse();
  };
  const setDepenseData = () => {
    afficher();
    setDepense(location.state.record);
  };

  const formatteDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };
  const getTaxeType = (type) => {
    if (type === "Tva" || type === "Taxe") return "%";
  };
  const formatteFrequence = (frequence) => {
    if (frequence?.type === "année")
      return " chaque " + frequence.periode + " ans ";
    else return " chaque " + frequence.periode + " " + frequence.type;
  };
  const upload = async () => {
    const res = await fileService.upload(
      selectedFiles,
      depense.depense_id,
      "1"
    );
    if (res.every((el) => el.status === 201)) {
      setselectedFiles();
      let newFilesData = res.map((el) => el.data);
      location.state.record.file_id = [
        ...location.state.record.file_id,
        ...newFilesData,
      ];
      setselectedFiles();
      setDepense({
        ...depense,
        file_id: location.state.record.file_id,
      });
      exportMessages.addSuccess("Fichier est bien ajouté");
      history.push("/ficheDepense", { record: location.state.record });
      window.location.reload();
    } else {
      exportMessages.errorMessage("une erreur est survenue");
    }
  };
  const getCurrency = (devise) => {
    return currencys.find((currency) => currency.id === devise).code;
  };
  useEffect(() => {
    setDepenseData();
    return () => {};
  }, [depense]);

  const openPaiementPage = () => {
    const record = location.state.record;
    history.push("/AddPaiement", { record });
  };
  const getColorByStatus = (status) => {
    switch (status) {
      case "PAYÉ":
        return "#52c41a";
      case "EN COURS":
        return "#ff7129";
      default:
        return "#ff4d4f";
    }
  };
  return (
    <>
      <Card
        headStyle={{ backgroundColor: "#dee0e9" }}
        title={
          <Row>
            <Col span={10}>
              <span onClick={() => goback()} className="goback mb-2">
                Retour
              </span>
            </Col>
            <Col span={10}>Détails de Charge</Col>
            {location.state.record.statut !== "PAYÉ" && (
              <Col span={4}>
                <Button
                  type="primary"
                  className="ml-2"
                  shape="round"
                  onClick={() => openPaiementPage()}
                >
                  Ajouter paiement
                </Button>
              </Col>
            )}
          </Row>
        }
      >
        <Row>
          <Col span={24} className="mx-2">
            <Descriptions title="Informations general"></Descriptions>
            <Row>
              <Col span={16}>
                <span>
                  <b>Titre :</b> {location.state.record.titre} <br />
                  <b>Description :</b> {location.state.record.description}{" "}
                  <br />
                  <b>Date de creation : </b>
                  {location.state.record.showCreationDate} <br />
                  {location.state.record.interne ? (
                    <b> Depense Interne </b>
                  ) : (
                    <>
                      <b>Fournisseur : </b>{" "}
                      {location.state.record.showFournisseur}
                    </>
                  )}
                  <br />
                  <b>Catégorie : </b>
                  {location.state.record?.categorie?.categorie_nom}
                  <br />
                  <b>Centre de profit : </b>
                  {location.state.record.profitCenter?.profitCenter_nom}
                  <br />
                </span>
              </Col>
              <Col span={8}>
                <span>
                  <b>
                    Etat :{" "}
                    <Tag color={getColorByStatus(location.state.record.statut)}>
                      {location.state.record.statut}
                    </Tag>
                  </b>{" "}
                  <br />
                  <b>Montant TTC : {location.state.record.showMontant}</b>{" "}
                  <br />
                  <b>Montant Hors Taxe : </b>
                  {location.state.record.montant.total_ht}{" "}
                  {getCurrency(location.state.record.devise)} <br />
                  <b>Montant des taxes : </b>{" "}
                  {location.state.record.montant.total_ttc -
                    location.state.record.montant.total_ht}{" "}
                  {getCurrency(location.state.record.devise)} <br />
                  <b>Taxe Appliquée: </b>
                  {location.state.record.montant.taxes.map((taxe) => {
                    return (
                      <li>
                        - {taxe.nom} - {taxe.valeur} {getTaxeType(taxe.type)}
                      </li>
                    );
                  })}
                  <li></li>
                </span>
              </Col>
            </Row>
            <Divider />

            {location.state.record.ligne_depenses.length > 0 && (
              <>
                <Descriptions title="Details"></Descriptions>
                {location.state.record.ligne_depenses.map((ligne) => {
                  return (
                    <span>
                      <Row>
                        <Col span={16}>
                          <b>Titre : </b> {ligne.titre} <br />
                          <b>Taxes Appliqué : </b>
                          {ligne.taxes.map((taxe) => {
                            return (
                              <span>
                                {taxe.nom} - {taxe.valeur}
                                {getTaxeType(taxe.type)} , {"  "}
                              </span>
                            );
                          })}
                          <Divider />
                        </Col>
                        <Col span={8}>
                          Montant TTC : {ligne.total_ttc} <br />
                          Montant Hors Taxe : {ligne.total_ht} <br />
                        </Col>
                      </Row>
                    </span>
                  );
                })}
              </>
            )}

            <br />
            <Descriptions
              title={
                location.state.record.echeances.length > 1
                  ? "Echeances"
                  : "Paiement Prévu "
              }
            ></Descriptions>
            <span>
              {location.state.record.echeances.length > 1 && (
                <>
                  <b>Nombre d'echeance : </b>
                  {location.state.record.echeances.length} <br />
                </>
              )}
            </span>
            <br />
            <EcheanceTable echeances={location.state.record.echeances} />

            <br />
            {!!location.state.record.paiements.length && (
              <>
                <Descriptions title="Paiements"></Descriptions>
                <span>
                  {location.state.record.paiements.length > 1 && (
                    <>
                      <b>Nombre de paiement : </b>
                      {location.state.record.paiements.length} <br />
                    </>
                  )}
                </span>
                <br />
                <PaiementTable
                  paiements={location.state.record.paiements}
                  depense={location.state.record}
                  showUpdate={true}
                ></PaiementTable>
              </>
            )}

            {location.state.record.depense_occurence && (
              <>
                <Descriptions title="Recurrence"></Descriptions>
                <span>
                  <Row>
                    {location.state.record.depense_occurence?.occurence && (
                      <Col span={16}>
                        <b> Nombre de recurrence : </b>
                        {location.state.record.depense_occurence?.occurence}
                        <br />
                        <b> Ocurrence Actuel : </b>
                        {location.state.record.depense_occurence?.current}{" "}
                        <br />
                      </Col>
                    )}

                    <Col>
                      Depense recurrente
                      <b>
                        {location.state.record.depense_occurence?.current &&
                          formatteFrequence(
                            location.state.record.depense_occurence?.frequence
                          )}
                      </b>
                    </Col>
                  </Row>
                </span>
                <br />
                <table style={{ width: "50%" }} className="table text-center">
                  <thead>
                    <tr>
                      <th> # </th>
                      <th> Date prevue du paiement </th>
                    </tr>
                  </thead>
                  <tbody>
                    {location.state.record.depense_occurence?.occurence_dates &&
                      location.state.record.depense_occurence?.occurence_dates.map(
                        (occurence, index) => {
                          return (
                            <tr>
                              <td> {index + 1} </td>
                              <td> {formatteDate(occurence.date_prevu)} </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <>
              {location.state.record.file_id.map((file) => (
                <Alert
                  key={file.file_id}
                  message={file.name}
                  type="success"
                  className="my-2"
                  action={
                    <Space direction="horizonal">
                      <Tooltip title="Supprimer">
                        <Button
                          type="danger"
                          shape="circle"
                          icon={<CloseOutlined />}
                          onClick={() => {
                            deleteFile(file);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Telecharger">
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<CloudDownloadOutlined />}
                          onClick={() => downloadFile(file)}
                        />
                      </Tooltip>
                    </Space>
                  }
                />
              ))}
            </>
          </Col>
          <Col span={24}>
            <Uploader text="Charge" fileChange={fileChange} />
            <Button
              type="primary"
              className="ml-2 mt-2 mb-5"
              shape="round"
              onClick={async () => {
                upload();
              }}
            >
              Ajouter fichier
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default FicheDepense;
