import axios from "axios";
import authHeader from "./auth-header";


import { environment } from "../env/config.env";
const API_URL = environment.api;

const addRolePermission = (values, name) => {
  return axios.post(API_URL + "rolePermission/" +name +'/'+ localStorage.getItem('entreprise'), values, { headers: authHeader()});
};

const updateRolePermission = (values,name, id) => {
  return axios.put(API_URL + "rolePermission/" + name +"?id=" + id, values, { headers: authHeader()});
};
const getAllRolePermission = () => {
  return axios.get(API_URL + "rolePermission/",{ headers: authHeader() });
};

const getPermissionByRoleId = (id) => {
  return axios.get(API_URL + "rolePermission/"+ id,{ headers: authHeader() });
};
const getAllRoles = () =>{
 let  EntrepriseId = (localStorage.getItem('entreprise'))

  return axios.get(API_URL + "generalRole/"+EntrepriseId , {headers: authHeader()});
}
const removeRole =( id) => {
  return axios.delete(API_URL + "generalRole/" +id , { headers: authHeader() });
 };


const exportObj = {
  addRolePermission,
    getAllRolePermission,
    getAllRoles,
    removeRole,
    getPermissionByRoleId,
    updateRolePermission
};
export default exportObj;