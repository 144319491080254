import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import createDebounce from "redux-debounced";

import rootReducer from "./rootReducer";
const middleware = [thunk, createDebounce()];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
export default store;
