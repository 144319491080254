import {
    CREATE_ROLES,
    RETRIEVE_ROLES,
    UPDATE_ROLES,
    DELETE_ROLES,
    } from "../actions/type";
    
    const initialState = [];
    
    function rolesReducer(roles = initialState, action) {
      const { type, payload } = action;
      switch (type) {
        case CREATE_ROLES:
          return [...roles, payload];
          
          case RETRIEVE_ROLES:
            return payload;
        default:
          return roles;
      }
    };
    
    export default rolesReducer;