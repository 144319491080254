import { Alert, Button, Result } from "antd";
import React from "react";
import { useHistory } from "react-router";

const NotFound = () => {
  const history = useHistory();
  const navigate = () => {
    history.push("/");
  };
  return (
    <>
      <Alert message="Page Non Trouvée" type="error" showIcon />{" "}
      <Result
        status="404"
        title="404"
        extra={
          <Button type="primary" onClick={() => navigate()}>
            {" "}
            Aller au page d'accueil
          </Button>
        }
      />
    </>
  );
};
export default NotFound;
