import React, { useState } from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
const { Dragger } = Upload;

const Uploader = ({ fileChange , text }) => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const props = {
    name: "file",
    multiple: true,

    onRemove(file) {},
    beforeUpload(file) {
      return false;
    },
  };
  return (
    <Dragger
      {...props}
      onChange={fileChange}
      multiple={true}
      defaultFileList={[]}
      style={{ maxHeight: "150px" }}
    >
      <p className="ant-Dragger-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-Dragger-text">
        Déposer des fichiers de {text} ici ou cliquer pour sélectionner 
      </p>
      <p className="ant-Dragger-hint">
        Vous pouvez télécharger plusieurs fichiers à la fois
      </p>
    </Dragger>
  );
};
export default Uploader;
