import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import paiementService from "../../../services/paiement.service";
import messageAlert from "../../../UIcomponent/messages/messages";
import Can from "../../../Can/Can";
import { useSelector } from "react-redux";



const PaiementTable = ({ paiements, depense , showUpdate }) => {
  const { user: currentUser } = useSelector((state) => state.auth);

  const history = useHistory();

  const formatteDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const goToUpdatePaiement = (index) => {
    const record = depense;
    history.push("/updatepaiement", { record, paiement:index });
  };
  const deletPaiement = (id) => {
    Modal.confirm({
      type: "info",
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Etes vous sur de vouloir supprimer ce paiement ? ",
      onOk: () => confimDelete(id),
      okText: "Oui",
      cancelText: "Non",
    });
  }
  const confimDelete = async (id) => {
    const depenseId = depense.depense_id;
    let resopnse = await paiementService.deletePaiement(depenseId, id);
    if (resopnse.status === 200) {
      messageAlert.addSuccess("Suppression du paiement avec succées");
      goback();
    } else {
      messageAlert.errorMessage("une erreur est survenue");
    }
  }
  const goback = () => {
    history.push("/Depensev2");
  };
  return (
    <>
      <table style={{ width: "99%" }} className="table">
        <thead>
          <tr>
            <th> Libelle </th>
            <th> Date du paiement </th>
            <th> Date d'encaissement </th>
            <th> Montant payé </th>
            {showUpdate && <th> Action </th>}
          </tr>
        </thead>
        <tbody>
          {paiements &&
            paiements.map((paiement,i) => {
              return (
                <tr >
                  <td className="pt-3"> {paiement.libelle} </td>
                  <td className="pt-3"> {formatteDate(paiement.date_paiement)} </td>
                  <td className="pt-3"> {formatteDate(paiement.date_ecaissement)} </td>
                  <td className="pt-3"> {paiement.montant} </td>
                  { showUpdate &&( Can("16_3")||!!currentUser) &&
                    <td>
                      <Tooltip title="Modifier paiement">
                        <Button
                          onClick={() => {
                            goToUpdatePaiement(i);
                          }}
                          className="mx-1 my-0 py-0"
                          type="dashed"
                          shape="circle"
                          icon={<EditOutlined />}
                        />
                      </Tooltip>
                      <Tooltip title="Supprimer paiement">
                        <Button
                          onClick={() => { deletPaiement(paiement.paiement_id); }}
                          className="mx-1 my-0 py-0"
                          type="dashed"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </td>
                    
                  }
                </tr>
              );
            })}
        </tbody>
      </table>
      <br />
    </>
  );
};
export default PaiementTable;
