import authHeader from "./auth-header";

import axios from "axios";
import { environment } from "../env/config.env";
const API_URL = environment.api;
let   EntrepriseId = (localStorage.getItem('entreprise'))

const addModule = (module) => {
  return axios.post(API_URL + "modulename", module, { headers: authHeader() });
};
const getallModuleNamesSide = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))

  return axios.get(API_URL + "modulenamesSide/" + EntrepriseId, { headers: authHeader() });
};
const getallModuleNames = () => {
  
  EntrepriseId = (localStorage.getItem('entreprise'))

  return axios.get(API_URL + "modulenames/"+EntrepriseId, { headers: authHeader() });
};
const getallModuleNamesTop = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
console.table(axios.get(API_URL + "modulenamesTop/" + EntrepriseId, { headers: authHeader() }))
  return axios.get(API_URL + "modulenamesTop/" + EntrepriseId, { headers: authHeader() });
};

const updateModule = (id, module) => {
  return axios.put(API_URL + "modulename/" + id, module, { headers: authHeader() });
};
const deleteModule = (id) => {
  return axios.delete(API_URL + "module/" + id, { headers: authHeader() });
};


const exportMethodes = {
  addModule,
  getallModuleNamesSide,
  updateModule,
  getallModuleNames,
  deleteModule,
  getallModuleNamesTop
  

};
export default exportMethodes;
