
import { BookOutlined, CloseOutlined, CloudDownloadOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, HighlightOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Card, Empty, Form, Input, Modal, Row, Spin, Table, Tabs, Tooltip } from "antd";
import JoditEditor from "jodit-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useHistory } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { environment } from "../../env/config.env";
import titles from "../../helpers/bilanTitles.js";
import BilanService from "../../services/bilan.service";
import ExerciceService from "../../services/exercice.service";
import fileService from "../../services/file.service";
import exportMessages from "../../UIcomponent/messages/messages";
import AppTable from "../../UIcomponent/Table/AppTable";
import ExportTables from "./ExportTables";



const API_URL = environment.api;

const Balances = () => {
    const history = useHistory();
    const editor = useRef(null)

    const [balanceForm] = Form.useForm();
    const [sectionForm] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [exercices, setExercices] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [helpersData, setHelpersData] = useState([]);

    const [actifTable, setActifTable] = useState([]);
    const [passifTable, setPassifTable] = useState([]);
    const [resultTable, setResultTables] = useState([]);
    const [fluxTables,setFluxTables] = useState([]);
    const [treeData , setTreeData] = useState([]);
    const [exercice1 , setExercice1] = useState("Exercice 1");
    const [exercice2 , setExercice2] = useState("Exercice 2");
    const [visible , setVisible] = useState(false);
	const [content, setContent] = useState('');
    const [isNativeNote , setNativeNote] = useState(true);
    const [updateData , setUpdateData] = useState({});  
    const [noteModal,setNoteModal] = useState(false);
    const [helpersModal,setHelpersModal] = useState(false);

	const config = {
		readonly: false,
		placeholder: ""
	}

    const visualtionConfig = {
		readonly: true,
		placeholder: ""
	}

    useEffect(() => {
        getExercices();
        getRapportHelpers();
        return () => {
            clearStates();
        };
    }, []);

    const getExercices = async () => {
        setLoading(true);
        const response = await ExerciceService.getDoneExercices();
        if (response.status === 200 || 204) initExercices(response.data);
        else exportMessages.errorMessage();
        setLoading(false);

    };

    const initExercices = (data) => {
        for (const exercice of data) {
            exercice.showDateDebut = moment(exercice.date_debut).format("YYYY-MM-DD");
            exercice.showDateFin = moment(exercice.date_fin).format("YYYY-MM-DD");
        }
        setExercices(data);
    }

    const getRapportHelpers = async () => {
        setLoading(true);
        const response = await BilanService.getRapportHelpers();
        if (response.status === 200 || 204) initTableData(response.data);
        else exportMessages.errorMessage();
        setLoading(false);
    };

    const initTableData = (data) => {
        setHelpersData(data);
    };

    const showBalance = async ( record ) => {
        balanceForm.setFieldsValue({exercice : record.exercice_id});
        await getBalanceData();
    }
    const showAlldata = async ( record ) => {
        await showBalance(record)
        setUpdateData(null);
        fullRapportEdit()
    }

    const getBalanceData = async () => {
        setLoading(true);
        const balance = balanceForm.getFieldsValue();
        const exercice = balance.exercice;
        var exerciceN_1Id = null;
        if(exercice){
            const exercice1 = exercices.find(e => (e.exercice_id === exercice));
            setExercice1(moment(exercice1.date_fin).format("YYYY-MM-DD"));
            setExercice2(moment(exercice1.date_fin).subtract(1,"year").format("YYYY-MM-DD") );
        }

        const oldFluxRequest = await BilanService.getOldExerciceBilan(exercice);
        const oldFlux = oldFluxRequest.data
        const bilan = await checkIfTablesExist(exercice);
        const response = await BilanService.getBalance(exercice , exerciceN_1Id, oldFlux);

        if( bilan.length > 0 ) formatteReadyData(response.data , bilan , oldFlux )
        else await formatteData(response.data,oldFlux);
        setLoading(false);
    };

    const checkIfTablesExist = async ( exercice ) => {
        const response = await BilanService.checkIfTablesExist( exercice );
        return response.data;
    }
    
    const formatteReadyData = ( balance ,bilan,oldFlux ) => {
        const list = [];
        for (const element of balance) {
            let debit_1 = "0";
            let credit_1 = "0";
            let debit_2 = "0";
            let credit_2 = "0";
            if(element.montantComptes[0] != null){
                debit_1 = parseFloat( ( element?.montantComptes[0]?.debit + "" ).replace("," , ".") ) ?? "0";
                credit_1 = parseFloat( ( element?.montantComptes[0]?.credit + "" ).replace("," , ".") ) ?? "0";
            }
            if(element.montantComptes[1] != null){
                debit_2 = parseFloat( ( element?.montantComptes[1]?.debit + "" ).replace("," , ".") ) ?? "0";
                credit_2 = parseFloat( ( element?.montantComptes[1]?.credit + "" ).replace("," , ".") ) ?? "0";
            }

            const compte = {
                compte : element.compte,
                libelle : element.libelle,
                debit_1 : debit_1,
                credit_1 : credit_1,
                debit_2 : debit_2,
                credit_2 : credit_2,
                classe : element.classComptable,
                rubrique: element.rubrique
            }
            list.push(compte);
        }
        setDataTable(list);
        setBilanTables(bilan ,oldFlux);
    }

    const setBilanTables = (bilan  ,oldFlux ) => {
        const actifs = [];
        const passifs = [];
        const resultat = [];
        const tree = [];
        const flux = [];
        for (const element of bilan) {
            element.id = element.bilan_id;
            if ( element.note ) tree.push(element);
            if ( element.table_name === "actifs"  ) actifs.push(element);
            else if ( element.table_name === "passif" ) passifs.push(element);
            else if ( element.table_name === "resultat" ) resultat.push(element);
            else if ( element.table_name === "flux"){   
                if( oldFlux.length ) element.valMinusOne = oldFlux.find(e => (e.attribut === element.attribut))[0]?.val  
                else element.valMinusOne = 0;
                flux.push(element);
            }
        }
        tree.sort((a,b) => a.element_order - b.element_order);
        setActifTable(actifs);
        setPassifTable(passifs);
        setResultTables(resultat);
        setFluxTables(flux);
        setTreeData(tree);
    }
    
    const formatteData = async (balance ,oldFlux) => {
        console.log(" CALLLLLLLLLLED ");
        const list = [];
        const rubriquesResponse = await BilanService.getRubriques();
        const rubriques = rubriquesResponse.data;
        const actifs = [
            { attribut : "ACTIFS NON COURANTS" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Actifs immobilisés" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Immobilisations incorporelles" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Moins : amortissements 1" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Immobilisations corporelles" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Moins : amortissements 2" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Immobilisations financières" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Moins : provisions 1" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Total des actifs immobilisés" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Autres actifs non courants" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "TOTAL DES ACTIFS NON COURANTS" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "ACTIFS COURANTS" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Stocks" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Moins : provisions 2" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Clients et comptes rattachés" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Moins : provisions 3" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Autres actifs courants" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Placements et autres actifs financiers" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "Liquidités et équivalents de liquidités" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "TOTAL DES ACTIFS COURANTS" , val : 0 , tableName : "actifs", valMinusOne : 0 }, 
            { attribut : "TOTAL DES ACTIFS" ,  val : 0 , tableName : "actifs" }
        ]
        
        const passifs = [
            { attribut : "CAPITAUX PROPRES" , val : 0 , tableName :"passif", valMinusOne : 0 },
            { attribut : "Capitaux propres" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Capital social" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Réserves" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Autres capitaux propres" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Compte de liaison" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Résultats reportés" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Total capitaux propres avant résultat" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Résultat de l'exercice" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "TOTAL CAPITAUX PROPRES" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "PASSIFS" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Passifs non courants" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Emprunts" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Autres passifs financiers" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Provisions ",val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Total passifs non courants" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Passifs courants" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Fournisseurs et comptes rattachés" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Autres passifs courants" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Concours bancaires et autres passifs financiers ",val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "Total passifs courants" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "TOTAL DES PASSIFS" , val : 0 , tableName :"passif" ,valMinusOne : 0},
            { attribut : "TOTAL DES CAPITAUX PROPRES ET PASSIFS" , val : 0 , tableName :"passif" ,valMinusOne : 0},
        ];


        const resultat = [
            { attribut : "PRODUITS D'EXPLOITATION" , val : 0 , tableName : "resultat", valMinusOne : 0 }, 
            { attribut : "Revenus" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Autres produits d'exploitation" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Transfert de charges" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Total des produits d'exploitation" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "CHARGES D'EXPLOITATION" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Variation des stocks produits finis et encours" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Achats de marchandises consommés" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Achats d'approvisionnements consommés" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Charges de personnel" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Dotations aux amortissements et provisions" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Autres charges d'exploitation" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Total des charges d'exploitation" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Résultat d'exploitation",val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Charges financières nettes" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Produits des placements" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Autres gains ordinaires" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Autres pertes ordinaires" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Résultat des activités ordinaires avant impôt",val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Impôt sur les bénéfices",val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Résultat des activités ordinaires après impôt",val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Eléments extraordinaires" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Résultat net de l'exercice" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Effets des modifications comptables" , val : 0 , tableName : "resultat", valMinusOne : 0 },
            { attribut : "Résultat après modifications comptables" , val : 0 , tableName : "resultat", valMinusOne : 0 },
        ];

        const flux = [
            { attribut : "_Flux de trésorerie liés à l'exploitation" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Résultat net" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Ajustements pour :" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Amortissements et provisions" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Quote part subvention d'investissement" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Variation des :" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "- Stocks" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "- Créances" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "- Autres actifs" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "- Fournisseurs et autres dettes" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Plus ou moins values de cession" , val : 0 , tableName : "flux", valMinusOne : 0 }, 
            { attribut : "Transfert de charges" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "TOTAL Flux de trésorerie liés à l'exploitation" , val : 0 , tableName : "flux", valMinusOne : 0 }, 
            { attribut : "Flux de trésorerie liés aux activités d'investissement",val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Acquisitions d'immobilisations" , val : 0 , tableName : "flux", valMinusOne : 0 }, 
            { attribut : "Cessions d'immobilisations" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Acquisitions d'immobilisations financières" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Cessions d'immobilisations financières" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "TOTAL Flux de trésorerie liés aux activités d'investissement",val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Flux de trésorerie liés aux activités de financement",val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Encaissements suite à l'émission d'actions",val : 0 , tableName : "flux", valMinusOne : 0 }, 
            { attribut : "Dividendes et autres distributions" , val : 0 , tableName : "flux", valMinusOne : 0 }, 
            { attribut : "Encaissements provenant des emprunts" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Remboursements d'emprunts" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Autres encaissements" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "TOTAL Flux de trésorerie liés aux activités de financement",val : 0 , tableName : "flux", valMinusOne : 0 }, 
            { attribut : "Incidence des variations des taux de change sur les liquidités et équivalents de liquidités",val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "Variation de trésorerie",val : 0 , tableName : "flux", valMinusOne : 0 }, 
            { attribut : "Trésorerie au début de l'exercice",val : 0 , tableName : "flux", valMinusOne : 0 }, 
            { attribut : "Trésorerie à la clôture de l'exercice" , val : 0 , tableName : "flux", valMinusOne : 0 },
            { attribut : "TOTAL Variation de trésorerie" , val : 0 , tableName : "flux", valMinusOne : 0 }, 
        ];

        for (const element of balance) {
            let debit_1 = "0";
            let credit_1 = "0";
            let debit_2 = "0";
            let credit_2 = "0";
            if(element.montantComptes[0] != null){
                debit_1 = parseFloat( ( element?.montantComptes[0]?.debit + "" ).replace("," , ".") ) ?? "0";
                credit_1 = parseFloat( ( element?.montantComptes[0]?.credit + "" ).replace("," , ".") ) ?? "0";
            }
            if(element.montantComptes[1] != null){
                debit_2 = parseFloat( ( element?.montantComptes[1]?.debit + "" ).replace("," , ".") ) ?? "0";
                credit_2 = parseFloat( ( element?.montantComptes[1]?.credit + "" ).replace("," , ".") ) ?? "0";
            }
            const { classe , rubrique } = initRubriques( element.compte , debit_1 , credit_1 , rubriques );
            const compte = {
                compte : element.compte,
                libelle : element.libelle,
                debit_1 : debit_1,
                credit_1 : credit_1,
                debit_2 : debit_2,
                credit_2 : credit_2,
                classe : classe,
                rubrique: rubrique
            }
            await BilanService.updateCompteRubrique(element.balance_id , compte.rubrique , compte.classe);
            initTables(compte, actifs, passifs, resultat, flux, oldFlux);
            list.push(compte);
        }
        setDataTable(list);
        await addBilan(actifs ,passifs ,resultat ,flux);
    };

    const initRubriques = ( searchCompte , debit_1 , credit_1 , rubriques  ) => {
        let classe = getClasse(searchCompte);
        let rubrique = "";


        //  DEBIT MOIS CREDIT 
        for (const rub of rubriques) {
            for (const compte of rub.comptesRubriques ) {
                if( compte.compte_starts_with === ""+searchCompte.substring(0, compte.compte_starts_with.length ) ) {
                    
                    if( compte.compte_condition === 0 ) {
                        rubrique = rub.nom;
                        break;
                    } else if ( compte.compte_condition === 1 ) {
                        if(  debit_1 - credit_1 > 0  ){
                            rubrique = rub.nom;
                            break;
                        }
                    } else if ( compte.compte_condition === -1 ) {
                        if( debit_1 - credit_1 < 0    ){
                            rubrique = rub.nom;
                            break;
                        }
                    }
                } 
            }
            if( rubrique !== "" ) break;
        }
        return { classe,rubrique }
    }

    const addBilan = async (actifs ,passifs , resultat,flux) => {
        const balance = balanceForm.getFieldsValue();
        const exercice = balance.exercice;
        const data = [...actifs , ...passifs , ...resultat , ...flux];
        await BilanService.addBilan( data, exercice );
        getBalanceData();
    }

    const initTables = ( compte , actifs ,passifs , resultat ,flux , oldFlux) => {
        for (const element of actifs) {
            if (compte.rubrique === element.attribut ) {
                element.val = Number(element.val + ( compte.debit_1 - compte.credit_1 ) );
                element.valMinusOne = Number(element.valMinusOne + ( compte.debit_2 - compte.credit_2 ) );
            };
        }
        for (const element of passifs) {
            if (compte.rubrique === element.attribut ) {
                element.val = Number(element.val + ( compte.credit_1 - compte.debit_1 ) );
                element.valMinusOne = Number(element.valMinusOne + ( compte.debit_2 - compte.credit_2 ) );
            }
        }
        for (const element of resultat) {
            if (compte.rubrique === element.attribut ) {
                element.val = Number(element.val + ( compte.debit_1 - compte.credit_1 ) );
                element.valMinusOne = Number(element.valMinusOne + ( compte.debit_2 - compte.credit_2 ) );
            }
        }
        for (const element of flux) {
            if (compte.rubrique === element.attribut ) {
                element.val = Number(element.val + ( compte.debit_1 - compte.credit_1 ) );
                element.valMinusOne = oldFlux ? oldFlux.find(e => (e.attribut === element.attribut) )[0]?.val : 0;
            }
        }
        completeTables(actifs ,passifs,resultat,flux);
    };

    const completeTables = (actifs ,passifs ,resultat,flux) => {
        completeActifTable(actifs);
        completePassifTable(passifs);
        completeResultatTable(resultat);
        completeFluxTable(flux , actifs ,passifs ,resultat)
    };

    const completeActifTable = (actifs) => {
        actifs[8].val = Number( parseFloat(actifs[2].val) + parseFloat(actifs[3].val) + parseFloat(actifs[4].val) + parseFloat(actifs[5].val) + parseFloat(actifs[6].val) + parseFloat(actifs[7].val)).toFixed(3);
        actifs[10].val = Number( parseFloat(actifs[8].val) + parseFloat(actifs[9].val) ).toFixed(3);
        actifs[19].val = Number( parseFloat(actifs[12].val) + parseFloat(actifs[13].val) + parseFloat(actifs[14].val) + parseFloat(actifs[15].val) + parseFloat(actifs[16].val) + parseFloat(actifs[17].val) + parseFloat(actifs[18].val)).toFixed(3) ;
        actifs[20].val = Number( parseFloat(actifs[19].val) + parseFloat(actifs[10].val)).toFixed(3);

        actifs[8].valMinusOne = Number( parseFloat(actifs[2].valMinusOne) + parseFloat(actifs[3].valMinusOne) + parseFloat(actifs[4].valMinusOne) + parseFloat(actifs[5].valMinusOne) + parseFloat(actifs[6].valMinusOne) + parseFloat(actifs[7].valMinusOne)).toFixed(3);
        actifs[10].valMinusOne = Number( parseFloat(actifs[8].valMinusOne) + parseFloat(actifs[9].valMinusOne) ).toFixed(3);
        actifs[19].valMinusOne = Number( parseFloat(actifs[12].valMinusOne) + parseFloat(actifs[13].valMinusOne) + parseFloat(actifs[14].valMinusOne) + parseFloat(actifs[15].valMinusOne) + parseFloat(actifs[16].valMinusOne) +  parseFloat(actifs[17].valMinusOne) + parseFloat(actifs[18].valMinusOne)).toFixed(3);
        actifs[20].valMinusOne = Number( parseFloat(actifs[19].valMinusOne) + parseFloat(actifs[10].valMinusOne)).toFixed(3);
    };

    const completePassifTable = (passifs) =>{
        passifs[7].val = Number( parseFloat(passifs[2].val) + parseFloat(passifs[3].val) + parseFloat(passifs[4].val) + parseFloat(passifs[5].val) + parseFloat(passifs[6].val)).toFixed(3); 
        passifs[9].val = Number( parseFloat(passifs[7].val) + parseFloat(passifs[8].val) ).toFixed(3); 
        passifs[15].val = Number( parseFloat(passifs[12].val) + parseFloat(passifs[13].val) + parseFloat(passifs[14].val)).toFixed(3); 
        passifs[20].val = Number( parseFloat(passifs[17].val) + parseFloat(passifs[18].val) + parseFloat(passifs[19].val)).toFixed(3); 
        passifs[21].val = Number( parseFloat(passifs[20].val) + parseFloat(passifs[15].val)).toFixed(3); 
        passifs[22].val = Number( parseFloat(passifs[9].val) + parseFloat(passifs[21].val)).toFixed(3); 

        passifs[7].valMinusOne = Number( parseFloat(passifs[2].valMinusOne) + parseFloat(passifs[3].valMinusOne) + parseFloat(passifs[4].valMinusOne) + parseFloat(passifs[5].valMinusOne) + parseFloat(passifs[6].valMinusOne)).toFixed(3); 
        passifs[9].valMinusOne = Number( parseFloat(passifs[7].valMinusOne) + parseFloat(passifs[8].valMinusOne) ).toFixed(3); 
        passifs[15].valMinusOne = Number(parseFloat(passifs[12].valMinusOne) + parseFloat(passifs[13].valMinusOne) + parseFloat(passifs[14].valMinusOne)).toFixed(3); 
        passifs[20].valMinusOne = Number(parseFloat(passifs[17].valMinusOne) + parseFloat(passifs[18].valMinusOne) + parseFloat(passifs[19].valMinusOne)).toFixed(3); 
        passifs[21].valMinusOne = Number(parseFloat(passifs[20].valMinusOne) + parseFloat(passifs[15].valMinusOne)).toFixed(3); 
        passifs[22].valMinusOne = Number(parseFloat(passifs[9].valMinusOne) + parseFloat(passifs[21].valMinusOne)).toFixed(3); 
    }; 

    const completeResultatTable = (resultat) =>{
        resultat[1].val = Number(parseFloat(resultat[1].val) + parseFloat(resultat[2].val) + parseFloat(resultat[3].val)).toFixed(3); 
        resultat[12].val = Number(parseFloat(resultat[6].val) + parseFloat(resultat[7].val) + parseFloat(resultat[8].val) + parseFloat(resultat[9].val) +parseFloat(resultat[10].val) + parseFloat(resultat[11].val)).toFixed(3);
        resultat[13].val = Number(parseFloat(resultat[4].val) - parseFloat(resultat[12].val)).toFixed(3); 
        resultat[18].val = Number(parseFloat(resultat[13].val) - parseFloat(resultat[14].val) - parseFloat(resultat[15].val) - parseFloat(resultat[16].val) - parseFloat(resultat[17].val)).toFixed(3); 
        resultat[20].val = Number(parseFloat(resultat[18].val) + parseFloat(resultat[19].val)).toFixed(3); 
        resultat[22].val = Number(parseFloat(resultat[20].val) + parseFloat(resultat[21].val)).toFixed(3); 
        resultat[24].val = Number(parseFloat(resultat[22].val) + parseFloat(resultat[23].val)).toFixed(3); 

        resultat[4].valMinusOne = Number( parseFloat(resultat[1].valMinusOne) + parseFloat(resultat[2].valMinusOne) + parseFloat(resultat[3].valMinusOne)).toFixed(3); 
        resultat[12].valMinusOne = Number( parseFloat(resultat[6].valMinusOne) + parseFloat(resultat[7].valMinusOne) + parseFloat(resultat[8].valMinusOne) + parseFloat(resultat[9].valMinusOne) +parseFloat(resultat[10].valMinusOne) + parseFloat(resultat[11].valMinusOne) ).toFixed(3);
        resultat[13].valMinusOne = Number( parseFloat(resultat[4].valMinusOne) - parseFloat(resultat[12].valMinusOne)).toFixed(3); 
        resultat[18].valMinusOne = Number( parseFloat(resultat[13].valMinusOne) - parseFloat(resultat[14].valMinusOne) - parseFloat(resultat[15].valMinusOne) - parseFloat(resultat[16].valMinusOne) - parseFloat(resultat[17].valMinusOne)).toFixed(3); 
        resultat[20].valMinusOne = Number( parseFloat(resultat[18].valMinusOne) + parseFloat(resultat[19].valMinusOne)).toFixed(3); 
        resultat[22].valMinusOne = Number( parseFloat(resultat[20].valMinusOne) + parseFloat(resultat[21].valMinusOne)).toFixed(3); 
        resultat[24].valMinusOne = Number( parseFloat(resultat[22].valMinusOne) + parseFloat(resultat[23].valMinusOne)).toFixed(3); 
    }; 

    const completeFluxTable = (flux,actifs ,passifs ,resultat) =>{
        flux[4].val = Number(parseFloat(resultat[24])).toFixed(3);
        flux[3].val = Number(parseFloat(resultat[10])).toFixed(3);
        flux[6].val = Number(parseFloat(actifs[12].valMinusOne) - parseFloat(actifs[12].val)).toFixed(3);
        flux[7].val = Number(parseFloat(actifs[13].valMinusOne) - parseFloat(actifs[13].val)).toFixed(3); 
        flux[8].val = Number(parseFloat(actifs[15].valMinusOne) - parseFloat(actifs[15].val)).toFixed(3);
        flux[9].val = Number(parseFloat(passifs[17].val) + parseFloat(passifs[18].val) - parseFloat(passifs[17].valMinusOne) + parseFloat(passifs[18].valMinusOne)).toFixed(3);
        flux[12].val = Number(parseFloat(passifs[17].val) + parseFloat(passifs[1].val) + parseFloat(passifs[3].valMinusOne) + parseFloat(passifs[6].valMinusOne)+ parseFloat(passifs[7].valMinusOne) + parseFloat(passifs[8].valMinusOne) + parseFloat(passifs[9].valMinusOne)).toFixed(3);
        flux[14].val = Number(parseFloat(actifs[2].val) - actifs[4].val + parseFloat(actifs[2].valMinusOne) - actifs[4].valMinusOne).toFixed(3);
        flux[16].val = Number(parseFloat(actifs[6].val) + parseFloat(actifs[6].valMinusOne)).toFixed(3);
        flux[18].val = Number(parseFloat(flux[14].val) + parseFloat(flux[15].val) + parseFloat(flux[16].val) + parseFloat(flux[17].val)).toFixed(3);
        flux[22].val = Number(parseFloat(passifs[17].val) - parseFloat(passifs[17].valMinusOne)).toFixed(3);
        flux[25].val = Number(parseFloat(flux[20].val) + parseFloat(flux[21].val) + parseFloat(flux[22].val) + parseFloat(flux[23].val) + parseFloat(flux[24].val)).toFixed(3);
        flux[27].val = Number(parseFloat(flux[25].val) + parseFloat(flux[18].val) + parseFloat(flux[12].val)).toFixed(3);
        flux[28].val = Number(parseFloat(flux[29].valMinusOne)).toFixed(3);
        flux[29].val = Number(parseFloat(actifs[17])).toFixed(3);
        flux[30].val = Number(parseFloat(flux[29].val) - parseFloat(flux[28].val)).toFixed(3);
    }; 
    
    const getClasse = ( compte ) => {
        if( compte >= 99999 && compte <= 199999 ) return "Compte de capitaux";
        else if( compte >= 200000 && compte <= 299999 ) return "Compte d'immoblisation";
        else if( compte >= 300000 && compte <= 399999 ) return "Compte de stock";
        else if( compte >= 400000 && compte <= 499999 ) return "Compte de tiers";
        else if( compte >= 500000 && compte <= 599999 ) return "Compte de finnancier";
        else if( compte >= 600000 && compte <= 699999 ) return "Compte de charge";
        else if( compte >= 700000 && compte <= 799999 ) return "Compte de produit";
        else return "compte Speciaux";
    }

    const updateRubText = async () => {
        setLoading(true);
        const response = await BilanService.updateRubData( treeData  );
        if (response.status === 200 || 204) {
            exportMessages.addSuccess("Mise à jours des notes avec success");
        }else exportMessages.errorMessage();
        setLoading(false);
    }

    const addNewNoteConfirm = async () => {
        setLoading(true);
        const list = treeData;
        const data = sectionForm.getFieldsValue();
        const exercice = balanceForm.getFieldValue();
        const newNote = {attribut: data.title, val : 0  , description : content , tableName: "added" , exercice: exercice}
        const response = await BilanService.addNoteToRapport( newNote , exercice.exercice );
        if (response.status === 200 || 204){
            newNote.id = parseFloat(response.data.message );
            newNote.bilan_id = parseFloat(response.data.message); 
            list.push(newNote);
            setTreeData(list);
            closeNewNoteModal();
        }
        else exportMessages.errorMessage();
        setLoading(false);
    }

    const addNote = async ( record ) => {
        setLoading(true);
        const note = !record.note;
        const response = await BilanService.updateNote( record.bilan_id , note  );
        if(response.status === 200 ){
            updateTables(record.table_name , record.bilan_id , note )
            const msg = note ? "Ajouter" : "Retier";
            exportMessages.addSuccess("Note " + msg )
        } else exportMessages.errorMessage();
        setLoading(false)
    }



    const updateTables = ( tableName , id ,note) => {
        let list = [];
        let tree = treeData;
        if( tableName === "actifs" ) {
            list = actifTable;
            const index = list.findIndex( element => element.bilan_id === id);
            actifTable[index].note = note;
            if( note ) {
                actifTable[index].title = actifTable[index].attribut;
                tree.push(actifTable[index]);
            } 
            else tree = tree.filter( e => (e.bilan_id !== id ));
            
        } else if ( tableName === "passif" ){
            list = passifTable;
            const index = list.findIndex( element => element.bilan_id === id);
            passifTable[index].note = note;
            if( note ) {
                passifTable[index].title = passifTable[index].attribut;
                tree.push(passifTable[index]);
            } else tree = tree.filter( e => (e.bilan_id !== id ));
        } else if( tableName === "resultat" ){
            list = resultTable;
            const index = list.findIndex( element => element.bilan_id === id);
            resultTable[index].note = note;
            if( note ){
                resultTable[index].title = resultTable[index].attribut;
                tree.push(resultTable[index])
            } else tree = tree.filter( e => (e.bilan_id !== id ));
        } else if( tableName === "flux" ){
            list = fluxTables;
            const index = list.findIndex( element => element.bilan_id === id);
            fluxTables[index].note = note;
            if( note ){
                fluxTables[index].title = fluxTables[index].attribut;
                tree.push(fluxTables[index])
            } else tree = tree.filter( e => (e.bilan_id !== id ));
        } else if ( tableName === "added"){
            tree = tree.filter( e => (e.bilan_id !== id ));
        }
        setTreeData(tree);

    }

    const editNote = (id, attribut , table) => {
        setUpdateData({id, attribut , table});
        let text = treeData.find(element =>( element.bilan_id === id)).description;
        if( text === null ) text = `<h5 style="margin-left: 5px; color : rgb(74, 134, 232) ">${attribut} : </h5> <p> - `
        setContent(text);
        setVisible(true);
    }

    const saveData = async () => {
        const list = treeData;
        const index = treeData.findIndex(element => element.bilan_id === updateData.id);
        list[index].description = content;
        closeEditor();
    }

    const navigate = (location) => {
        history.push(location);
    };

    const closeEditor = () => {
        setVisible(false);
        setUpdateData(null);
        setContent("");
    }

    const clearStates = () => {
        balanceForm.resetFields();
    };

    const importData = () => {
        const data = dataTable.filter(e => e.rubrique === updateData.attribut);
        if( data.length ){
            const importedData = formmateData(data);
            let currentContent = content;
            setContent(currentContent + importedData);
        } else exportMessages.errorMessage("Aucun compte n'est associé a cette rubrique");
    }

    const fullRapportEdit = () => {
        let str = "";
        for (const element of treeData) if(element.description !==null ) str += element.description; 
        setContent(str);
        setVisible(true);
        
    }

    const disableImport = () => {
        return !["actifs","passif","resultat"].includes(updateData?.table);
    }


    const reloadBilan = (record) => {
        console.log( record );
        Modal.confirm({
          title: "Attention",
          icon: <ExclamationCircleOutlined />,
          content: "Le rechargement va supprimer le rapport et les notes liée a cette exercice ? ",
          onOk: () => reloadBilanData(record),
          okText: "Oui",
          cancelText: "Non",
        });
    };
  
    const reloadBilanData = async (record) => {
        try{
            setLoading( true );
            console.log(  record.exercice_id );
            await BilanService.deleteBilanData( record.exercice_id );
            exportMessages.infoMessage("Rechargement en cours ...");
            // await getBalanceData();
            setLoading( false );
        } catch (err){
            exportMessages.errorMessage();
            setLoading( false );
        }
    };

    const closeNewNoteModal = () => {
        sectionForm.resetFields();
        setNoteModal(false);
    } 
    
    const addNewNote = () => {
        setNoteModal(true);
    }

    const recordSelected = (record) =>{
        sectionForm.setFieldsValue({title : record.attribut});
        setContent(record.description);
        setHelpersModal(false);
    }

    const formmateData = (data) => {
        const arrayHead = `<br><table style="width: 96.8109%; margin-left: 0.0759301%; height: 72px;">
        <tbody>
            <tr>
                <td style="width: 10%; background-color: rgb(74, 134, 232); color : #fff ">Compte<br></td>
                <td style="width: 40%; background-color: rgb(74, 134, 232); color : #fff ">Libelle<br></td>
                <td style="width: 10%; background-color: rgb(74, 134, 232); color : #fff ">Debit<br></td>
                <td style="width: 10%; background-color: rgb(74, 134, 232); color : #fff ">Credit<br></td>
                <td style="width: 40%; background-color: rgb(74, 134, 232); color : #fff ">Rubrique<br></td>
            </tr>
        `;    

        let tableData = "" 
        for (const element of data) {
            tableData +=
            `<tr>
                <td style="width: 10%;">${element.compte}<br></td>
                <td style="width: 40%;">${element.libelle}<br></td>
                <td style="width: 10%;">${element.debit_1}<br></td>
                <td style="width: 10%;">${element.credit_1}<br></td>
                <td style="width: 40%;">${element.rubrique}<br></td>
            </tr>` 
        }
        const ArrayEnd = `
                </tr>
            </tbody>
        </table> <br /> <p>`;
        return arrayHead + tableData + ArrayEnd;
        
    }
    const getEtat =(etat) =>{
        if(etat === 0) return "Balance Non Chargée";
        if(etat === 1) return "Balance Affectée";
        if(etat === 2) return "Rapport En Cours de prépartion";
        if(etat === 3) return "Rapport Validé";
    }


    const downloadFile = async (record) => {
        const file = record.file_id[0];
        await fileService.getFile(file.file_id, "4").then((response) => {
          let url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
        });
      };


   

    const getStyle = (element) => {
        const title = titles.find(e => (e.title === element.attribut));
        if (title?.emplacement === 1 || title?.emplacement === 0 ) {
            if ( title?.emplacement === 1 ) return "margin-left-small italic";
            if ( title?.emplacement === 0 ) return "bold";
        } else return "margin-left-large"
        
    }

    const getAttributeValue = (val , attribut) => {
        const title = titles.find(e => (e.title === attribut));
        const element = parseFloat(val);
        if( title?.hide ) return "";
        else {
            if( element !== null && element !== "" ) return (element).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            else return "";
        } 
    }

    const getElementValue = (val) => {
        const value = parseFloat(val);
        return (value).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    const ExerciceColumn = [
        {
            title: "Année",
            dataIndex: "annee",
            sorter: (a, b) => a.annee.localeCompare(b.annee),
        },
        {
            title: "Date debut",
            dataIndex: "showDateDebut",
        },
        {
            title: "Date fin",
            dataIndex: "showDateFin",
        },
        {
            title: "Etat",
            dataIndex: "etat",
            render: (text, record) => (
              <div>{getEtat(record.etat)}</div>
          ),
        },
        {
            title: "Action",
            key: "action",
            width: "20%",
            render: (text, record) => (
                <div>
                    <Tooltip title="recharger les calcules">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => reloadBilan(record)}
                            icon={<SyncOutlined />}
                        />
                    </Tooltip>
                    <Tooltip title="Telecharger Balance ">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => downloadFile(record)}
                            icon={<CloudDownloadOutlined />}
                        />
                    </Tooltip>
                    <Tooltip title="Visaliser les Tableaux ">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => showBalance(record)}
                            icon={<EyeOutlined />}
                        />
                    </Tooltip>
                    <Tooltip title="Visaliser Rapport ">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => showAlldata(record)}
                            icon={<BookOutlined />}
                        />
                    </Tooltip>
                    
                </div>
            ),
        },
    ];
  
    const bilanCols = [
        {
            title: " ",
            dataIndex: "attribut",
            key: "attribut",
            render: (text, record) => (
                <div className={getStyle(record) } >
                    {record.attribut}
                </div>
            ),
        },
        {
            title: `${exercice1}`,
            dataIndex: "val",
            key: "val",
            width:"10%",
            render: (text, record) => (
                <div className="mx-2">
                    {getAttributeValue(record.val , record.attribut)}
                </div>
            ),
        },{
            title: `${exercice2}`,
            dataIndex: "valMinusOne",
            key: "valMinusOne",
            width:"10%",
            render: (text, record) => (
                <div className="mx-2" >
                    {getAttributeValue(record.valMinusOne , record.attribut)}
                </div>
            ),
        },
        {
            title: "Action",
            key: "action",
            width : "10%",
            render: (text, record) => (
                <div className="mx-2">
                    <Tooltip title={record.note ? "Supprimer la note ":"Ajouter une note"}>
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => addNote(record)}
                            icon={ record.note ? <CloseOutlined /> :<HighlightOutlined />}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const columns = [
        {
            title: "Compte",
            dataIndex: "compte",
            key: "compte",
        },
        {
            title: "Libelle",
            dataIndex: "libelle",
            key: "libelle",
        },
        {
            title: `${exercice1}`,
            children: [
                {
                    title: "Debit",
                    dataIndex: "debit_1",
                    key: "debit_1",
                    render: (text, record) => (
                        <>
                            {getElementValue(record.debit_1)}
                        </>
                    ),
                },
                {
                    title: "Credit",
                    dataIndex: "credit_1",
                    key: "credit_1",
                    render: (text, record) => (
                        <>
                            {getElementValue(record.credit_1)}
                        </>
                    ),
                },
            ],
        },{
            title: `${exercice2}`,
            children: [
                {
                    title: "Debit",
                    dataIndex: "debit_2",
                    key: "debit_2",
                    render: (text, record) => (
                        <>
                            {getElementValue(record.debit_2)}
                        </>
                    ),
                },
                {
                    title: "Credit",
                    dataIndex: "credit_2",
                    key: "credit_2",
                    render: (text, record) => (
                        <>
                            {getElementValue(record.credit_2)}
                        </>
                    ),
                },
            ],
        },
        {
            title: "Classe",
            dataIndex: "classe",
            key: "classe",
        },
        {
            title: "Rubrique",
            dataIndex: "rubrique",
            key: "rubrique",
        },
    ];
        
    const RapportHelperColumn = [
        {
          title: "Titre",
          dataIndex: "attribut",
          sorter: (a, b) => a.attribut.localeCompare(b.attribut),
          width: "80%",
        },{
          title: "Action",
          key: "action",
          render: (text, record) => (
            <div>
                <Tooltip title="Importer">
                    <Button
                        className="mx-1"
                        type="dashed"
                        shape="circle"
                        onClick={()=> recordSelected(record)}
                        icon={<DownloadOutlined />}
                    />
                </Tooltip>
                
                <Button
                    className="mx-1"
                    type="dashed"
                    shape="circle"
                    disabled
                    icon={<EditOutlined />}
                />
                <Button
                    className="mx-1"
                    type="dashed"
                    shape="circle"
                    disabled
                    icon={<DeleteOutlined />}
                />
            </div>
          ),
        },
    ];

    const locale = {
        emptyText: (
            <Empty description={"Le tableau est vide pour le moment "}></Empty>
        ),
    };

    return (
        <Card headStyle={{ backgroundColor: "#dee0e9" }}          
            title="États financiers & Rapport "
            style={{ width: "100%" }}
            extra={
                <Button
                    className="mx-2"
                    shape="round"
                    type="primary"
                    onClick={() => navigate("/add_balance")}
                >
                    Ajouter Une Nouvelle Balance
                </Button>
            }
        >
            <Spin spinning={isLoading} size="large">

                <AppTable data={exercices} columns={ExerciceColumn} />
                <Tabs defaultActiveKey="1" >
                    <Tabs.TabPane tab="Balance" key="1">
                        <Table 
                            rowKey={(record) => record.id}
                            locale={locale} 
                            showSorterTooltip={false}
                            columns={columns}
                            size="small"
                            pagination={{ position: ["bottomCenter"], defaultPageSize: 1000 }}
                            dataSource={dataTable} 
                        /> 
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Actifs" key="2" disabled={actifTable.length === 0}>
                        <Row className="d-flex flex-row-reverse my-3">
                            <ReactHtmlTableToExcel
                                id="test-table-xls-button"
                                className="ant-btn ant-btn-primary  mx-2"
                                table="actif-table"
                                filename={'Actifs-Exercice-'+exercice1}
                                sheet="tablexls"
                                buttonText="Exporter La Table Des Actifs"
                                shape="round"
                                type="primary"
                            ></ReactHtmlTableToExcel>
                        </Row>

                        <Table 
                            rowKey={(record) => record.id}
                            locale={locale} 
                            showSorterTooltip={false}
                            size="small"
                            columns={bilanCols}
                            pagination={{ position: ["bottomCenter"], defaultPageSize: 50 }}
                            dataSource={actifTable} 
                        /> 
                                                                                                                                                      
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Passifs" key="3" disabled={passifTable.length === 0}>
                        <Row className="d-flex flex-row-reverse my-3">
                            <ReactHtmlTableToExcel
                                id="test-table-xls-button"
                                className="ant-btn ant-btn-primary mx-2"
                                table="passif-table"
                                filename={'Passifs-Exercice-'+exercice1}
                                sheet="tablexls"
                                buttonText="Exporter La Table Des Passifs"
                                shape="round"
                                type="primary"
                            ></ReactHtmlTableToExcel>
                        </Row>
                         <Table 
                            rowKey={(record) => record.id}
                            locale={locale} 
                            showSorterTooltip={false}
                            columns={bilanCols}
                            size="small"
                            pagination={{ position: ["bottomCenter"], defaultPageSize: 50 }}
                            dataSource={passifTable} 
                        /> 
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Resultat" key="5" disabled={resultTable.length === 0}>
                        <Row className="d-flex flex-row-reverse my-3">

                            <ReactHtmlTableToExcel
                                id="test-table-xls-button"
                                className="ant-btn ant-btn-primary mx-2"
                                table="result-table"
                                filename={'Resultat-Exercice-'+exercice1}
                                sheet="result-table"
                                buttonText="Exporter La Table De Resultat"
                                shape="round"
                                type="primary"
                            ></ReactHtmlTableToExcel>
                        </Row>
                        <Table 
                            rowKey={(record) => record.id}
                            locale={locale} 
                            showSorterTooltip={false}
                            columns={bilanCols}
                            size="small"
                            pagination={{ position: ["bottomCenter"], defaultPageSize: 50 }}
                            dataSource={resultTable} 
                        /> 
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Flux" key="4" disabled={fluxTables.length === 0}>
                        <Row className="d-flex flex-row-reverse my-3">
                            <ReactHtmlTableToExcel
                                id="test-table-xls-button"
                                className="ant-btn ant-btn-primary mx-2"
                                table="flux-table"
                                filename={'Flux-Exercice-'+exercice1}
                                sheet="tablexls"
                                buttonText="Exporter La Table Des Passifs"
                                shape="round"
                                type="primary"
                            ></ReactHtmlTableToExcel>
                        </Row>
                         <Table 
                            rowKey={(record) => record.id}
                            locale={locale} 
                            showSorterTooltip={false}
                            columns={bilanCols}
                            size="small"
                            pagination={{ position: ["bottomCenter"], defaultPageSize: 50 }}
                            dataSource={fluxTables} 
                        /> 
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Rapport" key="6" disabled={passifTable.length === 0}>
                        <Row className="d-flex flex-row-reverse my-3">
                            <Button 
                                type="primary"
                                className="mx-2"
                                onClick={()=> updateRubText()}
                            >
                                Enregistrer
                            </Button>

                            <Button 
                                type="primary"
                                className="mx-2"
                                onClick={()=> addNewNote()}
                            >
                                Ajouter une Section

                            </Button>
                            
                            <Button 
                                type="primary"
                                className="mx-2"
                                onClick={() => fullRapportEdit()}
                            >
                                Visualiser le rapport
                            </Button>

                        </Row>
                        <ReactSortable list={treeData} setList={setTreeData}>
                            {treeData.map((element, index) => (
                            <Row key={element.bilan_id} className=" py-2 my-2 mx-2 ">
                                <div className="d-flex justify-content-between w-100 px-3 py-2 my-list">
                                    <div className="d-flex align-items-center" >
                                        <b className="px-2">{index + 1} -  </b>  {element.attribut}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Tooltip title="Editer la note">
                                            <Button className="mx-2" onClick={() => editNote(element.bilan_id , element.attribut , element.table_name)} shape="circle" icon={<EditOutlined />} />
                                        </Tooltip>
                                        <Tooltip title="Supprimer la note" >
                                            <Button className="mx-2" onClick={() => addNote(element)} shape="circle" icon={<DeleteOutlined />} />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Row>
                            ))}
                        </ReactSortable>
                    </Tabs.TabPane>
                </Tabs>
            </Spin>
            <Modal
                centered
                visible={visible}
                onOk={() => saveData()}
                onCancel={() => closeEditor(false)}
                okText="Enregistrer"
                cancelText="Annuler"
                width={"90%"}
                height={"900px"}
                okButtonProps = {{ disabled :  updateData ? false : true }}
                cancelButtonProps = {{disabled :  updateData ? false : true }}
                title={
                    <>
                      <div className="d-flex justify-content-between ">
                        <div className="my-0">
                            Visualisation du rapport : {updateData ? " Section : " + updateData.attribut : " "}
                        </div>
                        <div>
                            <Button
                                className="mx-4"
                                shape="round"
                                onClick={() => importData(true)}
                                disabled={disableImport()}
                            >
                                Importer les données
                            </Button>
                        </div>
                      </div>
                    </>
                }
            >
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={updateData ? config : visualtionConfig}
                    placeholder={''}
                    tabIndex={1} 
                    disabled
                    onBlur={newContent => setContent(newContent)}
                />
            </Modal>
            <Modal
                centered
                visible={noteModal}
                onOk={() => addNewNoteConfirm()}
                onCancel={() => closeNewNoteModal(false)}
                okText="Enregistrer"
                cancelText="Annuler"
                width={"90%"}
                height={"%90"}
                title={
                    <>
                      <div className="d-flex justify-content-between ">
                        <div className="my-0">
                            Nouvelle section
                        </div>
                        <div>
                            <Button
                                className="mx-4"
                                shape="round"
                                onClick={() => setHelpersModal(true)}
                            >
                                Importer les données
                            </Button>
                        </div>
                      </div>
                    </>
                }
            >
            <Form layout="vertical" form={sectionForm}>
                <Form.Item
                    label="Titre de la section "
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Champ obligatoire !",
                        },
                    ]}
                >
                    <Input
                        placeholder=""
                        size="large"
                        type="text"
                    />
                </Form.Item>
            </Form>
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    placeholder={''}
                    tabIndex={1} 
                    onBlur={newContent => setContent(newContent)} 
                />
            </Modal>

            <Modal
                centered
                visible={helpersModal}
                onCancel={() => setHelpersModal(false)}
                footer={false}
                width={"60%"}
                title={"Sections prédéfinie"}
            >
                <AppTable data={helpersData} columns={RapportHelperColumn} />
            </Modal>
            <ExportTables 
                actifs={actifTable}
                passifs={passifTable}
                flux={fluxTables}
                resultat={resultTable}
                exercice1={exercice1}
                exercice2={exercice2}

            ></ExportTables>
            <div style={{visibility : "hidden"}}>
                <Form layout="vertical" form={balanceForm} >
                    <Form.Item label="Exercice" name="exercice" >
                        <Input type="text" />
                    </Form.Item>
                </Form>
            </div>
        </Card>
        
    );
};
export default Balances;
