import {
  CREATE_USERS,
  RETRIEVE_USERS,
  UPDATE_USERS,
  DELETE_USERS,
  DELETE_ALL_USERS,
  } from "../actions/type";
  
  const initialState = [];
  
  function usersReducer(users = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_USERS:
        return [...users, payload];
  
      case RETRIEVE_USERS:
        return payload;
  
      case UPDATE_USERS:
        return users.map((user) => {
          if (user.id === payload.id) {
            return {
              ...user,
              ...payload,
            };
          } else {
            return user;
          }
        });
  
      case DELETE_USERS:
        return users.filter(({ id }) => id !== payload.id);
  
      case DELETE_ALL_USERS:
        return [];
  
      default:
        return users;
    }
  };
  
  export default usersReducer;