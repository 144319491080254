import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import CAN from "../../../Can/Can";
import paiementMethodeService from "../../../services/paiementMethodes.service";
import moyenPaiementService from "../../../services/paiementMoyens.service";
import messages from "../../../UIcomponent/messages/messages";
import AppTable from "../../../UIcomponent/Table/AppTable";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";
const { TextArea } = Input;

function MethodePaiement() {
  const history = useHistory();
  const { user: currentUser } = useSelector((state) => state.auth);

  const [paiementMethodModal, setPaiementMethodModal] = useState(false);
  const [moyenPaiementModal, setMoyenPaiementModal] = useState(false);
  const [confirmMoyenPaiementLoading, setConfirmMoyenPaiementLoading] =
    useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [moyenForm] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [paiementTypes, setPaiementTypes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [switchValue, setSwitchValue] = useState("unchecked");
  const [updateId, setUpdateId] = useState();
  const [type, setType] = useState();
  const { Option } = Select;

  useEffect(() => {
    getPaiementTypes();
    getPaiements();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setPaiementTypes([]);
    setEditMode(false);
    setUpdateId(null);
  };

  const PaiementColumn = [
    {
      title: "Nom",
      dataIndex: "nom",
      responsive: ["md"],
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "Type",
      dataIndex: "showType",
      responsive: ["md"],
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Type du compte",
      dataIndex: "type_account",
      responsive: ["md"],
      sorter: (a, b) => a.type_account.localeCompare(b.type_account),
    },
    {
      title: "Description",
      dataIndex: "description",
      responsive: ["md"],
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Etat",
      dataIndex: "etat",
      responsive: ["md"],
      sorter: (a, b) => a.etat.localeCompare(b.etat),
    },
    {
      title: "Action",
      key: "action",
      responsive: ["sm"],
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            {(CAN("6_3")|| !!currentUser.type) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            )}
          </Tooltip>
          <Tooltip title="Supprimer">
            {(CAN("6_4")|| !!currentUser.type) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => confirmDelete(record)}
                icon={<DeleteOutlined />}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];
  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content:
        "Êtes-vous sûr de vouloir supprimer cette méthode de paiement ? ",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const updateMode = (record) => {
    getPaiementTypes();
    setPaiementMethodModal(true);
    form.setFieldsValue(record);
    const checkedSwitch = record.active ? "checked" : "unchecked";
    setSwitchValue(checkedSwitch);
    form.setFieldsValue({
      type: record.moyenPaiement.id_type_paiement,
    });
    setUpdateId(record.methode_paiement_id);
    setEditMode(true);
  };

  const getPaiements = async () => {
    setLoading(true);
    const response = await paiementMethodeService.getPaiementMethodes();
    if (response.status === 200 || 204) initTableData(response.data);
    else messages.errorMessage();
    setLoading(false);
  };

  const initTableData = (data) => {
    let array = data;
    for (const element of array) {
      element.showType = element.moyenPaiement?.nom;
      element.etat = element.active ? "Activé" : "Non Activé";
    }
    setDataTable(array);
  };
  const deleteItem = async (item) => {
    const response = await paiementMethodeService.deletePaiementMethode(
      item.methode_paiement_id
    );
    if (response.status === 200 || 204) {
      messages.addSuccess("Suppression avec succès ! ");
      getPaiements();
    } else messages.errorMessage();
  };

  const getPaiementTypes = async () => {
    setLoading(true);
    const response = await moyenPaiementService.getPaiementMoyens();
    if (response.status === 200 || 204) {
      initMethodeForm(response.data);
    } else messages.errorMessage();
    setLoading(false);
  };

  const initMethodeForm = (data) => {
    setPaiementTypes(data);
  };

  const addPaiementMethod = async (values) => {
    setConfirmLoading(true);
    const response = editMode
      ? await paiementMethodeService.updatePaiementMethode(updateId, values)
      : await paiementMethodeService.addPaiementMethode(values);
    if (response.status === 200 || 204) {
      if (editMode) updatePaiementMethodsSuccess();
      else addPaiementMethodsSuccess();
    } else addPaiementMethodsError();
    closingModal();
  };
  const closingModal = () => {
    setPaiementMethodModal(false);
    getPaiements();
    setConfirmLoading(false);
    closePaiementModal();
    setEditMode(false);
  };
  const addPaiementMethodsSuccess = () => {
    messages.addSuccess("Méthode de paiement ajoutée avec succès");
    closingModal();
  };
  const updatePaiementMethodsSuccess = () => {
    messages.updateSuccess("Mise à jour success");
    closingModal();
  };
  const addPaiementMethodsError = () => {
    setConfirmLoading(false);
    messages.errorMessage();
  };

  const addPaiementMoyen = async (values) => {
    setLoading(true);
    setConfirmMoyenPaiementLoading(true);
    setMoyenPaiementModal(true);
    const response = await moyenPaiementService.addPaiementMoyen(values);
    if (response.status === 200 || 204)
      messages.addSuccess("Ajout du Moyen de paiement avec success");
    else messages.errorMessage();
    getPaiementTypes();
    setLoading(false);
    setConfirmMoyenPaiementLoading(false);
    closeMoyenPaiementModal();
  };

  const closeMoyenPaiementModal = () => {
    setMoyenPaiementModal(false);
    moyenForm.resetFields();
  };

  const closePaiementModal = () => {
    setPaiementMethodModal(false);
    setEditMode(false);
    form.resetFields();
  };

  return (
    <Spin spinning={isLoading} size="large">
      {(CAN("6_1")|| !!currentUser.type) ? (
        <Card
          headStyle={{ backgroundColor: "#dee0e9" }}
          title="Liste des méthodes de paiement"
          style={{ width: "100%" }}
          extra={
           ( CAN("6_2") || !!currentUser.type) && (
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => setPaiementMethodModal(true)}
              >
                Ajouter une méthode de paiement
              </Button>
            )
          }
        >
          <AppTable data={dataTable} columns={PaiementColumn} />
        </Card>
      ) : (
        <NotAuthorized />
      )}
      <Form layout="vertical" form={form} onFinish={addPaiementMethod}>
        <Modal
          width={800}
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  {editMode
                    ? "Modifier une méthode de paiement"
                    : "Ajouter une méthode de paiement"}
                </div>
                <div>
                  <Form.Item
                    className="my-0 mx-5"
                    name="active"
                    valuePropName={switchValue}
                  >
                    <Switch
                      checkedChildren="Activer"
                      unCheckedChildren="Désactiver"
                      className="px-4"
                    />
                  </Form.Item>
                </div>
              </div>
            </>
          }
          okText="Enregistrer"
          cancelText="Annuler"
          getContainer={false}
          visible={paiementMethodModal}
          confirmLoading={confirmLoading}
          onOk={form.submit}
          onCancel={closePaiementModal}
        >
          <Row>
            <Col span={12} className="px-2">
              <Form.Item
                label="Nom de la méthode"
                name="nom"
                rules={[
                  {
                    required: true,
                    message:
                      "Champ obligatoire avec maximum de caractère 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input
                  placeholder="Exemple : Carte"
                  size="large"
                  type="text"
                  maxLength={100}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={20}>
                  <Form.Item
                    label="Type de paiement"
                    name="type"
                    rules={[{ required: true, message: "Champ obligatoire !" }]}
                  >
                    <Select size="large" style={{ width: "100%" }}>
                      {paiementTypes.map((el) => (
                        <Option value={el.id_type_paiement}>{el.nom}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ paddingTop: "31px" }}>
                  <Tooltip title="Ajouter un type de paiement">
                    <Button
                      size="large"
                      onClick={() => setMoyenPaiementModal(true)}
                      icon={<PlusOutlined />}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="px-2">
              <Form.Item
                label="Type du compte bancaire"
                name="type_account"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  defaultValue="--Sélectionner un type  --"
                >
                  <Select.Option value="Professionnel">
                    Professionnel
                  </Select.Option>
                  <Select.Option value="Personnel">Personnel</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} className="px-2">
              <Form.Item
                label="Ce mode de paiement a-t-il une date d'encaissement différente de la date de paiement ?"
                name="encaissement"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <Radio.Group>
                  <Radio value={true}>Oui</Radio>
                  <Radio value={false}>Non</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Col span={24} className="px-2">
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  message: "Champ obligatoire avec maximum de caractère 255 !",
                  max: 254,
                },
              ]}
            >
              <TextArea rows={4} type="text" maxLength={255} />
            </Form.Item>
          </Col>
        </Modal>
      </Form>

      <Modal
        title="Ajouter un type de paiement"
        centered
        getContainer={false}
        okText="Enregistrer"
        cancelText="Annuler"
        confirmLoading={confirmMoyenPaiementLoading}
        visible={moyenPaiementModal}
        onOk={moyenForm.submit}
        onCancel={closeMoyenPaiementModal}
        width={400}
      >
        <Form layout="vertical" form={moyenForm} onFinish={addPaiementMoyen}>
          <Row>
            <Col span={24} className="px-2">
              <Form.Item
                label="Type de paiement"
                name="nom"
                rules={[{
                    required: true,
                    message: "Champ obligatoire avec maximum de caractère 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input
                  placeholder="Exemple : Carte"
                  size="large"
                  typ="text"
                  maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
}
export default MethodePaiement;
