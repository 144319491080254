import authHeader from "./auth-header";
import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;
let   EntrepriseId = (localStorage.getItem('entreprise'))

const addFrequence = ( frequence ) => {

  return axios.post(API_URL + "frequence/"+EntrepriseId , frequence ,{ headers: authHeader() });
};

const getAllFrequence = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "frequenceActive/" + EntrepriseId ,  { headers: authHeader() });
};

const deleteFrequence = (id) => {
  return axios.delete(API_URL + "frequence/" + id , { headers: authHeader() });
};
const updateFrequence = (id , frequence ) => {
  return axios.put(API_URL + "frequence/" + id , frequence, { headers: authHeader() });
};


const exportMethodes = {
    addFrequence,
    getAllFrequence,
    deleteFrequence,
    updateFrequence
};
export default exportMethodes;