import authHeader from "./auth-header";
import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;


const registerUser=(user ,entreprise )=>{
  user.role_id = 1
  return axios.post(API_URL + "auth/signup/"+entreprise, user);

}

const loginExpert =async(expert)=>{
    const res =  await axios.post(API_URL + "auth/signinExpert", expert)
    localStorage.setItem("user", JSON.stringify(res.data));
    localStorage.setItem(
      "permissions",
      JSON.stringify(res.data.arrayPermission))
    return res

}

const addentrepriseclient = (entrepriseclient) => {
  return axios.post(API_URL + "entrepriseclient", entrepriseclient, {
    headers: authHeader(),
  });
};

const getAllentrepriseclients = () => {
  return axios.get(API_URL + "entrepriseclients", { headers: authHeader() });
};
const deleteentrepriseclient = (id) => {
  return axios.delete(API_URL + "entrepriseclient/" + id, { headers: authHeader() });
};
const updateentrepriseclient = (id, entrepriseclient) => {
  return axios.put(API_URL + "entrepriseclient/" + id, entrepriseclient, {
    headers: authHeader(),
  });
};

const exportMethodes = {
  addentrepriseclient,
  getAllentrepriseclients,
  deleteentrepriseclient,
  updateentrepriseclient,
  loginExpert,
  registerUser
};
export default exportMethodes;
