import authHeader from "./auth-header";


import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addEcheanceType = ( typeEchance ) => {
  return axios.post(API_URL + "typeEchance" , typeEchance ,{ headers: authHeader() });
};
const getTypesEcheances = () => {
  return axios.get(API_URL + "typeEchance" ,  { headers: authHeader() });
};
const deletEcheanceType = (id) => {
  return axios.delete(API_URL + "typeEchance/" + id , { headers: authHeader() });
};
const updatEcheanceType = (id , typeEchance ) => {
  return axios.put(API_URL + "typeEchance/" + id , typeEchance, { headers: authHeader() });
};


const echeanceType = {
    addEcheanceType,
    getTypesEcheances,
    deletEcheanceType,
    updatEcheanceType
};
export default echeanceType;