import { RETRIEVE_ENTREPEISES } from "../actions/type";
const initialState = {};

export const entrepriseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RETRIEVE_ENTREPEISES:
      return payload;
    default:
      return state;
  }
};
