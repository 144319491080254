const TypeEcheanceColumn = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Nombre d'échéance",
    dataIndex: "nbEcheance",
    sorter: (a, b) => a.nbEcheance - b.nbEcheance,
  },
];

const TypeEcheanceColumns = {
  TypeEcheanceColumn,
};
export default TypeEcheanceColumns;
