import auth from "./reducers/auth";
import message from "./reducers/message";
import vehiculesReducer from "./reducers/vehicule";
import { entrepriseReducer } from "./reducers/entreprise";
import roles from "./reducers/roles";
import modules from "./reducers/module";
import permissionType from "./reducers/permissionType";
import profitCenterReducer from "./reducers/profitCenterReducer";
import categorieReducer from "./reducers/categorie";
import depensesReducer from "./reducers/depense";
import depensesTypeReducer from "./reducers/depenseType";
import users from "./reducers/users";
import frequenceReducer from "./reducers/frequence";
import fournisseurReducer from "./reducers/fournisseur";
import echeanceReducer from "./reducers/echeanceType";

import { combineReducers, createStore, applyMiddleware, compose } from "redux";

const rootReducer = combineReducers({
  auth,
  profitCenterReducer,
  message,
  users,
  vehiculesReducer,
  entrepriseReducer,
  categorieReducer,
  roles,
  modules,
  permissionType,
  depensesReducer,
  depensesTypeReducer,
  frequenceReducer,
  fournisseurReducer,
  echeanceReducer,
});
export default rootReducer;
